import { BrowserRouter, Route, Routes } from "react-router-dom"

import PrivatePageLayout from "@/components/layouts/PrivatePageLayout"
import AccountVerification from "@/components/pages/accountVerification/AccountVerification"
import ActualInventory from "@/components/pages/actualInventory/ActualInventory"
import DebtSummary from "@/components/pages/debtSummary/DebtSummary"
import Home from "@/components/pages/Home/Home"
import { ForgotPassword } from "@/components/pages/Forgot_password/ForgotPassword"
import Inventory from "@/components/pages/inventory/Inventory"
import Invoices from "@/components/pages/invoices/Invoices"
import { Login } from "@/components/pages/login/Login"
import { Modules } from "@/components/pages/modules/modules"
import Notifications from "@/components/pages/notifications/Notifications"
import Orders from "@/components/pages/orders/Orders"
import Profile from "@/components/pages/profile/Profile"
import ProviderBySales from "@/components/pages/providerBySales/providerBySales"
import Providers from "@/components/pages/providers/Providers"
import { ResetController } from "@/components/pages/resetPassword/Reset"
import SalesForm from "@/components/pages/saleForm/SaleForm"
import Sales from "@/components/pages/sales/Sales"
import { TransactionEntity } from "@/components/pages/transactionEntity/TransactionEntity"
import { WorkSpaceEntities } from "@/components/pages/work_space_entities/workSpaceEntities"
import PayrollCalculations from "@/components/common/payrollCalculations/payrollCalculations"
import Deductions from "@/components/common/deductions/deductions"
import Contributions from "@/components/common/contributions/contributions"
import ExpenseReports from "@/components/common/expenseReports/expenseReports"
import Payroll from "@/components/pages/payroll/Payroll"
import Employees from "@/components/pages/employees/Employees"
import PayrollPeriods from "@/components/pages/payrollPeriods/PayrollPeriods"
import { PrivateRoutes, PublicRoutes } from "@/utils/PrivateRoute"
import Causation from "@/components/new-front/Causation/Causation"
import InvoicesNotSent from "@/components/new-front/InvoicesNotSent/InvoicesNotSent"
import ConsolidatedInfo from "@/components/new-front/ConsolidatedInfo/ConsolidatedInfo"
import Debts from "@/components/new-front/Debts/Debts"
import HelpCenter from "@/components/pages/helpCenter/helpCenter"
import Settings from "@/components/new-front/Settings/Settings"
import Taxes from "@/components/new-front/Taxes/Taxes"

const Routing = () => {
	return (
		<BrowserRouter>
			<Routes>
				{/* Imported the public Routes */}
				<Route element={<PublicRoutes />}>
					#<Route path="/login" element={<Login />} />
					{/* <Route path="/register" element={<RegisterController />} /> */}
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/password-reset/:token" element={<ResetController />} />
					<Route path="/verify/:token" element={<AccountVerification />} />
				</Route>
				{/* Imported the private Routes */}
				<Route
					element={
						<PrivatePageLayout>
							<PrivateRoutes />
						</PrivatePageLayout>
					}
				>
					<Route path="/" element={<Home />} />

					{/* Workflow Routes */}
					<Route path="/causation" element={<Causation />} />
					<Route path="/invoices-not-sent" element={<InvoicesNotSent />} />
					{/* <Route path="/debts" element={<ConsolidatedInfo />} /> */}
					{/* <Route path="/testing" element={<InvoiceModule />} /> */}
					<Route path="/debts" element={<Debts />} />
					<Route path="/consolidated-info/:id" element={<ConsolidatedInfo />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/modules" element={<Modules />} />
					<Route path="/modules/:id" element={<WorkSpaceEntities />} />
					<Route path="/modules/data/:module/:id" element={<ActualInventory />} />
					<Route path="/debt-summary" element={<DebtSummary />} />
					<Route path="/get-report/:id" element={<TransactionEntity />} />
					<Route path="/providers" element={<Providers />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/inventory" element={<Inventory />} />
					<Route path="/notifications" element={<Notifications />} />
					<Route path="/orders" element={<Orders />} />
					<Route path="/sales" element={<ProviderBySales />} />
					<Route path="/sales/:id" element={<Sales />} />
					<Route path="/sales/form" element={<SalesForm />} />
					<Route path="/invoices" element={<Invoices />} />
					<Route path="/taxes" element={<Taxes />} />
					<Route path="/payroll" element={<Payroll />} />
					<Route path="/payroll/periods" element={<PayrollPeriods />} />
					<Route path="/employees" element={<Employees />} />

					{/* Spring 19 Jose */}
					<Route path="/payroll/calculations" element={<PayrollCalculations />} />
					<Route path="/payroll/deductions" element={<Deductions />} />
					<Route path="/payroll/contributions" element={<Contributions />} />
					<Route path="/payroll/expenseReports" element={<ExpenseReports />} />
					<Route path="/help-center" element={<HelpCenter />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default Routing
