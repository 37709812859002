import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/payroll"

export async function getPeriodDates() {
  const res = await axios.get(`${VITE_BASE_URL}/range`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function getPayrollSettings() {
  const res = await axios.get(`${VITE_BASE_URL}/settings`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function editPayrollSettings(data) {
  const res = await axios.put(`${VITE_BASE_URL}/settings`, data, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function createPayrollRecord(data) {
  const res = await axios.post(`${VITE_BASE_URL}`, data, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function getPayrollRecord(frequency) {
  const res = await axios.get(`${VITE_BASE_URL}/report`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
    params: {
      frequency,
    }
  })
  return res.data
}
