export const formatPrice = (value) => {
	// Verificar que el valor sea un número o cadena
	console.log(typeof value, value)
	if (typeof value !== "number" && typeof value !== "string") {
		console.error("El valor debe ser un número o una cadena.")
	}

	// Convertir el valor a un número, en caso de ser cadena
	const numericValue = typeof value === "string" ? parseFloat(value) : value

	if (isNaN(numericValue)) {
		console.error("El valor no es un número válido.")
	}

	// Formatear el número usando Intl.NumberFormat para el formato colombiano
	return new Intl.NumberFormat("es-CO", {
		style: "currency",
		currency: "COP",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(numericValue)
}

export const transformDataForTable = (data) => {
	const groupedByRows = {}

	// Group items by rowIndex
	data.forEach((item) => {
		if (!groupedByRows[item.rowIndex]) {
			groupedByRows[item.rowIndex] = []
		}
		groupedByRows[item.rowIndex].push(item)
	})

	// Convert object to array of rows for easier mapping
	const rows = Object.values(groupedByRows)

	// Optionally, sort rows by rowIndex if necessary (assuming it's already in order)
	rows.sort((a, b) => a[0].rowIndex - b[0].rowIndex)
	return rows
}

export const getDetailedData = (data) => {
	return data.map((row) => {
		const Total = row.total
		const description = row.description
		const quantity = row.quantity //parseInt(row.quantity?.value)

		const UnitPrice = row.unitPrice
		const TaxRate = row.unitTax

		return {
			total: Total,
			description,
			quantity,
			unitPrice: UnitPrice,
			unitTax: TaxRate,
		}
	})
}

export function isBase64(str) {
	try {
		return btoa(atob(str)) === str
	} catch (err) {
		return false
	}
}
