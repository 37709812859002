// src/components/login/Login.jsx
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { loginUser } from "@/services/user"
import Cookies from "js-cookie"
import { PaymentInstructionsPop } from "../../common/popUps/payment_instructionsPopUp/payment_instructions"
import { TbClock, TbRouteX } from "react-icons/tb"
import { HiSparkles } from "react-icons/hi2"
import logo from "/logo.svg"
import "./style.css"
import { useTranslation } from "react-i18next" // Import useTranslation
import { Label } from "@/components/ui/label" // Import Label
import { Input } from "@/components/ui/input" // Import Input
import { Checkbox } from "@/components/ui/checkbox" // Import Checkbox
import { Button } from "@/components/ui/button" // Import Button
import { Eye, EyeClosed } from "lucide-react"

export const Login = () => {
	const { t } = useTranslation(["login"])
	const [loginForm, setLoginForm] = useState({
		email: "",
		password: "",
	})
	const [formError, setFormError] = useState(null)
	const [resendemail, setResendemail] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [showPaymentInstructionsPop, setShowPaymentInstructionsPop] = useState(false)
	const [index, setIndex] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1))
		}, 8000)
		return () => clearInterval(interval)
	}, [])

	const handleLoginFormChange = (event) => {
		setLoginForm({
			...loginForm,
			[event.target.name]: event.target.value,
		})
	}

	const handleSubmit = (event) => {
		event.preventDefault()

		// Verificar campos vacíos
		for (const key in loginForm) {
			if (loginForm[key].trim() === "") {
				setFormError(t("login.messages.noBlankSpaces"))
				return // Detener la validación si se encuentra un campo vacío
			}
		}
		handleLogin()
		setFormError(null)
	}

	const handleLogin = async () => {
		try {
			const loginData = await loginUser(loginForm)
			if (loginData.status === 200) {
				const { token } = loginData.data
				Cookies.set("token", token, { expires: 7, secure: true })
				window.location.href = "/"
			}
		} catch (error) {
			if (error.response.status === 401) {
				setFormError(t("login.messages.userNotAuthenticated"))
				setResendemail(true)
			} else if (error.response.status === 404) {
				setFormError(t("login.messages.incorrectCredentials"))
			} else {
				setFormError(t("login.messages.serverError"))
			}
		}
	}

	const resendEmail = async () => {
		try {
			const resendEmailData = await resendEmail()
			if (resendEmailData.status === 200) {
				console.log(resendEmailData.data)
			}
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="flex min-h-screen w-full">
			<PaymentInstructionsPop
				show={showPaymentInstructionsPop}
				onClose={() => setShowPaymentInstructionsPop(false)}
			/>
			<div className="flex flex-col justify-center items-center w-full px-[10%] sm:px-[15%] md:px-[25%] lg:w-1/2 lg:px-[10%]">
				<h1 className="mb-12 text-7xl text-[#18252F] flex gap-1 items-center justify-center font-[FoundersGrotesk] mt-10">
					<img src={logo} alt={t("login.labels.logoAlt")} className="w-20 h-20" />{" "}
					<span className="mt-5">{t("login.labels.brandName")}</span>
				</h1>
				<h2 className="mb-6 text-3xl text-balance text-center text-[#18252F] font-[FoundersGrotesk]-bold">
					{t("login.labels.welcomeBack")}
				</h2>
				<div className="flex justify-center ">
					<form className="space-y-7">
						<div className="grid max-w-sm items-center gap-4 w-full">
							<Label htmlFor="email">{t("login.placeholders.email")}</Label>
							<Input name="email" type="email" id="email" onChange={handleLoginFormChange} />
						</div>

						<div className="grid relative max-w-sm items-center gap-4 w-full">
							<Label htmlFor="password">{t("login.placeholders.password")}</Label>
							<div className="relative w-full">
								<Input
									name="password"
									type={showPassword ? "text" : "password"}
									id="email"
									className="w-full"
									onChange={handleLoginFormChange}
								/>
								<button
									type="button"
									className="absolute right-4 top-3"
									onClick={() => setShowPassword(!showPassword)}
									aria-label={
										showPassword ? t("login.labels.hidePassword") : t("login.labels.showPassword")
									}
								>
									{showPassword ? (
										<Eye className="h-6 w-6 text-primary-grey-1200 hover:text-primary-grey-2200" />
									) : (
										<EyeClosed className="h-6 text-primary-grey-1200 hover:text-primary-grey-2200" />
									)}
								</button>
							</div>
						</div>

						{formError && (
							<p className="error-message w-full h-4 text-red-500 text-sm">{formError}</p>
						)}
						{resendemail && (
							<p
								className="w-full text-blue-500 text-sm cursor-pointer hover:underline"
								onClick={resendEmail}
							>
								{t("login.buttons.resendVerification")}
							</p>
						)}

						<div className="flex justify-around items-center gap-8">
							<div className="flex items-center">
								<Checkbox id="remember"></Checkbox>
								<Label
									htmlFor="remember"
									className="text-sm px-1 text-[#18252F] font-[FoundersGrotesk]-bold"
								>
									{t("login.labels.rememberMe")}
								</Label>
							</div>
							<Link
								to="/forgot-password"
								className="text-sm text-[#18252F] hover:underline font-[FoundersGrotesk]-bold"
							>
								{t("login.labels.forgotPassword")}
							</Link>
						</div>
						<hr className="mx-5" />
						<Button variant="primary" onClick={handleSubmit} className="w-full">
							{t("login.buttons.login")}
						</Button>
					</form>
				</div>
				<p className="mt-5 text-sm text-center text-[#18252F] font-[FoundersGrotesk]-bold">
					{t("login.labels.registerPrompt")}{" "}
					<button
						href="#"
						onClick={(e) => {
							e.preventDefault()
							setShowPaymentInstructionsPop(true)
						}}
						className="text-[#2BAB56] hover:underline"
					>
						{t("login.labels.registerButton")}
					</button>
				</p>
				<p className="mt-5 text-sm text-center text-[#18252F] font-[FoundersGrotesk]-bold">
					{t("login.labels.termsAndConditions")}{" "}
					<a
						href="https://www.bucks-finance.com/es/blank-2-1"
						className="text-[#2BAB56] hover:underline"
						target="_blank"
						rel="noopener noreferrer"
					>
						{t("login.labels.termsAndConditionsLink")}
					</a>{" "}
					{t("login.labels.privacyPolicy")}{" "}
					<a
						href="https://www.bucks-finance.com/es/blank-2"
						className="text-[#2BAB56] hover:underline"
						target="_blank"
						rel="noopener noreferrer"
					>
						{t("login.labels.privacyPolicyLink")}
					</a>
				</p>
			</div>
			<div className="hidden w-1/2 bg-gradient-to-r from-[--secondary] to-[--secondary-light] lg:flex lg:flex-col lg:justify-center lg:items-center overflow-hidden relative m-5 rounded-xl">
				<div
					className={`text-center flex absolute left-0 w-[300%] transition-transform ease-in-out duration-1000 -translate-x-${index}/3`}
				>
					<div className="w-1/3 2xl:px-52">
						<HiSparkles className="w-64 h-64 mx-auto mb-4 text-[--glow-green]" />
						<p className="mb-4 text-4xl text-balance text-[--tertiary] [&>span]:text-[--glow-green]">
							<span>{t("login.slider.contabilidad")}</span> {t("login.slider.enTiempoReal")}.
						</p>
					</div>
					<div className="w-1/3 2xl:px-52">
						<TbClock className="w-64 h-64 mx-auto mb-4 text-[--glow-green]" />
						<p className="mb-4 text-4xl text-balance text-[--tertiary] [&>span]:text-[--glow-green]">
							<span>{t("login.slider.generaInformes")}</span> {t("login.slider.financieros")}.
						</p>
					</div>
					<div className="w-1/3 2xl:px-52">
						<TbRouteX className="w-64 h-64 mx-auto mb-4 text-[--glow-green]" />
						<p className="mb-4 text-4xl text-balance text-[--tertiary] [&>span]:text-[--glow-green]">
							<span>{t("login.slider.acelera")}</span> {t("login.slider.tomaDecisiones")}.
						</p>
					</div>
				</div>
				<div className="absolute bottom-5">
					<div className="flex justify-center mt-4 space-x-2">
						<span
							className={`w-2 h-2 rounded-full ${index === 0 ? "bg-[--glow-green]" : "bg-[--separator]"}`}
						/>
						<span
							className={`w-2 h-2 rounded-full ${index === 1 ? "bg-[--glow-green]" : "bg-[--separator]"}`}
						/>
						<span
							className={`w-2 h-2 rounded-full ${index === 2 ? "bg-[--glow-green]" : "bg-[--separator]"}`}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
