import { useEffect, useState } from "react"
import Header from "../../common/Header/Header"
import { useTranslation } from "react-i18next"
import { Button } from "@/components/ui/button"
import { DataTableInvoices } from "../DataTables/DataTableInvoices"
import { getModules } from "@/services/modules"
import { getTransactionEntitiesOnWorkSpaceId } from "@/services/transactionEntity"
import { useDispatch, useSelector } from "react-redux"
import { fetchInvoicesSuccess } from "@/redux/invoice.slice"

export default function InvoicesNotSent() {
	const { t } = useTranslation(["invoicesNotSent"])
	const [modules, setModules] = useState([])
	const [costs, setCosts] = useState([])
	const [expenses, setExpenses] = useState([])
	const [selected, setSelected] = useState("all")

	const [firstLoad, setFirstLoad] = useState(true)
	const { fetchInvoices } = useSelector((state) => state.invoice)
	const dispatch = useDispatch()

	useEffect(() => {
		const getInvoicesModules = async () => {
			try {
				const response = await getModules()
				setModules(response)
				setFirstLoad(true)
			} catch (error) {
				console.error(t("messages.errorFetchingModules"), error)
			}
		}

		getInvoicesModules()
	}, [t])

	useEffect(() => {
		if (!firstLoad && !fetchInvoices) return
		
		const getCausationInfoByModule = async (id) => {
			try {
				const response = await getTransactionEntitiesOnWorkSpaceId(id)
				return response.data
			} catch (error) {
				console.error("Error getting workflows:", error)
			}
		}
		modules.forEach(async (module) => {
			const data = (await getCausationInfoByModule(module._id)).map((item) => ({
				mongoId: item._id,
				id: item.name,
				creationDate: new Date(item.created).toLocaleDateString(),
				paymentMethod: item.longTermPayment ? t("labels.credit") : t("labels.cash"),
			}))

			switch (module.name) {
				case "costos":
					setCosts(data)
					break
				case "gastos":
					setExpenses(data)
					break
				default:
					break
				}
		})
		setFirstLoad(false)
		dispatch(fetchInvoicesSuccess())
	}, [modules, t, fetchInvoices, dispatch, firstLoad])

	return (
		<>
			<Header title={t("title")} sections={[{ href: "/causation", title: t("causation") }]} />
			<main className="m-5 flex flex-col justify-between gap-3">
				<div className="flex gap-3">	
					<Button
						variant={selected === "all" ? "primary" : "quiet"}
						disabled={selected === "all"}
						onClick={() => setSelected("all")}
						className="disabled:opacity-95"
					>
						{t("radioGroup.all")}
					</Button>
					<Button
						variant={selected === "costs" ? "primary" : "quiet"}
						disabled={selected === "costs"}
						onClick={() => setSelected("costs")}
						className="disabled:opacity-95"
					>
						{t("radioGroup.costs")}
					</Button>
					<Button
						variant={selected === "expenses" ? "primary" : "quiet"}
						disabled={selected === "expenses"}
						onClick={() => setSelected("expenses")}
						className="disabled:opacity-95"
					>
						{t("radioGroup.expenses")}
					</Button>
				</div>

				{(selected === "all" || selected === "costs") && (
					<>
						<div className="flex items-center">
							<p className="text-lg font-bold">{t("radioGroup.costs")}</p>
							<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
						</div>
						<DataTableInvoices
							data={costs}
							setData={setCosts}
							pageSize={selected === "all" ? 5 : 10}
						/>
					</>
				)}
				{(selected === "all" || selected === "expenses") && (
					<>
						<div className="flex items-center">
							<p className="text-lg font-bold">{t("radioGroup.expenses")}</p>
							<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
						</div>
						<DataTableInvoices
							data={expenses}
							setData={setExpenses}
							pageSize={selected === "all" ? 5 : 10}
						/>
					</>
				)}
			</main>
		</>
	)
}
