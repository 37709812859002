import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '@/components/ui/accordion'

/**
 * Componente para solicitar y validar las llaves de la API de Siigo.
 *
 * @param {boolean} show - Controla si el diálogo está visible o no.
 * @param {function} onClose - Callback para cuando se cierra el diálogo.
 */
function WindowConfigurationEmail({ show, onClose }) {
  const { t } = useTranslation('windowConfigEmail')

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)
  }

  const renderSteps = (steps) =>
    steps.map((step, index) => {
      if (Array.isArray(step)) {
        // Renderiza subpasos con indentación y guiones verdes
        return (
          <ul key={`substep-${index}`} className="ml-6">
            {step.map((substep, subIndex) => (
              <li key={`substep-${index}-${subIndex}`} className="text-gray-700">
                <span className="text-primary-green-1400 font-bold">-</span> {substep}
              </li>
            ))}
          </ul>
        )
      } else {
        // Renderiza pasos normales con número y color
        return (
          <p key={`step-${index}`} className="mb-2">
            <span className="text-primary-green-1400 font-bold">{index + 1}.</span> {step}
          </p>
        )
      }
    })

  return (
    <Dialog
      open={show}
      onOpenChange={(val) => {
        if (!val && onClose) onClose()
      }}
    >
      <DialogContent>
        <div className="px-14 py-6">
          <DialogHeader>
            <DialogTitle>{t('dialogTitle')}</DialogTitle>
          </DialogHeader>

          <Accordion type="single" collapsible className="w-full mt-2">
            <AccordionItem value="item-1">
              <AccordionTrigger>{t('accordion.step1Title')}</AccordionTrigger>
              <AccordionContent>
                {renderSteps(t('accordion.step1Desc', { returnObjects: true }))}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>{t('accordion.step2Title')}</AccordionTrigger>
              <AccordionContent>
                {renderSteps(t('accordion.step2Desc', { returnObjects: true }))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default WindowConfigurationEmail
