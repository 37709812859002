// src/components/invoice/DragNdrop.jsx
import { useRef, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useNavigate } from "react-router-dom"

// Import SVG icons
import { IoCloudUploadOutline, IoWarning } from "react-icons/io5"
import { FaCheck, FaTimes, FaBan } from "react-icons/fa"

// Import services
import { uploadFile } from "../../../../services/file"

// Import Redux actions
import { useDispatch, useSelector } from "react-redux"
import { addInvoice, clearInvoices } from "../../../../redux/invoice.slice"

// Import Util Components
import { Each } from "../../../../utils/Each"
import Button from "../../Buttons/Button"

// Import PropTypes
import propTypes from "prop-types"
import "./dragNdrop.css" // Asegúrate de crear este archivo CSS para los estilos

// Import useTranslation
import { useTranslation } from "react-i18next"

export const DragNdrop = ({ show, onClose, setShowProgress, setFileStatuses }) => {
	const { t } = useTranslation(["invoice"])
	const popupContentRef = useRef(null) // Referencia para el contenido del popup

	const dispatch = useDispatch()
	const navigation = useNavigate()

	const invoices = useSelector((state) => state.invoice.invoices)

	const handleBackdropClick = (e) => {
		// Verifica si el clic fue en el backdrop y no en el contenido del popup
		if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
			onClose() // Cierra el popup si el clic fue fuera del contenido
		}
	}

	// Manejar la carga de archivos
	const handleFile = async (file) => {
		const formData = new FormData()
		formData.append("file", file)

		const fileType = file.type

		if (fileType === "application/zip" || fileType === "application/x-zip-compressed") {
			return handleZipFile(formData)
		} else {
			return {
				status: 415,
				message: t("messages.unsupportedFileType"), // "Tipo de archivo no soportado"
			}
		}
	}

	// Manejar la carga de archivos ZIP
	const handleZipFile = async (formData) => {
		try {
			// Sube el archivo
			const result = await uploadFile(formData)
			const { transactionEntity, invoice } = result.data

			return {
				status: result.status,
				path: transactionEntity.path,
				invoiceId: transactionEntity._id,
				data: invoice,
			}
		} catch (error) {
			console.error("Error in handleZipFile:", error)

			if (error.response && error.response.status === 400) {
				const { errors, errorTypes } = error.response.data

				// Procesar errores por tipo
				let translatedErrors = ""

				// Manejar errores de proveedor
				if (errorTypes.includes("provider")) {
					const providerErrors = errors.filter((err) => err.error === "No se encontró el proveedor")
					const providerDetails = providerErrors
						.map(
							(e) =>
								`${t("messages.errorProvider")}: ${e.supplier.name} (${e.supplier.vendorAddress}, ${e.supplier.vendorEmail})`,
						)
						.join(". ")
					translatedErrors += providerDetails + ". "
				}

				// Manejar errores de ítems
				if (errorTypes.includes("item")) {
					const itemErrors = errors.filter(
						(err) => err.error === "No se encontraron los items en el inventario",
					)
					const itemNames = itemErrors
						.flatMap((e) => e.items.map((item) => item.name)) // Obtener nombres de todos los ítems
						.join(", ")
					translatedErrors += `${t("messages.errorItemNotFound")}: ${itemNames}. `
				}

				// Manejar errores de PUC
				if (errorTypes.includes("puc")) {
					translatedErrors += `${t("messages.errorPUC")}. `
				}

				return {
					status: error.response.status,
					message: translatedErrors.trim(), // Eliminar espacios innecesarios al final
				}
			} else if (error.response && error.response.status === 406) {
				return {
					status: error.response.status,
					message: t("messages.invalidInvoice"), // "Factura inválida."
				}
			} else if (error.response && error.response.status === 409) {
				return {
					status: error.response.status,
					message: t("messages.uploadDuplicate"), // "Archivo duplicado."
				}
			}

			// Manejo genérico de errores
			return {
				status: error.response ? error.response.status : 500,
				message: error.message || t("messages.genericError"), // "Ocurrió un error."
			}
		}
	}

	// Manejar la caída de archivos
	const onDrop = useCallback(
		(acceptedFiles) => {
			onClose()
			setShowProgress(true)
			acceptedFiles.forEach(async (file, index) => {
				setFileStatuses((prevStatuses) => [...prevStatuses, { name: file.name, status: "loading" }])
				const result = await handleFile(file)
				switch (result.status) {
					case 200:
						result.message = t("messages.uploadSuccess") // "Archivo subido con éxito."
						break
					case 201:
						result.message = t("messages.uploadWarning") // "Archivo subido con una advertencia."
						break
					case 202:
						result.message = t("messages.uploadAttention") // "Archivo requiere de atención."
						break
					case 409:
						result.message = t("messages.uploadDuplicate") // "Archivo duplicado."
						break
					case 406:
						result.message = t("messages.invalidInvoice") // "Factura inválida."
						break
					default:
						break
				}

				// Despachar el resultado al store
				dispatch(
					addInvoice({
						status: result.status,
						name: file.name,
						description: result.message,
						invoiceId: result.invoiceId,
					}),
				)
				// Actualizar el estado del archivo en el popup
				setFileStatuses((prevStatuses) => {
					const newStatuses = [...prevStatuses]
					newStatuses[index] = {
						...newStatuses[index],
						status: result.status,
						message: result.message || t("messages.uploadSuccess"), // Valor por defecto
						invoiceId: result.invoiceId,
					}
					return newStatuses
				})
			})
		},
		[setShowProgress, setFileStatuses, handleFile, onClose, dispatch, t],
	)
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
	})

	if (!show) {
		return null
	}

	return (
		<div className="popup-backdrop z-[100]" onClick={handleBackdropClick}>
			{invoices.length > 0 ? (
				<div className="popup-content" ref={popupContentRef}>
					<Each
						data={invoices}
						render={(invoice, index) => (
							<div key={index} className="files-loader">
								<h3 className="file-name">{invoice.name}</h3>
								{invoice.status === "loading" && (
									<PulseLoader size={10} color={"var(--primary)"} loading={true} />
								)}
								{invoice.status === 200 && <FaCheck color="var(--primary)" />}
								{invoice.status === 201 && <IoWarning color="#e5be01" size={25} />}
								{invoice.status === 202 && <FaTimes color="red" size={25} />}
								{invoice.status === 409 && <FaBan color="red" size={25} />}
								{invoice.status === 406 && <FaBan color="red" size={25} />}
								<p>{invoice.description}</p>
								{/* Condicionalmente renderizar el botón ">" solo si no hay error */}
								{(invoice.status === 200 || invoice.status === 201) && (
									<Button text=">" onClick={() => navigation(`/get-report/${invoice.invoiceId}`)} />
								)}
							</div>
						)}
					/>
					<Button
						text={t("buttons.uploadMoreInvoices")}
						onClick={() => dispatch(clearInvoices())}
					/>
				</div>
			) : (
				<div className="popup-content" ref={popupContentRef}>
					<div {...getRootProps()} className={`dragNdrop ${isDragActive ? "is-drag-active" : ""}`}>
						{isDragActive && <div className="wave"></div>}
						<IoCloudUploadOutline />
						<p>{t("labels.dropFileHere")}</p>
					</div>
					<input {...getInputProps()} />
					<p>{t("labels.dropOrClick")}</p>
				</div>
			)}
		</div>
	)
}

DragNdrop.propTypes = {
	show: propTypes.bool.isRequired,
	onClose: propTypes.func.isRequired,
	setShowProgress: propTypes.func.isRequired,
	setFileStatuses: propTypes.func.isRequired,
}
