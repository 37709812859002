import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	invoices: [],
	fetchInvoices: false,
}

export const invoiceSlice = createSlice({
	name: "invoice",
	initialState,
	reducers: {
		addInvoice: (state, action) => {
			const { status, name, description, extension, blobid, invoiceId, module } = action.payload
			state.invoices.push({
				status,
				name,
				description,
				extension,
				blobid,
				invoiceId,
				module,
			})
			state.fetchInvoices = true
		},
		fetchInvoicesSuccess: (state) => {
			state.fetchInvoices = false
		},
		clearInvoices: (state) => {
			state.invoices = []
      state.fetchInvoices = true
		},
	},
})

export const { addInvoice, clearInvoices, fetchInvoicesSuccess } = invoiceSlice.actions
export default invoiceSlice.reducer
