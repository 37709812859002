import propTypes from "prop-types"
import { cn } from "@/lib/utils"
import { useState } from "react"

export default function InvoiceCombobox({
	id,
	label,
	className,
	onSelect,
	value,
	number = false,
	disabled = false,
	values = [],
}) {
	const [filter, setFilter] = useState("")
	const [focus, setFocus] = useState(false)

	const changeHandler = (e) => {
		if (e.key === "Escape") {
			setFilter("")
			setFocus(false)
			e.target.blur()
		}
	}

	const setVal = (val) => {
		setFilter("")
		onSelect(val, true)
		setFocus(false)
	}

	return (
		<div className={cn("relative form-group", className)}>
      <div className="flex justify-between">
				<label htmlFor={id}>{label}</label>
				<div></div>
			</div>
			<input
				id={id}
				name={id}
				type={number ? "number" : "text"}
				placeholder=""
				value={filter}
				defaultValue={value}
				onChange={(e) => {
					setFilter(e.target.value)
					onSelect(e.target.value, false)
				}}
				className="w-full p-2 form-control"
				readOnly={disabled}
				onKeyDown={changeHandler}
				onFocus={() => setFocus(true)}
				onBlur={(e) => {
					if (e.relatedTarget?.name === "pucButton") {
						return
					}
					setFilter("")
					setFocus(false)
				}}
			/>
			{focus && (
				<div className="absolute max-h-64 w-full flex flex-col bg-white shadow-lg translate-y-20 z-10 overflow-y-scroll">
					{values
						//.filter((val) => val.numDoc.startsWith(filter || "") || val.nombre.startsWith(filter || ""))
						.map((val) => (
							<div
								key={val.id}
								className="p-2 hover:bg-gray-100 cursor-pointer z-20 w-full"
							>
								<button
                  type="button"
									name="pucButton"
									className="w-full"
									onClick={() => {
										setVal(val)
										setFilter("")
										setFocus(false)
									}}
								>
									{`${val.numDoc} - ${val.nombre}`}
								</button>
							</div>
						))}
				</div>
			)}
		</div>
	)
}

InvoiceCombobox.propTypes = {
	id: propTypes.string.isRequired,
	label: propTypes.string,
	value: propTypes.string,
	className: propTypes.string,
	number: propTypes.bool,
	disabled: propTypes.bool,
	onSelect: propTypes.func.isRequired,
	values: propTypes.array,
}
