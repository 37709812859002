import { useEffect, useState } from "react"
import Header from "../../../components/common/Header/Header"
import { useTranslation } from "react-i18next"
import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog"
import {
	uploadTokenFile,
	getPaidInvoices,
	getSentInvoices,
	getPendingInvoices,
} from "@/services/invoice"
import { ArrowUpRight } from "lucide-react"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"

import { toggleToast } from "@/components/pages/modules/modules"
import { DataTableDianTokenInfo } from "../DataTables/DataTableDianTokenInfo"

export default function Causation() {
	const [file, setFile] = useState(null)
	const { t } = useTranslation(["causation"])
	const [comparisonResult, setComparisonResult] = useState(null)
	const handleFileChange = (event) => {
		setFile(event.target.files[0])
	}

	const handleFileUpload = async () => {
		try {
			if (!file) {
				toggleToast(t("messages.pleaseSelectFile"))
				return
			}
			const result = await uploadTokenFile(file)
			setComparisonResult(result)
			toggleToast(t("messages.fileUploadedSuccess"))
		} catch (error) {
			console.error("Error uploading file:", error)
			toggleToast(t("messages.fileUploadFailed"))
		}
	}

	const [paidInvoices, setPaidInvoices] = useState([])
	const [sentInvoices, setSentInvoices] = useState([])
	const [pendingInvoices, setPendingInvoices] = useState({
		pending: 0,
		unsent: 0,
	})
	const navigate = useNavigate()

	useEffect(() => {
		const fetchRecentActivity = async () => {
			const paidInvoices = (await getPaidInvoices()).map((invoice) => ({
				id: invoice.invoiceId,
				provider: invoice.provider,
				paymentDate: new Date(invoice.issueDate).toLocaleDateString(),
				paymentMethod: invoice.longTermPayment ? t("labels.credit") : t("labels.cash"),
				totalAmount: invoice.invoiceTotal,
			}))

			const sentInvoices = (await getSentInvoices()).map((invoice) => ({
				id: invoice.invoiceId,
				provider: invoice.provider,
				sentDate: new Date(invoice.issueDate).toLocaleDateString(),
				paymentMethod: invoice.longTermPayment ? t("labels.credit") : t("labels.cash"),
			}))

			setPaidInvoices(paidInvoices.reverse().slice(0, 5))
			setSentInvoices(sentInvoices.reverse().slice(0, 5))
		}

		const getPendingInvoicesTotal = async () => {
			try {
				const summary = await getPendingInvoices()
				setPendingInvoices(summary)
			} catch (error) {
				console.error(t("actualInventory.messages.errorFetchingSummaryData"), error)
			}
		}

		fetchRecentActivity()
		getPendingInvoicesTotal()
	}, [t])

	return (
		<>
			<Header title={t("title")} />
			<main className="m-5 flex flex-col justify-between gap-5 ">
				<div className="flex flex-row justify-between gap-5 2xl:gap-14">
					{/* Carta 1 */}
					<Card className="w-full flex flex-col bg-primary-green-1200">
						<CardHeader className="flex flex-row justify-between items-center">
							<CardTitle className="max-w-48">{t("cards.invoicesToPay.title")}</CardTitle>
							<Button variant="arrowGrey" size="circle" onClick={() => navigate("/debts")}>
								<ArrowUpRight className="h-5 w-5" />
							</Button>
						</CardHeader>
						<div className="border-t border-primary-grey-1800 mx-5 my-10 2xl:my-2"></div>
						<CardDescription className="mx-5 mb-5 mt-2">
							{pendingInvoices.pending}
							{t("cards.invoicesToPay.description")}
						</CardDescription>
					</Card>
					{/* Carta 2 */}
					<Card className="w-full flex flex-col bg-primary-green-1200">
						<CardHeader className="flex flex-row justify-between items-center">
							<CardTitle className="max-w-44">{t("cards.invoicesNotSent.title")}</CardTitle>
							<Button
								variant="arrowGrey"
								size="circle"
								onClick={() => navigate("/invoices-not-sent")}
							>
								<ArrowUpRight className="h-5 w-5" />
							</Button>
						</CardHeader>
						<div className="border-t border-primary-grey-1800 mx-5 my-10 2xl:my-2"></div>
						<CardDescription className="mx-5 mb-5 mt-2">
							{pendingInvoices.unsent}
							{t("cards.invoicesNotSent.description")}
						</CardDescription>
					</Card>
					{/* Carta 3 */}
					<Card className="w-full flex flex-col bg-primary-grey-1800">
						<CardHeader className="flex flex-row justify-between items-center">
							<CardTitle className="text-white">{t("cards.dianToken.title")}</CardTitle>
							<Dialog>
								<DialogTrigger asChild>
									<Button variant="arrowWhite" size="circle">
										<ArrowUpRight className="h-5 w-5" />
									</Button>
								</DialogTrigger>
								<DialogContent className="max-w-[1000px] max-h-screen overflow-y-scroll">
									<DialogHeader>
										<DialogTitle>{t("messages.comparacionToken")}</DialogTitle>
									</DialogHeader>
									<div className="flex items-center justify-between gap-4">
										<input
											type="file"
											accept=".xls,.xlsx, csv"
											onChange={handleFileChange}
											className="my-2 border px-2 py-1 rounded w-full"
										/>
										<div className="flex">
											<Button onClick={handleFileUpload} variant="primary">
												{t("buttons.subirYComparar")}
											</Button>
										</div>
									</div>
									{comparisonResult && (
										<>
											<h3 className="text-lg font-bold mt-2 mb-4">
												{t("messages.cufesEncontrados")}
												<span className="text-green-700">
													{" " + comparisonResult.existingInvoices.length}
												</span>
											</h3>
											<DataTableDianTokenInfo data={comparisonResult.existingInvoices} />

											<h3 className="text-lg font-bold my-4">
												{t("messages.cufesFaltantes")}
												<span className="text-red-400">
													{" " + comparisonResult.missingCufes.length}
												</span>
											</h3>
											<DataTableDianTokenInfo data={comparisonResult.missingCufes} />
										</>
									)}
								</DialogContent>
							</Dialog>
						</CardHeader>
						<div className="border-t border-primary-grey-400 mx-5 my-10 2xl:my-2"></div>
						<CardDescription className="mx-5 mb-5 mt-2 text-primary-grey-400">
							{t("cards.dianToken.description")}
						</CardDescription>
					</Card>
				</div>

				<div className="flex items-center">
					<p className="text-lg font-bold">{t("recentActivity")}</p>
					<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
				</div>
				<section className="flex flex-col gap-5">
					<h2>{t("paidInvoices.title")}</h2>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>{t("paidInvoices.invoice")}</TableHead>
								<TableHead>{t("paidInvoices.provider")}</TableHead>
								<TableHead>{t("paidInvoices.paymentDate")}</TableHead>
								<TableHead>{t("paidInvoices.paymentMethod")}</TableHead>
								<TableHead className="text-right">{t("paidInvoices.amount")}</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{paidInvoices.length === 0 && (
								<TableRow>
									<TableCell colSpan={5} className="h-24 text-center">
										No results.
									</TableCell>
								</TableRow>
							)}
							{paidInvoices.map((invoice) => (
								<TableRow key={invoice.invoice}>
									<TableCell className="font-medium">{invoice.id}</TableCell>
									<TableCell>{invoice.provider}</TableCell>
									<TableCell>{invoice.paymentDate}</TableCell>
									<TableCell>{invoice.paymentMethod}</TableCell>
									<TableCell className="text-right">{invoice.totalAmount}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<Button variant="quiet" className="w-fit mx-auto">
						{t("seeMore")}
					</Button>
				</section>
				<section className="flex flex-col gap-5">
					<h2>{t("sentInvoices.title")}</h2>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>{t("sentInvoices.invoice")}</TableHead>
								<TableHead>{t("sentInvoices.provider")}</TableHead>
								<TableHead>{t("sentInvoices.sentDate")}</TableHead>
								<TableHead className="text-right">{t("sentInvoices.paymentMethod")}</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{sentInvoices.length === 0 && (
								<TableRow>
									<TableCell colSpan={4} className="h-24 text-center">
										No results.
									</TableCell>
								</TableRow>
							)}
							{sentInvoices.map((invoice) => (
								<TableRow key={invoice.invoice}>
									<TableCell className="font-medium">{invoice.id}</TableCell>
									<TableCell>{invoice.provider}</TableCell>
									<TableCell>{invoice.sentDate}</TableCell>
									<TableCell className="text-right">{invoice.paymentMethod}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<Button variant="quiet" className="w-fit mx-auto">
						{t("seeMore")}
					</Button>
				</section>
			</main>
		</>
	)
}
