import React from "react"
import Header from "../../common/Header/Header"
import { useTranslation } from "react-i18next"
import { CircleHelp } from "lucide-react"
import CopyButton from "../../common/Buttons/CopyButton" // Import the CopyButton component

export default function HelpCenter() {
	const email = "support@bucks-team.com"
	const { t } = useTranslation(["helpCenter"])

	return (
		<>
			<Header title={t("header")} />
			<div className="flex flex-col items-center gap-8 py-32">
				<CircleHelp className="text-primary-green-1200 w-64 h-auto" />
				<div className="w-full max-w-3xl text-balance m-auto px-4 sm:px-6 lg:px-8 text-center">
					<h1 className="text-4xl font-bold text-primary-grey-2000 mb-4">{t("HelpHeader")}</h1>
					<div className="flex justify-center items-center gap-x-2">
						<p className="inline text-lg text-primary-grey-1800">{t("HelpSubHeader")}</p>
						<CopyButton textToCopy={email} buttonText={t("copyLabel")} />
					</div>
				</div>
			</div>
		</>
	)
}
