import axios from "axios";
import Cookies from "js-cookie";

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/wo'; // Replace with your API base URL

// Get
export const getWoReport = async () => {
    try {
        const response = await axios.get(`${VITE_BASE_URL}/test`, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`,
            },
            responseType: 'blob',
        });

        // Create a download link and simulate a click to trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx'); // Filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return response.data;
    } catch (error) {
        console.error("Error getting workspaces:", error);
        throw error;
    }
}

