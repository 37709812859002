import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import Header from '../../common/Header/Header';
import dataJson from './pruebaData.json';

// Configuración para Chart.js
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export default function ExpenseReports() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [period, setPeriod] = useState('mensual');

    useEffect(() => {
        setData(dataJson);
        setFilteredData(dataJson);
    }, []);

    // Función para parsear fechas en formato dd/mm/yyyy
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(year, month - 1, day);
    };

    // Filtrar datos según el período seleccionado
    const filterDataByPeriod = () => {
        const now = new Date();
        let filtered = data;

        if (period === 'trimestral') {
            // Show data from today back to 3 months ago
            const startOfPeriod = new Date(now);
            startOfPeriod.setMonth(now.getMonth() - 3);

            filtered = data.filter((row) => {
                const date = parseDate(row.date);
                return date >= startOfPeriod && date <= now;
            });
        } else if (period === 'mensual') {
            // Show data from today back to 1 month ago
            const startOfPeriod = new Date(now);
            startOfPeriod.setMonth(now.getMonth() - 1);

            filtered = data.filter((row) => {
                const date = parseDate(row.date);
                return date >= startOfPeriod && date <= now;
            });
        } else {
            // Sin filtro
            filtered = data;
        }

        setFilteredData(filtered);
    };

    useEffect(() => {
        filterDataByPeriod();
    }, [period, data]);

    // Calcular los totales de los gastos
    const totalNetSalary = filteredData.reduce((sum, row) => sum + row.netSalary, 0);
    const totalDeductions = filteredData.reduce((sum, row) => sum + row.deductions, 0);
    const totalEarnedSalary = filteredData.reduce((sum, row) => sum + row.earnedSalary, 0);

    // Datos para el gráfico de línea (tendencia de gasto)
    const lineChartData = {
        labels: filteredData.map(row => parseDate(row.date).toLocaleDateString('es-ES', { month: 'short', year: 'numeric' })),
        datasets: [
            {
                label: 'Gasto de Nómina',
                data: filteredData.map(row => row.netSalary),
                borderColor: '#36A2EB',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true
            }
        ]
    };

    // Datos para el gráfico de barras comparativo
    const barChartData = {
        labels: filteredData.map(row => parseDate(row.date).toLocaleDateString('es-ES', { month: 'short', year: 'numeric' })),
        datasets: [
            {
                label: 'Salario Devengado Total',
                data: filteredData.map(row => row.earnedSalary),
                backgroundColor: '#4CAF50'
            },
            {
                label: 'Total de Deducciones',
                data: filteredData.map(row => row.deductions),
                backgroundColor: '#FF6384'
            },
            {
                label: 'Salario Neto',
                data: filteredData.map(row => row.netSalary),
                backgroundColor: '#36A2EB'
            }
        ]
    };

    // Opciones para ambos gráficos
    const lineChartOptions = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'Tendencia de Gasto de Nómina' }
        }
    };

    const barChartOptions = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'Comparación de Salarios y Deducciones' }
        }
    };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    return (
        <>
            <Header title={"Reporte de Gastos en Nómina"} />
            <main className="w-full pt-[3px]">
                <div className="flex flex-wrap justify-center items-center font-bold p-4 gap-2">
                    <p
                        className="bg-secondary text-center p-4 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Gasto Total de Nómina: ${totalNetSalary}
                    </p>
                    <p
                        className="bg-secondary text-center p-4 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Total de Deducciones: ${totalDeductions}
                    </p>
                    <p
                        className="bg-secondary text-center p-4 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Salario Devengado Total: ${totalEarnedSalary}
                    </p>
                    <select
                        className="appearance-none border-none outline-none p-4 pr-8 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)] text-center relative mx-1"
                        value={period}
                        onChange={handlePeriodChange}
                        style={{
                            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='black'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a 1 1 0 010-1.414z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
                            backgroundPosition: 'right 0.5rem center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '1rem',
                            fontSize: "0.9rem",
                            margin: "0 0.5rem"
                        }}
                    >
                        <option value="mensual">Mensual</option>
                        <option value="trimestral">Trimestral</option>
                    </select>
                </div>

                {/* Gráfico de Línea */}
                <div className="mt-8 flex justify-center">
                    <div className="w-full max-w-[600px] h-[300px]">
                        <Line data={lineChartData} options={lineChartOptions} />
                    </div>
                </div>

                {/* Gráfico de Barras Comparativo */}
                <div className="mt-8 flex justify-center">
                    <div className="w-full max-w-[600px] h-[300px]">
                        <Bar data={barChartData} options={barChartOptions} />
                    </div>
                </div>
            </main>
        </>
    );
}
