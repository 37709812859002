import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip"
import { Button } from "@/components/ui/button"
import { useState, useEffect } from "react"

export default function CopyButton({ textToCopy, buttonText }) {
	const [copied, setCopied] = useState(false)

	useEffect(() => {
		if (copied) {
			const timer = setTimeout(() => setCopied(false), 2000)
			return () => clearTimeout(timer)
		}
	}, [copied])

	const handleCopy = () => {
		navigator.clipboard.writeText(textToCopy)
		setCopied(true)
	}

	return (
		<>
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							className="normal-case text-lg text-primary-grey-1800"
							variant="primary"
							onClick={handleCopy}
						>
							{copied ? "Copied" : buttonText}
							{copied ? (
								<CheckIcon className="ml-2 h-4 w-4" />
							) : (
								<ClipboardIcon className="ml-2 h-4 w-4" />
							)}
						</Button>
					</TooltipTrigger>
					<TooltipContent>
						<p>{copied ? "Copied for 2 seconds" : "Click to copy"}</p>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</>
	)
}

function CheckIcon(props) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M20 6 9 17l-5-5" />
		</svg>
	)
}

function ClipboardIcon(props) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
			<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
		</svg>
	)
}
