import { createSlice } from "@reduxjs/toolkit"

const progress = JSON.parse(localStorage.getItem("progress") || "{}")

const initialState = {
	isLoading: progress?.isLoading || false,
	progress: Number(progress?.progress) || 0,
}

export const progressSlice = createSlice({
	name: "progress",
	initialState,
	reducers: {
		startProgress: (state) => {
			state.isLoading = true
			localStorage.setItem("progress", JSON.stringify({ progress: 0, isLoading: true }))
		},
		setGlobalProgress: (state, action) => {
			state.progress = action.payload
			if (action.payload >= 100) {
				state.isLoading = false
			}
			localStorage.setItem("progress", JSON.stringify({ progress: action.payload, isLoading: state.isLoading }))
		},
		stopProgress: (state) => {
			state.isLoading = false
			state.progress = 0
			localStorage.setItem("progress", JSON.stringify({ progress: 0, isLoading: false }))
		}
	},
})
export const { setGlobalProgress, startProgress, stopProgress } = progressSlice.actions
export default progressSlice.reducer
