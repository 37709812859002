// src/hooks/useLoadNamespaces.js
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useLoadNamespaces = (namespaces) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        namespaces.forEach((ns) => {
            if (!i18n.hasResourceBundle(i18n.language, ns)) {
                i18n.loadNamespace(ns);
            }
        });
    }, [i18n, namespaces]);
};

export default useLoadNamespaces;
