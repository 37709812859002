// src/index.js
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
// Importar Redux
import { Provider } from "react-redux"
import { store } from "./redux/store"
import App from "./containers/App"
// Importar estilos
import "./index.css"

// Importar la configuración de i18n
import "./config/i18n.config"
import LogoLoader from "./components/new-front/LogoLoader/LogoLoader"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Suspense
				fallback={
					<main className="flex items-center justify-center h-screen w-screen">
						<LogoLoader className="h-32 w-32" />
					</main>
				}
			>
				<App />
			</Suspense>
		</Provider>
	</React.StrictMode>,
)

reportWebVitals()
