import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/employees"

export async function getEmployees() {
  const res = await axios.get(`${VITE_BASE_URL}/active`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function getEmployeeById(id) {
  const res = await axios.get(`${VITE_BASE_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function countEmployees() {
  const res = await axios.get(`${VITE_BASE_URL}/active/count`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data.count
}

export async function countEmployeesByPeriod(period) {
  const res = await axios.get(`${VITE_BASE_URL}/active/${period}/count`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data.count
}

export async function createEmployee(employee) {
  const res = await axios.post(`${VITE_BASE_URL}/create`, employee, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function updateEmployee(employee) {
  const res = await axios.put(`${VITE_BASE_URL}/update/${employee.employeeId}`, employee, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}

export async function deleteEmployee(id) {
  const res = await axios.delete(`${VITE_BASE_URL}/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
  return res.data
}
