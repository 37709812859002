import { useRef } from "react";
import PropTypes from "prop-types";
import Button from "../../Buttons/Button";


export const PaymentInstructionsPop = ({ show, onClose }) => {
  const popupContentRef = useRef(null);

  const handleBackdropClick = (e) => {
    if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
      onClose();
    }
  };

  const handlePaymentClick = () => {
    window.open("https://buy.stripe.com/bIY7w9bCD3DYaDS9AD", "_blank");
  };

  if (!show) {
    return null;
  }

  return (
    <div className="popup-backdrop z-[100]" onClick={handleBackdropClick}>
      <div className="popup-content text-black" ref={popupContentRef}>
        <h1>Registro tras pago</h1>
        <p>
          El siguiente botón te redirigirá a nuestro link de pago de Stripe, 
          una vez realizado el pago, nuestro equipo te enviará lo antes posible tus credenciales para que puedas acceder a Bucks.
        </p>
        <div className="options">
          <Button text="Pagar" onClick={handlePaymentClick} />
        </div>
      </div>
    </div>
  );
};

PaymentInstructionsPop.propTypes = {
  show: PropTypes.  bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
