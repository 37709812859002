// src/components/sales/SalesForm.jsx
import { useState, useEffect } from "react";
import Select from "react-select";
import { getAllProviders, providerToProveedor, searchProviders } from "../../../services/providers";
import { getSellers, searchSeller } from "../../../services/sellers";
import { ToastContainer } from "react-toastify";
import { getAllProducts, searchProducts, productToProducto } from "../../../services/products";
import { getAllPaymentTypes } from "../../../services/paymentTypes"; // Import the service to fetch payment methods
import { createSalesInvoice } from "../../../services/sales";
import { toggleToast } from "../modules/modules";
import Header from "../../common/Header/Header";
import ForbiddenPopUp from "../../common/popUps/forbiddenPopUp/ForbiddenPopUp"; // Si utilizas este componente
import { useTranslation } from 'react-i18next'; // Import useTranslation

export default function SalesForm() {
  const { t } = useTranslation(['salesForm']);
  const [page, ] = useState(1);

  // Providers state
  const [providers, setProviders] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(null);

  // Sellers state
  const [sellers, setSellers] = useState([]);
  const [sellerFilter, setSellerFilter] = useState("");
  const [selectedSeller, setSelectedSeller] = useState(null);

  // Products state
  const [products, setProducts] = useState([]);
  const [productFilter, setProductFilter] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  // List of added products with quantity and price
  const [addedProducts, setAddedProducts] = useState([]);

  // Quantity and price for the current product
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);

  // Payment Methods state
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentValue, setPaymentValue] = useState(0); // To capture payment value
  const [dueDate, setDueDate] = useState(""); // To capture due date

  // Send to DIAN checkbox state
  const [sendToDIAN, setSendToDIAN] = useState(false);

  // Validation states
  const [errors, setErrors] = useState({});
  
  // Forbidden state (si aplica)
  const [forbidden, setForbidden] = useState(false);

  // Function to get providers based on filter
  const getProviders = async () => {
    let limit = window.innerWidth >= 1536 ? 10 : window.innerWidth >= 1280 ? 7 : 5;
    let providersData;

    if (filter !== "") {
      providersData = await searchProviders({ limit, page, search: filter });
    } else {
      providersData = await getAllProviders({ limit, page });
    }

    setProviders(providersData.map(providerToProveedor).reverse());
  };

  // Function to get sellers based on filter
  const getSellersData = async () => {
    let sellersData;
    if (sellerFilter !== "") {
      sellersData = await searchSeller({ search: sellerFilter });
    } else {
      sellersData = await getSellers();
    }
    setSellers(sellersData.reverse());
  };

  // Function to get products based on filter
  const getProductsData = async () => {
    let productsData;
    if (productFilter !== "") {
      productsData = await searchProducts({ search: productFilter, limit: 10, page });
    } else {
      productsData = await getAllProducts({ limit: 10, page });
    }
    setProducts(productsData.map(productToProducto).reverse());
  };

  // Function to get payment methods from the backend
  const getPaymentMethods = async () => {
    try {
      const paymentMethodsData = await getAllPaymentTypes();
      setPaymentMethods(paymentMethodsData); // Assuming the API returns an array
    } catch (error) {
      console.error(t('salesForm.messages.errorObteniendoMetodosPago'), error);
      // Optionally, you can add a toast notification here
      toggleToast(t('salesForm.messages.errorObteniendoMetodosPago'), 'error');
    }
  };

  // Fetch payment methods on component load
  useEffect(() => {
    getPaymentMethods();
  }, []);

  // Fetch providers when the filter changes
  useEffect(() => {
    getProviders();
  }, [filter]);

  // Fetch sellers when the sellerFilter changes
  useEffect(() => {
    getSellersData();
  }, [sellerFilter]);

  // Fetch products when the productFilter changes
  useEffect(() => {
    getProductsData();
  }, [productFilter]);

  // Handlers for changing inputs
  const handleProviderChange = (selectedOption) => setSelectedProvider(selectedOption);
  const handleProviderInputChange = (inputValue) => setFilter(inputValue);

  const handleSellerChange = (selectedOption) => setSelectedSeller(selectedOption);
  const handleSellerInputChange = (inputValue) => setSellerFilter(inputValue);

  const handleProductChange = (selectedOption) => setSelectedProduct(selectedOption);
  const handleProductInputChange = (inputValue) => setProductFilter(inputValue);

  const handlePaymentMethodChange = (selectedOption) => setSelectedPaymentMethod(selectedOption);

  // Add selected product to the list with quantity and price
  const addProduct = () => {
    if (selectedProduct && quantity > 0 && price > 0) {
      setAddedProducts([...addedProducts, { ...selectedProduct, quantity, price }]);
      setSelectedProduct(null); // Clear the selected product after adding
      setQuantity(1); // Reset quantity
      setPrice(0); // Reset price
    }
  };

  // Remove product from the list
  const removeProduct = (index) => {
    const newProducts = addedProducts.filter((_, i) => i !== index);
    setAddedProducts(newProducts);
  };

  // Handle DIAN checkbox change
  const handleDIANChange = (e) => {
    setSendToDIAN(e.target.checked);
  };

  // Validate fields before submission
  const validateForm = () => {
    const newErrors = {};

    // Check if provider is selected
    if (!selectedProvider) newErrors.provider = t('salesForm.messages.debeSeleccionarProveedor');

    // Check if seller is selected
    if (!selectedSeller) newErrors.seller = t('salesForm.messages.debeSeleccionarVendedor');

    // Check if products are added
    if (addedProducts.length === 0) newErrors.products = t('salesForm.messages.debeAgregarAlMenosProducto');

    // Check if payment method is selected
    if (!selectedPaymentMethod) newErrors.paymentMethod = t('salesForm.messages.debeSeleccionarMetodoPago');

    // Check if payment value is greater than zero
    if (paymentValue <= 0) newErrors.paymentValue = t('salesForm.messages.montoPagoMayorCero');

    // Check if due date is provided
    if (!dueDate) newErrors.dueDate = t('salesForm.messages.debeIngresarFechaVencimiento');

    // Calculate the total price of the products
    const totalProductPrice = addedProducts.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);

    // Validate if the total product price matches the payment value
    if (totalProductPrice !== paymentValue) {
      newErrors.paymentMismatch = t('salesForm.messages.pagoNoCoincide', { totalProductPrice, paymentValue });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Function to create the sales invoice
  const createFv = async () => {
    if (!validateForm()) return;

    const products = addedProducts.map((product) => ({
      code: product.value,
      quantity: product.quantity,
      price: product.price,
    }));

    const data = {
      customer: { identification: selectedProvider.value },
      seller: selectedSeller.code,
      items: products,
      date: new Date().toISOString().split("T")[0],
      payments: [
        {
          id: selectedPaymentMethod.value,
          value: paymentValue, // Capture the value the user entered
          due_date: dueDate, // Capture the due date
        },
      ],
      stamp: {
        send: sendToDIAN,
      },
    };

    try {
      const response = await createSalesInvoice(data);
      console.log("Sale created:", response);
      toggleToast(t('salesForm.messages.facturaCreadaExito'), 'success');
      // Optionally, reset the form here
      // resetForm();
    } catch (error) {
      console.error(t('salesForm.messages.errorCrearFactura'), error);
      toggleToast(t('salesForm.messages.errorCrearFactura'), 'error');
      // Optionally, handle the error further
    }
  };

  // Optional: Function to reset the form after successful creation
  const resetForm = () => {
    setSelectedProvider(null);
    setFilter("");
    setSelectedSeller(null);
    setSellerFilter("");
    setSelectedProduct(null);
    setProductFilter("");
    setAddedProducts([]);
    setQuantity(1);
    setPrice(0);
    setSelectedPaymentMethod(null);
    setPaymentValue(0);
    setDueDate("");
    setSendToDIAN(false);
    setErrors({});
  };

  return (
    <>
      <Header title={t('salesForm.title')} />
      {forbidden && (
        <div className="z-10 w-full h-full backdrop-blur-[2px] bg-white/20 absolute top-0">
          <ForbiddenPopUp className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
      )}
      <div className="px-12 w-full pt-[3px] flex flex-col gap-4">
        {/* Provider Select */}
        <div className="mt-6">
          <label
            htmlFor="providerSelect"
            className="block text-sm font-medium text-gray-700"
          >
            {t('salesForm.labels.seleccionarProveedor')}
          </label>

          <Select
            id="providerSelect"
            options={providers.map((provider) => ({
              value: provider.numDoc,
              label: `${provider.nombre} - ${provider.numDoc}`,
            }))}
            value={selectedProvider}
            onChange={handleProviderChange}
            onInputChange={handleProviderInputChange}
            isClearable
            placeholder={t('salesForm.labels.buscarProveedor')}
            noOptionsMessage={() => t('salesForm.labels.noOptions')}
          />
          {errors.provider && (
            <p className="text-red-500 text-sm mt-2">{errors.provider}</p>
          )}
        </div>

        {/* Seller Select */}
        <div className="mt-6">
          <label
            htmlFor="sellerSelect"
            className="block text-sm font-medium text-gray-700"
          >
            {t('salesForm.labels.seleccionarVendedor')}
          </label>

          <Select
            id="sellerSelect"
            options={sellers.map((seller) => ({
              value: seller.identification,
              code: seller.siigo_id,
              label: `${seller.first_name} ${seller.last_name} - ${seller.identification}`,
            }))}
            value={selectedSeller}
            onChange={handleSellerChange}
            onInputChange={handleSellerInputChange}
            isClearable
            placeholder={t('salesForm.labels.buscarVendedor')}
            noOptionsMessage={() => t('salesForm.labels.noOptions')}
          />
          {errors.seller && <p className="text-red-500 text-sm mt-2">{errors.seller}</p>}
        </div>

        {/* Product Select */}
        <div className="mt-6">
          <label
            htmlFor="productSelect"
            className="block text-sm font-medium text-gray-700"
          >
            {t('salesForm.labels.seleccionarProducto')}
          </label>

          <Select
            id="productSelect"
            options={products.map((product) => ({
              value: product.code,
              label: `${product.name} - ${product.code}`,
            }))}
            value={selectedProduct}
            onChange={handleProductChange}
            onInputChange={handleProductInputChange}
            isClearable
            placeholder={t('salesForm.labels.buscarProducto')}
            noOptionsMessage={() => t('salesForm.labels.noOptions')}
          />

          {selectedProduct && (
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                {t('salesForm.labels.cantidad')}
              </label>
              <input
                type="number"
                className="mt-1 block w-full border border-gray-300 rounded-md"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
                min={1}
              />

              <label className="block text-sm font-medium text-gray-700">
                {t('salesForm.labels.precio')}
              </label>
              <input
                type="number"
                className="mt-1 block w-full border border-gray-300 rounded-md"
                value={price}
                onChange={(e) => setPrice(parseFloat(e.target.value))}
                min={0}
              />

              <button
                type="button"
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                onClick={addProduct}
              >
                {t('salesForm.buttons.agregarProducto')}
              </button>
            </div>
          )}
          {errors.products && (
            <p className="text-red-500 text-sm mt-2">{errors.products}</p>
          )}
        </div>

        {/* Added Products List */}
        <div className="mt-6">
          <h2 className="text-lg font-medium">{t('salesForm.labels.productosAgregados')}</h2>
          {addedProducts.length > 0 ? (
            <ul className="mt-4 space-y-4">
              {addedProducts.map((product, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center border p-2 rounded"
                >
                  <div>
                    <p>{product.label}</p>
                    <p>{t('salesForm.labels.cantidad')}: {product.quantity}</p>
                    <p>{t('salesForm.labels.precio')}: {product.price}</p>
                  </div>
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-2 rounded"
                    onClick={() => removeProduct(index)}
                  >
                    {t('salesForm.buttons.eliminar')}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('salesForm.labels.noProductosAgregados')}</p>
          )}
        </div>

        {/* Payment Method Select */}
        <div className="mt-6">
          <label
            htmlFor="paymentMethodSelect"
            className="block text-sm font-medium text-gray-700"
          >
            {t('salesForm.labels.seleccionarMetodoPago')}
          </label>

          <Select
            id="paymentMethodSelect"
            options={paymentMethods.map((method) => ({
              value: method.id, // Assuming each payment method has an `id` field
              label: method.name, // Assuming each payment method has a `name` field
            }))}
            value={selectedPaymentMethod}
            onChange={handlePaymentMethodChange}
            isClearable
            placeholder={t('salesForm.labels.buscarMetodoPago')}
            noOptionsMessage={() => t('salesForm.labels.noOptions')}
          />
          {errors.paymentMethod && (
            <p className="text-red-500 text-sm mt-2">{errors.paymentMethod}</p>
          )}
          {errors.paymentMismatch && (
            <p className="text-red-500 text-sm mt-2">{errors.paymentMismatch}</p>
          )}

          {selectedPaymentMethod && (
            <div className="mt-4">
              <h2 className="text-lg font-medium">{t('salesForm.labels.metodoPagoSeleccionado')}</h2>
              <p>{selectedPaymentMethod.label}</p>
              <label className="block text-sm font-medium text-gray-700 mt-4">
                {t('salesForm.labels.montoPago')}
              </label>
              <input
                type="number"
                className="mt-1 block w-full border border-gray-300 rounded-md"
                value={paymentValue}
                onChange={(e) => setPaymentValue(parseFloat(e.target.value))}
                min={0}
              />
              {errors.paymentValue && (
                <p className="text-red-500 text-sm mt-2">{errors.paymentValue}</p>
              )}

              <label className="block text-sm font-medium text-gray-700 mt-4">
                {t('salesForm.labels.fechaVencimiento')}
              </label>
              <input
                type="date"
                className="mt-1 block w-full border border-gray-300 rounded-md"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
              {errors.dueDate && (
                <p className="text-red-500 text-sm mt-2">{errors.dueDate}</p>
              )}
            </div>
          )}
        </div>

        {/* Checkbox for DIAN */}
        <div className="mt-6 flex gap-4">
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
            checked={sendToDIAN}
            onChange={handleDIANChange}
          />
          <label className="block text-sm font-medium text-gray-700">
            {t('salesForm.labels.enviarFacturaDIAN')}
          </label>
          {sendToDIAN && (
            <p className="mt-2 text-green-600">{t('salesForm.labels.facturaEnviadaDIAN')}</p>
          )}
        </div>

        {/* Create Invoice Button */}
        <button
          className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center py-2 px-4 bg-green-600"
          onClick={createFv}
        >
          {t('salesForm.buttons.crearFactura')}
        </button>
        <ToastContainer />
      </div>
    </>
  );
}
