import { useMemo, useEffect, useState } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import Header from "../Header/Header"
import dataJson from "./pruebaData.json" // Importa el JSON con los datos

export default function PayrollCalculations() {
	const [data, setData] = useState([]) // Datos completos desde JSON
	const [filteredData, setFilteredData] = useState([]) // Datos filtrados para mostrar en la tabla
	const [period, setPeriod] = useState("sinFiltro") // Valor inicial para evitar undefined

	// Cargar datos desde el JSON al inicio
	useEffect(() => {
		setData(dataJson)
		setFilteredData(dataJson) // Inicialmente sin filtro
	}, [])

	// Función para parsear fechas en formato dd/mm/yyyy
	const parseDate = (dateString) => {
		const [day, month, year] = dateString.split("/")
		return new Date(year, month - 1, day)
	}

	// Función para filtrar datos según el período seleccionado
	const filterDataByPeriod = () => {
		const now = new Date()
		let filtered

		if (period === "quincenal") {
			// Filtrar para los últimos 15 días
			const startOfPeriod = new Date(now)
			startOfPeriod.setDate(now.getDate() - 15) // Go back 15 days from today

			filtered = data.filter((row) => {
				const date = parseDate(row.date)
				return date >= startOfPeriod && date <= now
			})
		} else if (period === "mensual") {
			// Filtrar para el último mes
			const startOfPeriod = new Date(now)
			startOfPeriod.setMonth(now.getMonth() - 1) // Go back 1 month from today

			filtered = data.filter((row) => {
				const date = parseDate(row.date)
				return date >= startOfPeriod && date <= now
			})
		} else {
			// Sin filtro (mostrar todos los datos)
			filtered = data
		}

		setFilteredData(filtered)
	}

	// Llamar a filterDataByPeriod cuando cambia `period`
	useEffect(() => {
		console.log("Ejecutando filtro con period:", period)
		filterDataByPeriod()
	}, [period, data])

	// Columnas de la tabla de nómina
	const columns = useMemo(
		() => [
			{
				accessorKey: "name",
				header: "Nombre del Trabajador",
				muiTableHeadCellProps: { style: { color: "var(--primary)" } },
			},
			{
				accessorKey: "earnedSalary",
				header: "Salario Devengado",
				muiTableHeadCellProps: { style: { color: "var(--primary)" } },
			},
			{
				accessorKey: "deductions",
				header: "Total de Deducciones",
				muiTableHeadCellProps: { style: { color: "var(--primary)" } },
			},
			{
				accessorKey: "netSalary",
				header: "Salario Neto",
				muiTableHeadCellProps: { style: { color: "var(--primary)" } },
			},
			{
				accessorKey: "date",
				header: "Fecha",
				muiTableHeadCellProps: { style: { color: "var(--primary)" } },
			},
		],
		[],
	)

	// Configuración de la tabla
	const table = useMaterialReactTable({
		columns,
		data: filteredData, // Usamos los datos filtrados
		enableRowSelection: true,
		enableColumnOrdering: false,
		enableGlobalFilter: true, // Permitir el filtro global
		initialState: {
			pagination: {
				pageSize: 5,
				pageIndex: 0,
			},
		},
		enablePagination: true,
		enableFullScreenToggle: true,
		muiTableBodyCellProps: {
			className: "text-sm",
		},
	})

	// Obtener las filas seleccionadas
	const selectedRows = table.getSelectedRowModel().flatRows

	// Determinar los datos a usar para calcular los totales
	const displayedData =
		selectedRows.length > 0 ? selectedRows.map((row) => row.original) : filteredData

	// Calcular los totales basados en los datos mostrados
	const totalEarnedSalary = displayedData.reduce((sum, row) => sum + row.earnedSalary, 0)
	const totalDeductions = displayedData.reduce((sum, row) => sum + row.deductions, 0)
	const totalNetSalary = displayedData.reduce((sum, row) => sum + row.netSalary, 0)

	// Manejar el cambio de periodo en el filtro
	const handlePeriodChange = (event) => {
		const newPeriod = event.target.value
		console.log("Nuevo periodo seleccionado:", newPeriod)
		setPeriod(newPeriod)
	}

	return (
		<>
			<Header title={"Payroll Calculations"} section={{ title: "Payroll", href: "/payroll" }} />
			<main className=" w-full pt-[3px]">
				{/* Resumen de Totales y Filtro */}
				<div className="flex justify-between items-center font-bold text-sm text-base  p-4">
					<p className="bg-secondary text-center p-4 rounded-full shadow-[_0px_0px_10px_rgba(0,0,0,0.2)] ">
						Salario Devengado Total: ${totalEarnedSalary}
					</p>
					<p className="bg-secondary text-center p-4 rounded-full shadow-[_0px_0px_10px_rgba(0,0,0,0.2)]">
						Total de Deducciones: ${totalDeductions}
					</p>
					<p className="bg-secondary text-center p-4 rounded-full shadow-[_0px_0px_10px_rgba(0,0,0,0.2)]">
						Salario Neto Total: ${totalNetSalary}
					</p>
					<select
						className="appearance-none border-none outline-none p-4 pr-10 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)] text-center relative"
						value={period}
						onChange={handlePeriodChange}
						style={{
							backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='black'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
							backgroundPosition: "right 0.5rem center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "1rem",
						}}
					>
						<option value="sinFiltro">Sin Filtro</option>
						<option value="quincenal">Quincenal</option>
						<option value="mensual">Mensual</option>
					</select>
				</div>

				{/* Tabla */}
				<MaterialReactTable table={table} />
			</main>
		</>
	)
}
