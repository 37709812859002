import PropTypes from "prop-types"

import { cn } from "@/lib/utils"

export default function Logo({ className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="320 400 2350 2000"
			className={cn("fill-primary-green-1200", className)}
		>
			<g>
				<path d="M359.16,1405.7l-.06,289.69c0,9.94,5.3,19.12,13.9,24.09l250.86,144.81c9.27,5.35,20.85-1.34,20.85-12.04v-289.69c0-9.93-5.3-19.11-13.9-24.08l-250.8-144.81c-9.27-5.35-20.85,1.34-20.85,12.04Z" />
				<path d="M2358.24,911.07l-.06,289.69c0,9.94,5.3,19.12,13.9,24.09l250.86,144.81c9.27,5.35,20.85-1.34,20.85-12.04v-289.69c0-9.93-5.3-19.11-13.9-24.08l-250.8-144.81c-9.27-5.35-20.85,1.34-20.85,12.04Z" />
				<path d="M359.18,1199.64v-318.08c0-99.34,53-191.13,139.03-240.79l578.74-334.11c86.02-49.66,192-49.66,278.03,0l443.92,256.28c121.7,70.26,121.7,245.93,0,316.19l-513.42,296.41c-43.01,24.83-69.51,70.73-69.51,120.4v728.47c0,80.26-86.89,130.43-156.4,90.29h0c-79.92-46.14-129.15-131.41-129.16-223.7l-.05-679.65c0-99.35,52.99-191.15,139.03-240.82l380.04-219.39c23.17-13.38,23.17-46.82,0-60.2l-198.72-114.72c-21.5-12.41-48-12.41-69.5,0l-466.95,269.56c-43.02,24.84-69.52,70.74-69.52,120.42l.05,390.37c0,10.7-11.59,17.39-20.85,12.04l-250.86-144.87c-8.6-4.97-13.9-14.15-13.9-24.08Z" />
				<path d="M2629.89,1537.58c8.6,4.97,13.9,14.14,13.9,24.08v318.08c0,99.33-52.97,191.15-139.02,240.85l-578.74,334.06c-85.98,49.7-191.99,49.7-278.04,0l-332.13-191.72c-6.06-3.5-7.02-11.84-1.9-16.6,58.92-54.66,95.81-132.7,95.81-219.16v-247.67c0-9.93,5.3-19.11,13.9-24.08l293.82-169.64c43.03-24.82,69.51-70.69,69.51-120.39v-336.36c0-9.93,5.3-19.11,13.9-24.08l94.46-54.53c76.86-44.33,133.55-111.52,163.77-190.62,2.76-7.21,13.4-5.28,13.4,2.44v8.08s.08,679.67.08,679.67c0,99.33-52.97,191.15-139.02,240.85l-380.08,219.37c-23.15,13.35-23.15,46.78,0,60.2l198.73,114.69c21.55,12.44,48.03,12.44,69.51,0l466.97-269.56c43.03-24.82,69.51-70.69,69.51-120.39l-.07-390.4c0-10.7,11.59-17.39,20.85-12.04l250.86,144.87Z" />
			</g>
		</svg>
	)
}

Logo.propTypes = {
	className: PropTypes.string,
}
