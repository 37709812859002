// src/components/invoices/TableComponent.jsx
import propTypes from "prop-types";

// Import utils
import { Each } from "../../../utils/Each";
import { useTranslation } from 'react-i18next';

export const TableComponent = ({ data, ActionComponent, onClick }) => {
    // Mover el hook useTranslation dentro del componente
    const { t } = useTranslation(['debtSummary']);

    if (!data || data.length === 0) {
        return <p>{t('noInfo')}</p>; // Corregir la clave de traducción
    }

    // Filtrar los encabezados, excluyendo "Url" si existe
    const headers = Object.keys(data[0]).filter((header) => header !== "Url");

    return (
        <table className="table" style={{ marginBottom: "1rem" }}>
            <thead>
                <tr>
                    <Each
                        data={headers}
                        render={(header, headerIndex) => {
                            // Traducir cada encabezado de la tabla
                            return <th key={headerIndex}>{t(`headers.${header}`)}</th>;
                        }}
                    />
                    {ActionComponent && <th>{t('headers.actions')}</th>} {/* Agregar encabezado para acciones */}
                </tr>
            </thead>
            <tbody>
                <Each
                    data={data}
                    render={(item, itemIndex) => (
                        <tr key={itemIndex} onClick={() => onClick(item)}>
                            {headers.map((header, headerIndex) => (
                                <td key={headerIndex}>{item[header]}</td>
                            ))}
                            {ActionComponent && (
                                <td>
                                    <ActionComponent item={item} />
                                </td>
                            )}
                        </tr>
                    )}
                />
            </tbody>
        </table>
    );
};

TableComponent.propTypes = {
    data: propTypes.array.isRequired,
    ActionComponent: propTypes.elementType,
    onClick: propTypes.func,
};
