import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	id: "",
	name: "",
	lastname: "",
	email: "",
	phone: "",
	newUser: false,
	preferences: {
		inventoryManagement: "",
	},
	role: 0,
	welcome: false,
}

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, action) => {
			const { _id, name, email, lastname, newUser, preferences, activeInventory, phone } =
				action.payload

			state.id = _id
			state.name = name
			state.lastname = lastname
			state.email = email
			state.newUser = newUser
			state.preferences = preferences
			state.role = activeInventory
			state.phone = phone
			state.welcome = false
		},
		logout: (state) => {
			state.id = ""
			state.name = ""
			state.lastname = ""
			state.email = ""
			state.newUser = false
			state.phone = ""
			state.preferences = {
				inventoryManagement: "",
			}
			state.role = 0
		},
		editUser: (state, action) => {
			const { name, lastname, phone } = action.payload
			state.name = name || state.name
			state.lastname = lastname || state.lastname
			state.phone = phone || state.phone
			state.preferences = action.payload.preferences || state.preferences
		},
		setWelcome: (state) => {
			state.welcome = true
		},
	},
})

export const { login, logout, editUser, setWelcome } = userSlice.actions
export default userSlice.reducer
