import { useMemo, useEffect, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Bar } from 'react-chartjs-2';
import Header from '../../common/Header/Header';
import dataJson from './pruebaData.json';


// Configuración para Chart.js
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Deductions() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [period, setPeriod] = useState('sinFiltro');

    // Cargar datos desde el JSON
    useEffect(() => {
        setData(dataJson);
        setFilteredData(dataJson);
    }, []);

    // Función para parsear fechas en formato dd/mm/yyyy
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(year, month - 1, day);
    };

    // Filtrar datos según el período seleccionado
    const filterDataByPeriod = () => {
        const now = new Date();
        let filtered = data;

        if (period === 'quincenal') {
            const startOfPeriod = new Date(now);
            startOfPeriod.setDate(now.getDate() - 15);

            filtered = data.filter((row) => {
                const date = parseDate(row.date);
                return date >= startOfPeriod && date <= now;
            });
        } else if (period === 'mensual') {
            const startOfPeriod = new Date(now);
            startOfPeriod.setMonth(now.getMonth() - 1);

            filtered = data.filter((row) => {
                const date = parseDate(row.date);
                return date >= startOfPeriod && date <= now;
            });
        } else {
            filtered = data;
        }

        setFilteredData(filtered);
    };

    useEffect(() => {
        filterDataByPeriod();
    }, [period, data]);

    // Columnas de la tabla de deducciones
    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Nombre del Trabajador',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
            },
            {
                accessorKey: 'withholding',
                header: 'Retención en la Fuente',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
            },
            {
                accessorKey: 'healthContribution',
                header: 'Aporte a Salud',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
            },
            {
                accessorKey: 'pensionContribution',
                header: 'Aporte a Pensión',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
            },
            {
                accessorKey: 'deductions',
                header: 'Total de Deducciones',
                Cell: ({ row }) => (
                    row.original.withholding + row.original.healthContribution + row.original.pensionContribution
                ),
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
            },
            {
                accessorKey: 'date',
                header: 'Fecha',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
            }
        ],
        []
    );

    // Configuración de la tabla
    const table = useMaterialReactTable({
        columns,
        data: filteredData,
        enableRowSelection: true,
        enableColumnOrdering: false,
        enableGlobalFilter: true,
        initialState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0
            }
        },
        enablePagination: true,
        enableFullScreenToggle: true
    });

    // Obtener las filas seleccionadas
    const selectedRows = table.getSelectedRowModel().flatRows;
    const displayedData = selectedRows.length > 0
        ? selectedRows.map((row) => row.original)
        : filteredData;

    // Calcular los totales de las deducciones y porcentajes
    const totalWithholding = displayedData.reduce((sum, row) => sum + row.withholding, 0);
    const totalHealth = displayedData.reduce((sum, row) => sum + row.healthContribution, 0);
    const totalPension = displayedData.reduce((sum, row) => sum + row.pensionContribution, 0);
    const totalDeductions = totalWithholding + totalHealth + totalPension;

    const withholdingPercentage = ((totalWithholding / totalDeductions) * 100).toFixed(2);
    const healthPercentage = ((totalHealth / totalDeductions) * 100).toFixed(2);
    const pensionPercentage = ((totalPension / totalDeductions) * 100).toFixed(2);

    // Configuración de datos y opciones para el gráfico de barras
    const chartData = {
        labels: ['Retención en la Fuente', 'Aporte a Salud', 'Aporte a Pensión'],
        datasets: [
            {
                label: 'Total de Deducciones',
                data: [totalWithholding, totalHealth, totalPension],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                borderWidth: 1
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Total de Deducciones por Tipo'
            }
        }
    };

    // Manejar el cambio de periodo en el filtro
    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    return (
        <>
            <Header title={"Deducciones"} />
            <main className="w-full pt-[3px] ">
                <div className="flex justify-center items-center font-bold p-4 text-base">
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Total de Deducciones: ${totalDeductions}
                    </p>
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Porcentaje Retención: {withholdingPercentage}%
                    </p>
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Porcentaje Salud: {healthPercentage}%
                    </p>
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Porcentaje Pensión: {pensionPercentage}%
                    </p>
                    <select
                        className="appearance-none border-none outline-none p-3 pr-10 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)] text-center relative"

                        value={period}
                        onChange={handlePeriodChange}
                        style={{
                            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='black'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a 1 1 0 010-1.414z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
                            backgroundPosition: 'right 0.5rem center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '1rem',
                            fontSize: "0.9rem", margin: "0 0.5rem"
                        }}
                    >
                        <option value="sinFiltro">Sin Filtro</option>
                        <option value="quincenal">Quincenal</option>
                        <option value="mensual">Mensual</option>
                    </select>
                </div>

                {/* Table for deductions breakdown */}
                <MaterialReactTable table={table} />

                {/* Bar Chart */}
                <div className="mt-8 flex justify-center">
                    <div className="w-full max-w-[600px] h-auto">
                        <Bar data={chartData} options={chartOptions} />
                    </div>
                </div>

            </main>
        </>
    );
}
