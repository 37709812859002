import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { HoverCard, HoverCardTrigger, HoverCardContent } from "@/components/ui/hover-card"
import WindowApiKey from "@/components/new-front/WindowApiKey/WindowApiKey"

function WindowWelcome() {
	const { t } = useTranslation("windowWelcome")
	const [isOpen, setIsOpen] = useState(true)
	const [showKeyRequest, setShowKeyRequest] = useState(false)

	const handleSubmit = (e) => {
		e.preventDefault()
		alert(t("messages.connectionSuccess"))
	}

	const openWindowApiKey = () => {
		setIsOpen(false)
		setShowKeyRequest(true)
	}

	return (
		<>
		
			{showKeyRequest && (
				<WindowApiKey show={showKeyRequest} onClose={() => setShowKeyRequest(false)} />
			)}
			<Dialog open={isOpen} onOpenChange={setIsOpen}>
				<DialogContent>
					<div className="px-12 py-6 flex flex-col gap-5 items-center">
						<DialogHeader>
							<DialogTitle>{t("dialogTitle")}</DialogTitle>
						</DialogHeader>
						<p className="text-center">{t("description.main")}</p>
						<p className="text-center font-bold">{t("description.secondary")}</p>
						<div onSubmit={handleSubmit} className="mt-6 space-y-4">
							<div className="flex justify-center">
								<Button variant="primary" type="button" onClick={openWindowApiKey}>
									{t("form.connectButton")}
								</Button>
							</div>
							<DialogDescription className="mt-4">
								{t("dialogDescription.beforeHoverCard")}{" "}
								<HoverCard>
									<HoverCardTrigger asChild>
										<a
											className="text-primary-green-1400 underline cursor-pointer"
											onClick={openWindowApiKey}
										>
											{t("dialogDescription.videoButton")}
										</a>
									</HoverCardTrigger>
									<HoverCardContent className="w-80">
										<div className="flex justify-between space-x-4">
											<div className="space-y-1">
												<h4 className="text-sm font-semibold">
													{t("dialogDescription.videoTitle")}
												</h4>
												<p className="text-sm">{t("dialogDescription.videoDesc")}</p>
												<div className="flex items-center pt-2" />
											</div>
										</div>
									</HoverCardContent>
								</HoverCard>
							</DialogDescription>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default WindowWelcome
