import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/notification"

export async function getAllNotificationsByFilter(filter) {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/all`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
			params: {
				...filter.type,
				...filter.status,
				...filter.date,
				...filter.priority,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error reading notifications:", error)
		throw error
	}
}

export async function setAllNotificationsAsCanceled() {
	try {
		const response = await axios.delete(`${VITE_BASE_URL}/clear`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error clearing notifications:", error)
		throw error
	}
}

export async function setNotificationsAsDone(id) {
	try {
		const response = await axios.put(
			`${VITE_BASE_URL}/${id}/done`,
			{},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error changing notification status:", error)
		throw error
	}
}
export async function setNotificationsAsCanceled(id) {
	try {
		const response = await axios.put(
			`${VITE_BASE_URL}/${id}/cancel`,
			{},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error changing notification status:", error)
		throw error
	}
}

export async function addNotification(name, type, description) {
	try {
		const response = await axios.post(
			`${VITE_BASE_URL}`,
			{
				name,
				metadata: {
					type,
					description,
				},
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error adding the notification:", error)
		throw error
	}
}

const endPointURL = "http://localhost:8080/api/emails/send-custom";


/**
 * Actualiza la frecuencia de notificaciones del usuario
 * @param {string} token
 * @param {string} frequency - Nueva frecuencia (ej: "Diario", "Semanal", "Quincenal")
 * @returns {Promise} 
*/
/* export async function updateUserNotificationFrequency(token, frequency) {
	return axios.put(
		`${endPointURL}`, //esto es mal, pero es temporal
		{ preferences: { frequency } },
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json"
			}
		}
	);
} */

//Esto esta correctamente implementado, solo no se usa porque su logica se paso a una lambda
/**
 * Envía el correo de notificación
 * @param {string} token 
 * @param {Object} payload - Datos del correo: {from, to, subject, userName, invoiceCount, totalSum}
 * @returns {Promise} 
 */
export async function sendNotificationEmail({ from, to, subject, userName, invoiceCount, totalSum }) {
	try {
		const response = await axios.post(
			endPointURL,
			{ from, to, subject, userName, invoiceCount, totalSum },
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
					"Content-Type": "application/json",
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error sending notification email:", error);
		throw error;
	}
}
