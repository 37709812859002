import React, { useState } from "react"

// Import SVG
import { MdDelete, MdHideImage } from "react-icons/md"
import { HiOutlineReceiptTax } from "react-icons/hi"

// Import Utils
import { formatPrice } from "../../../utils/helpers"
import PropTypes from "prop-types"

const SendTable = ({ data = [], setData }) => {
	// Function to handle PUC selection change
	const handlePUCChange = (index, selectedPUC) => {
		const updatedData = [...data]
		updatedData[index] = {
			...updatedData[index],
			defaultPUC: selectedPUC, // Actualizar el PUC seleccionado
		}
		setData(updatedData)
	}

	// Function to handle row removal
	const handleRemoveRow = (index) => {
		const updatedData = data.filter((_, i) => i !== index)
		setData(updatedData)
	}

	// State to control visibility of the taxes table for each invoice
	const [visibleTaxes, setVisibleTaxes] = useState({})

	// Toggle visibility for a specific invoice
	const toggleTaxesVisibility = (index) => {
		setVisibleTaxes((prev) => ({
			...prev,
			[index]: !prev[index],
		}))
	}
	return (
		<table className="table-auto w-full border-collapse border border-gray-400 ">
			<thead>
				<tr>
					<th className="border border-gray-300 p-2">Proveedor</th>
					<th className="border border-gray-300 p-2">Número de Factura</th>
					<th className="border border-gray-300 p-2">Total Factura</th>
					<th className="border border-gray-300 p-2">PUC</th>
					<th className="border border-gray-300 p-2">Acciones</th>
				</tr>
			</thead>
			<tbody>
				{data.length === 0 ? (
					<tr>
						<td colSpan="5" className="text-center p-4">
							No hay datos disponibles
						</td>
					</tr>
				) : (
					data.map((item, index) => (
						<React.Fragment key={item._id || index}>
							{/* Fila principal con la información básica de la factura */}
							<tr
								className={`border-b border-gray-300 ${visibleTaxes[index] ? "bg-gray-100" : ""}`}
							>
								<td className="border border-gray-300 p-2">
									{item.providerName || "N/A"}
								</td>
								<td className="border border-gray-300 p-2">
									{item.invoiceId || "N/A"}
								</td>
								<td className="border border-gray-300 p-2">
									{formatPrice(item.invoiceTotal) || "N/A"}
								</td>
								<td className="border border-gray-300 p-2">
									<select
										value={item.defaultPUC || ""}
										onChange={(e) => handlePUCChange(index, e.target.value)}
										className="border border-gray-300 p-1 rounded w-full"
									>
										{(item.PUC?.concat(item.inferedPuc) || []).map((puc) => (
											<option key={puc} value={puc}>
												{puc}
											</option>
										))}
									</select>
								</td>
								<td className="border border-gray-300 p-2 flex justify-center gap-2">
									{/* Mostrar botón solo si hay impuestos */}
									{item.taxes?.length > 0 && (
										<button
											className="bg-blue-500 text-white px-4 py-2 rounded"
											onClick={() => toggleTaxesVisibility(index)}
										>
											{visibleTaxes[index] ? (
												<MdHideImage />
											) : (
												<HiOutlineReceiptTax />
											)}
										</button>
									)}
									<button
										className="bg-red-500 text-white px-4 py-2 justify-center rounded"
										onClick={() => handleRemoveRow(index)}
									>
										<MdDelete />
									</button>
								</td>
							</tr>

							{/* Mostrar mensaje si no hay impuestos o mostrar la tabla de impuestos */}
							{visibleTaxes[index] && (
								<tr>
									<td colSpan="5" className="p-0">
										{item.taxes?.length > 0 ? (
											<table className="table-auto w-full border-collapse border border-gray-400 mt-0 bg-white">
												<thead>
													<tr className="bg-gray-200">
														<th className="border border-gray-300 p-2 text-center">
															Impuesto
														</th>
														<th className="border border-gray-300 p-2 text-center">
															Monto
														</th>
														<th className="border border-gray-300 p-2 text-center">
															Porcentaje
														</th>
													</tr>
												</thead>
												<tbody>
													{item.taxes.map((tax, taxIndex) => (
														<tr key={`${item._id}-${taxIndex}`}>
															<td className="border border-gray-300 p-2 text-center">
																{tax.name}
															</td>
															<td className="border border-gray-300 p-2 text-center">
																{formatPrice(tax.amount)}
															</td>
															<td className="border border-gray-300 p-2 text-center">
																{tax.percent}%
															</td>
														</tr>
													))}
												</tbody>
											</table>
										) : (
											<div className="p-4 text-center bg-gray-100">
												No hay impuestos para esta factura
											</div>
										)}
									</td>
								</tr>
							)}
						</React.Fragment>
					))
				)}
			</tbody>
		</table>
	)
}

SendTable.propTypes = {
	data: PropTypes.array,
	setData: PropTypes.func.isRequired,
}

export default SendTable
