import propTypes from "prop-types"
import { cn } from "@/lib/utils"

export default function FormCheckInput({ id, label, className, value, onChange }) {
	return (
		<div className={cn("flex gap-2 group items-center", className)}>
			<label htmlFor={id} className="text-gray-500 group-hover:text-[#006e25]">
				{label}
			</label>
			<input
				id={id}
				type="checkbox"
				className="h-5 w-5 border-gray-300 rounded focus:border-[#006e25] group-hover:border-[#006e25]"
				checked={value}
				onChange={onChange}
			/>
		</div>
	)
}

FormCheckInput.propTypes = {
	id: propTypes.string.isRequired,
	label: propTypes.string,
	className: propTypes.string,
	value: propTypes.bool,
	onChange: propTypes.func,
}
