import React from "react"
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { Input } from "@/components/ui/input"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { useTranslation } from "react-i18next"
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from "@/components/ui/pagination"
import { formatNumber } from "@/utils/formatNumber"

export function DataTableDianTokenInfo({ data }) {
	const [sorting, setSorting] = React.useState([])
	const [columnFilters, setColumnFilters] = React.useState([])
	const [columnVisibility, setColumnVisibility] = React.useState({})
	const [rowSelection, setRowSelection] = React.useState({})
	const [globalFilter, setGlobalFilter] = React.useState("")
	const { t } = useTranslation(["causation"])

	const columns = [
		{
			accessorKey: "cufe",
			header: <div>{t("labels.cufe")}</div>,
			cell: ({ row }) => <div className="line-clamp-1">{row.getValue("cufe").substring(0,20) + "..."}</div>,
		},
		{
			accessorKey: "nit",
			header: <div>{t("labels.nit")}</div>,
			cell: ({ row }) => row.getValue("nit"),
		},
		{
			accessorKey: "provider",
			header: <div>{t("labels.provider")}</div>,
			cell: ({ row }) => <div className="capitalize">{row.getValue("provider")}</div>,
		},
		{
			accessorKey: "total",
			header: <div className="text-right">{t("labels.total")}</div>,
			cell: ({ row }) => <div className="text-right">{formatNumber(row.getValue("total"))}</div>,
		},
	]

	const table = useReactTable({
		data,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			globalFilter,
		},
	})

	return (
		<div className="w-full">
			<div className="flex w-full mb-2">
				<div className="flex flex-grow">
					<Input
						type="text"
						placeholder={t("labels.inputPlaceholder")}
						variant="search"
						className="h-8 text-sm w-1/2"
						autoComplete="off"
						spellCheck="false"
						value={globalFilter}
						onChange={(event) => {
							setGlobalFilter(event.target.value)
						}}
					/>
				</div>
			</div>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Pagination className="mt-2">
				<PaginationContent>
					<PaginationItem>
						<PaginationPrevious
							onClick={() => {
								if (table.getCanPreviousPage()) table.previousPage()
							}}
							disabled={!table.getCanPreviousPage()}
							displayName={t("pagination.previous")}
						/>
					</PaginationItem>
					{table.getState().pagination.pageIndex > 1 && (
						<>
							<PaginationItem>
								<PaginationLink onClick={() => table.setPageIndex(0)}>1</PaginationLink>
							</PaginationItem>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
						</>
					)}
					{table.getState().pagination.pageIndex > 0 && (
						<PaginationItem>
							<PaginationLink
								onClick={() => table.setPageIndex(table.getState().pagination.pageIndex - 1)}
							>
								{table.getState().pagination.pageIndex}
							</PaginationLink>
						</PaginationItem>
					)}
					<PaginationItem>
						<PaginationLink isActive>{table.getState().pagination.pageIndex + 1}</PaginationLink>
					</PaginationItem>
					{table.getPageCount() >= table.getState().pagination.pageIndex + 2 && (
						<PaginationItem>
							<PaginationLink
								onClick={() => table.setPageIndex(table.getState().pagination.pageIndex + 1)}
							>
								{table.getState().pagination.pageIndex + 2}
							</PaginationLink>
						</PaginationItem>
					)}
					{table.getPageCount() > table.getState().pagination.pageIndex + 2 && (
						<>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
							<PaginationItem>
								<PaginationLink onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
									{table.getPageCount()}
								</PaginationLink>
							</PaginationItem>
						</>
					)}
					<PaginationItem>
						<PaginationNext
							onClick={() => {
								if (table.getCanNextPage()) table.nextPage()
							}}
							disabled={!table.getCanNextPage()}
							displayName={t("pagination.next")}
						/>
					</PaginationItem>
				</PaginationContent>
			</Pagination>
		</div>
	)
}
