import { useTranslation } from "react-i18next"
import Header from "../../common/Header/Header"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { TbArrowBearRight2, TbCalculator, TbReportAnalytics, TbUsersGroup } from "react-icons/tb"
import { useEffect, useState } from "react"
import { countEmployees } from "../../../services/employees"
import { getPayrollRecord, getPeriodDates } from "../../../services/payroll"

export default function Payroll() {
	const navigate = useNavigate()
	const { t } = useTranslation(["payroll"])

	const [numEmployees, setNumEmployees] = useState(0)
	const [totalAmount, setTotalAmount] = useState(0)
	const [dates, setDates] = useState({
		default: "monthly",
		monthly: {
			start: "",
			end: "",
			payment: "",
		},
		"bi-weekly": {
			start: "",
			end: "",
			payment: "",
		},
	})
	// new Date().toUTCString

	useEffect(() => {
		countEmployees().then((data) => setNumEmployees(data))
		getPeriodDates().then((data) => setDates(data))
		getPayrollRecord(dates.default).then((data) => {
			setTotalAmount(data.payrollRecords.reduce((acc, record) => acc + record.netSalary, 0))
		})
	}, [])
	return (
		<>
			<Header title={t("title")} />
			<main className="px-12 w-full flex flex-col gap-8 py-8">
				<section className="bg-[--primary] shadow-lg rounded-xl pl-2">
					<div className="p-8 rounded-xl w-full bg-[--tertiary] flex justify-around">
						<article className="flex flex-col gap-1 py-4 items-center">
							<span className="text-[--separator] text-xl self-start">Total Amount</span>
							<p className="text-2xl self-start">${totalAmount}</p>
						</article>
						<div className="border border-[--separator]"></div>
						<article className="flex flex-col gap-1 py-4 items-center">
							<span className="text-[--separator] text-xl self-start">Payment date</span>
							<p className="text-2xl self-start">{dates[dates.default].payment}</p>
						</article>
						<div className="border border-[--separator]"></div>
						<article className="flex flex-col gap-1 py-4 items-center">
							<span className="text-[--separator] text-xl self-start">Period range</span>
							<p className="text-2xl self-start">{dates[dates.default].start} - {dates[dates.default].end}</p>
						</article>
					</div>
				</section>
				<section className="grid grid-cols-2 xl:grid-cols-3 grid-rows-2 gap-10">
					<section className="xl:col-span-2 row-span-2 grid xl:grid-cols-2 gap-8 bg-[--tertiary] p-8 shadow-lg rounded-xl">
						<article className="flex flex-col justify-center gap-2">
							<div className="flex gap-4 items-center mx-auto w-full 2xl:w-3/4 max-w-72">
								<figure className="rounded-full p-2 bg-blue-200">
									<TbCalculator className="h-10 w-10 text-blue-600" />
								</figure>
								<div className="flex flex-col gap-1 items-start">
									<span className="text-[--separator] text-xl">Calculations</span>
									<p className="text-2xl">$10.000.000,00</p>
								</div>
							</div>
							<button
								className="text-blue-500 hover:underline"
								onClick={() => {
									window.scrollTo({ top: 0 })
									navigate("/payroll/calculations")
								}}
							>
								View more
							</button>
						</article>
						<article className="flex flex-col justify-center gap-2">
							<div className="flex gap-4 items-center mx-auto w-full 2xl:w-3/4 max-w-72">
								<figure className="rounded-full p-2 bg-green-200">
									<TbReportAnalytics className="h-10 w-10 text-green-600" />
								</figure>
								<div className="flex flex-col gap-1 items-start">
									<span className="text-[--separator] text-xl">Expense reports</span>
									<p className="text-2xl">$10.000.000,00</p>
								</div>
							</div>
							<button
								className="text-blue-500 hover:underline"
								onClick={() => {
									window.scrollTo({ top: 0 })
									navigate("/payroll/expenseReports")
								}}
							>
								View more
							</button>
						</article>
						<article className="flex flex-col justify-center gap-2">
							<div className="flex gap-4 items-center mx-auto w-full 2xl:w-3/4 max-w-72">
								<figure className="rounded-full p-2 bg-red-200">
									<TbArrowBearRight2 className="h-10 w-10 text-red-600" />
								</figure>
								<div className="flex flex-col gap-1 items-start">
									<span className="text-[--separator] text-xl">Deductions</span>
									<p className="text-2xl">$10.000.000,00</p>
								</div>
							</div>
							<button
								className="text-blue-500 hover:underline"
								onClick={() => {
									window.scrollTo({ top: 0 })
									navigate("/payroll/deductions")
								}}
							>
								View more
							</button>
						</article>
						<article className="flex flex-col justify-center gap-2">
							<div className="flex gap-4 items-center mx-auto w-full 2xl:w-3/4 max-w-72">
								<figure className="rounded-full p-2 bg-orange-200">
									<TbUsersGroup className="h-10 w-10 text-orange-600" />
								</figure>
								<div className="flex flex-col gap-1 items-start">
									<span className="text-[--separator] text-xl">Contributions</span>
									<p className="text-2xl">$10.000.000,00</p>
								</div>
							</div>
							<button
								className="text-blue-500 hover:underline"
								onClick={() => {
									window.scrollTo({ top: 0 })
									navigate("/payroll/contributions")
								}}
							>
								View more
							</button>
						</article>
					</section>
					<section className="shadow-lg rounded-xl bg-[--tertiary] p-4 min-h-40 flex flex-col items-center justify-evenly">
						<p className="text-6xl text-green-700">{numEmployees}</p>
						<button
							className="w-min text-nowrap mx-auto px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
							onClick={() => {
								window.scrollTo({ top: 0 })
								navigate("/employees")
							}}
						>
							Manage employees
						</button>
					</section>
					<section className="shadow-lg rounded-xl bg-[--tertiary] p-4 min-h-40 flex flex-col items-center justify-evenly">
						<h3 className="text-2xl text-center">Payroll actions</h3>
						<button
							className="w-min text-nowrap mx-auto px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
							onClick={() => {
								window.scrollTo({ top: 0 })
								navigate("/payroll/periods")
							}}
						>
							Manage my periods
						</button>
						<button
							className="w-min text-nowrap mx-auto px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
							onClick={() => {
								navigate("/profile", { state: { targetId: "preferences" } })
							}}
						>
							Preferences
						</button>
					</section>
				</section>
			</main>
			<ToastContainer />
		</>
	)
}
