// src/components/Modules.jsx
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import './modules.css'
import { TbCalendarDollar, TbInvoice } from 'react-icons/tb'
import { uploadTokenFile } from '../../../services/invoice'
import { GrValidate } from 'react-icons/gr'
import Header from '../../common/Header/Header'
import { useTranslation } from 'react-i18next'


export const toggleToast = text => {
  toast(text, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  })
}

export const Modules = () => {
  const { t, i18n } = useTranslation(['modules'])
  const [showUpload, setShowUpload] = useState(false)
  const [file, setFile] = useState(null)
  const [comparisonResult, setComparisonResult] = useState(null)

  const firstLoad = useRef(true)
  const navigate = useNavigate()

  const handleFileChange = event => {
    setFile(event.target.files[0])
  }

  const handleFileUpload = async () => {
    try {
      if (!file) {
        toggleToast(t('modules.messages.pleaseSelectFile'))
        return
      }
      const result = await uploadTokenFile(file)
      setComparisonResult(result)
      toggleToast(t('modules.messages.fileUploadedSuccess'))
    } catch (error) {
      console.error('Error uploading file:', error)
      toggleToast(t('modules.messages.fileUploadFailed'))
    }
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    }
  }, [])

  return (
    <>


      <Header title={t('modules.title')} /> {/* Mantener dinámico */}
      {/* contenedor cartas*/}
      
      {/* antiguo*/}
      <div>
        <div className='workflows w-full p-8 flex flex-col'>
          <div className='items'>
            {/* Elemento 1: Cuentas por pagar */}
            <div className='flex justify-between items-center w-3/12'>
              <p className='flex gap-2 items-center'>
                <TbCalendarDollar className='h-8 w-8' />{' '}
                {t('modules.labels.cuentasPorPagar')}
              </p>
              <button
                className='px-2 py-1 rounded-md bg-green-500/20 border border-green-500/25 hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md'
                onClick={() => navigate('/debt-summary')}
              >
                {t('modules.buttons.ver')}
              </button>
            </div>

            {/* Elemento 2: Facturas no enviadas */}
            <div className='flex justify-between items-center w-3/12'>
              <p className='flex gap-2 items-center'>
                <TbInvoice className='h-8 w-8' />{' '}
                {t('modules.labels.facturasNoEnviadas')}
              </p>
              <button
                className='px-2 py-1 rounded-md bg-green-500/20 border border-green-500/25 hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md'
                onClick={() => navigate('/invoices')}
              >
                {t('modules.buttons.ver')}
              </button>
            </div>

            {/* Elemento 3: Conciliar con token DIAN */}
            <div className='flex justify-between items-center w-3/12'>
              <p className='flex gap-2 items-center'>
                <GrValidate className='h-8 w-8' />{' '}
                {t('modules.labels.conciliarConTokenDIAN')}
              </p>
              <button
                className='px-2 py-1 rounded-md bg-green-500/20 border border-green-500/25 hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md'
                onClick={() => setShowUpload(true)}
              >
                {t('modules.buttons.subir')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {showUpload && (
        <div className='fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center'>
          <div className='bg-white p-8 rounded-md max-w-6xl shadow-md w-full'>
            <h2 className='text-xl mb-4'>
              {t('modules.messages.comparacionToken')}
            </h2>
            <input
              type='file'
              accept='.xls,.xlsx, csv'
              onChange={handleFileChange}
              className='my-2 border px-2 py-1 rounded w-full'
            />
            <div className='flex justify-end mt-4'>
              <button
                className='px-4 py-2 rounded-md bg-green-500/20 border border-green-500/25 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md mr-2'
                onClick={handleFileUpload}
              >
                {t('modules.buttons.subirYComparar')}
              </button>
              <button
                className='bg-gray-500 text-white px-4 py-2 rounded-md'
                onClick={() => setShowUpload(false)}
              >
                {t('modules.buttons.cerrar')}
              </button>
            </div>

            {comparisonResult && (
              <div className='mt-8'>
                <h3 className='text-lg font-bold mb-4'>
                  {t('modules.messages.cufesEncontrados')}{' '}
                  <p className='text-green-700'>
                    {comparisonResult.existingInvoices.length}
                  </p>
                </h3>
                <div className='max-h-48 overflow-y-auto border p-4 rounded bg-gray-50'>
                  {comparisonResult.existingInvoices.length > 0 ? (
                    <ul className='list-disc pl-5 space-y-2'>
                      {comparisonResult.existingInvoices.map(
                        (invoice, index) => (
                          <li key={index}>
                            <strong>{t('modules.labels.nit')}</strong>:{' '}
                            {invoice.nit} <br />
                            <strong>{t('modules.labels.cufe')}</strong>:{' '}
                            {invoice.cufe}
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    <p>{t('modules.messages.noCufesFoundDatabase')}</p>
                  )}
                </div>

                <h3 className='text-lg font-bold mt-6 mb-4'>
                  {t('modules.messages.cufesFaltantes')}{' '}
                  <p className='text-red-400'>
                    {comparisonResult.missingCufes.length}
                  </p>
                </h3>
                <div className='max-h-48 overflow-y-auto border p-4 rounded bg-gray-50'>
                  {comparisonResult.missingCufes.length > 0 ? (
                    <ul className='list-disc pl-5 space-y-2'>
                      {comparisonResult.missingCufes.map((cufe, index) => (
                        <li key={index}>
                          <strong>{t('modules.labels.nit')}</strong>: {cufe.nit}{' '}
                          <br />
                          <strong>{t('modules.labels.cufe')}</strong>:{' '}
                          {cufe.cufe}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>{t('modules.messages.noMissingCufesFound')}</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
