import PropTypes from "prop-types"
import { AppSidebar } from "../common/SideBar/AppSideBar"
import { SidebarProvider } from "@/components/ui/sidebar"

export default function PrivatePageLayout({ children }) {
	return (
		<div className="w-full flex">
			<SidebarProvider>
				<AppSidebar />
				<div className="relative w-full">{children}</div>
			</SidebarProvider>
		</div>
	)
}

PrivatePageLayout.propTypes = {
	children: PropTypes.node,
}
