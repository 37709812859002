// Input.jsx

import React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import { Search } from 'lucide-react'

const inputVariants = cva(
  'flex h-12 rounded-full bg-primary-grey-50 px-4 py-2 text-base border border-primary-grey-300 focus-visible:outline-none',
  {
    variants: {
      variant: {
        default: '',
        search: 'pl-10'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

const Input = React.forwardRef(
  ({ className, type, variant, ...props }, ref) => {
    if (variant === 'search') {
      return (
        <div className='relative flex items-center px-2 w-full'>
          <Search className='absolute left-5 h-4  text-gray-500' />
          <input
            type={type}
            className={cn(
              inputVariants({ variant }),
              'flex h-8 text-sm',
              className
            )}
            ref={ref}
            {...props}
          />
        </div>
      )
    }

    return (
      <input
        type={type}
        className={cn(inputVariants({ variant }), className)}
        ref={ref}
        {...props}
      />
    )
  }
)

Input.displayName = 'Input'

export { Input }
