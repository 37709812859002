import { useState } from "react"

import {
	CircleHelp,
	FileSpreadsheet,
	Home,
	PackageOpen,
	PanelLeftClose,
	PanelLeftOpen,
	Scroll,
	Settings,
} from "lucide-react"
import { useTranslation } from "react-i18next"

import { cn } from "@/lib/utils"
import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarGroup,
	SidebarGroupContent,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	SidebarMenuSub,
	SidebarMenuSubButton,
	SidebarMenuSubItem,
} from "@/components/ui/sidebar"
import { Separator } from "@/components/ui/separator"
import Logo from "../icons/Logo"
import { Avatar } from "@/components/ui/avatar"
import { AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { createSelector } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

const selectUser = createSelector(
	(state) => state.user,
	(user) => user,
)

export function AppSidebar() {
	const { t } = useTranslation(["sidebar"])
	const user = useSelector(selectUser)
	const navigate = useNavigate()
	const location = useLocation()
	const [collapsed, setCollapsed] = useState(false)

	// Menu items.
	const items = [
		{
			title: t("menu.home"),
			url: "/",
			icon: Home,
		},
		{
			title: t("menu.causation"),
			url: "/causation",
			icon: Scroll,
			submenu: [
				{
					title: t("menu.debts"),
					url: "/debts",
				},
				{
					title: t("menu.unsent-invoices"),
					url: "/invoices-not-sent",
				},
			],
		},
		{
			title: t("menu.management"),
			url: "#",
			icon: PackageOpen,
			submenu: [
				{
					title: t("menu.inventories"),
					url: "/inventory",
				},
				{
					title: t("menu.suppliers"),
					url: "/providers",
				},
			],
		},
		{
			title: t("menu.taxes"),
			url: "/taxes",
			icon: FileSpreadsheet,
		},
	]

	return (
		<div
			className={cn("relative h-svh min-w-[272px]", {
				"min-w-[83px]": collapsed,
			})}
		>
			<Sidebar
				className={cn("p-4 w-min fixed min-w-[272px]", {
					"px-2 min-w-[83px]": collapsed,
				})}
				collapsible="none"
			>
				<SidebarHeader
					className={cn("flex flex-row justify-between items-center gap-0", {
						"mb-6": !collapsed,
						"flex-col-reverse justify-normal": collapsed,
					})}
				>
					<Separator
						className={cn("border-t border-primary-grey-600 w-2/3 my-4", {
							hidden: !collapsed,
						})}
					/>
					<button
						onClick={() => {
							window.scrollTo({ top: 0 })
							navigate("")
						}}
					>
						<figure className="flex gap-1.5 items-center">
							<Logo
								className={cn("w-8 h-8 ml-1.5 transition-colors duration-300", {
									"m-0 fill-primary-grey-600 hover:fill-primary-green-1200": collapsed,
								})}
							/>
							<div className={cn("flex flex-col items-start", { hidden: collapsed })}>
								<span className="mt-3 text-3xl -mb-2 font-[FoundersGrotesk] text-primary-grey-1800">
									{t("logo.title")}
								</span>
								<span className="text-xs text-primary-grey-1000">{t("logo.subtitle")}</span>
							</div>
						</figure>
					</button>
					<Separator
						className={cn("border-t border-primary-grey-600 w-2/3 my-4", {
							hidden: !collapsed,
						})}
					/>
					<button
						onClick={() => setCollapsed(!collapsed)}
						className={cn({
							"mt-4": collapsed,
						})}
					>
						{collapsed ? <PanelLeftOpen /> : <PanelLeftClose />}
					</button>
				</SidebarHeader>
				<SidebarContent className={cn("flex flex-col", { "w-min mx-auto -mt-2": collapsed })}>
					<SidebarGroup>
						<SidebarGroupContent>
							<SidebarMenu>
								{items.map((item) => (
									<SidebarMenuItem key={item.title}>
										<SidebarMenuButton
											className="text-base h-9 hover:bg-primary-grey-200"
											onClick={() => {
												window.scrollTo({ top: 0 })
												navigate(item.url)
											}}
											variant={location.pathname === item.url ? "active" : "default"}
										>
											<item.icon className="h-6 w-6" />
											{!collapsed && <span>{item.title}</span>}
										</SidebarMenuButton>
										{item.submenu && !collapsed && (
											<SidebarMenuSub>
												{item.submenu.map((subitem) => (
													<SidebarMenuSubItem key={subitem.title}>
														<SidebarMenuSubButton
															className={cn(
																"ease-in-out duration-300 transition text-base h-9 hover:bg-primary-grey-200 cursor-pointer border border-transparent",
																{
																	"border-primary-grey-600": location.pathname === subitem.url,
																},
															)}
															onClick={() => {
																window.scrollTo({ top: 0 })
																navigate(subitem.url)
															}}
														>
															<span>{subitem.title}</span>
														</SidebarMenuSubButton>
													</SidebarMenuSubItem>
												))}
											</SidebarMenuSub>
										)}
									</SidebarMenuItem>
								))}
							</SidebarMenu>
						</SidebarGroupContent>
					</SidebarGroup>
				</SidebarContent>
				<SidebarFooter>
					<SidebarMenu className={cn("mb-4", { "w-min mx-auto": collapsed })}>
						<SidebarMenuItem>
							<SidebarMenuButton
								className="text-base h-9 hover:bg-primary-grey-200 ease-in-out duration-300 transition"
								onClick={() => {
									window.scrollTo({ top: 0 })
									navigate("/settings")
								}}
								variant={location.pathname === "/settings" ? "active" : "default"}
								>
								<Settings className="h-6 w-6" />
								{!collapsed && <span>{t("menu.settings")}</span>}
							</SidebarMenuButton>
						</SidebarMenuItem>
						<SidebarMenuItem>
							<SidebarMenuButton
								className="text-base h-9 hover:bg-primary-grey-200"
								variant={location.pathname === "/help-center" ? "active" : "default"}
								onClick={() => {
									window.scrollTo({ top: 0 })
									navigate("/help-center")
								}}
							>
								<CircleHelp className="h-6 w-6" />
								{!collapsed && <span>{t("menu.help-center")}</span>}
							</SidebarMenuButton>
						</SidebarMenuItem>
					</SidebarMenu>
					<SidebarMenu>
						<SidebarMenuItem>
							<SidebarMenuButton
								className="text-base h-12 hover:bg-primary-grey-200 flex gap-4 rounded-full"
								onClick={() => {
									window.scrollTo({ top: 0 })
									navigate("/profile")
								}}
								variant={location.pathname === "/profile" ? "active" : "default"}
							>
								<Avatar className="h-8 w-8 items-center justify-center">
									<AvatarImage src="" alt={`${user.name} ${user.lastname}`} />
									<AvatarFallback className="bg-primary-grey-400">
										{user.name[0]}
										{user.lastname[0]}
									</AvatarFallback>
								</Avatar>
								{!collapsed && (
									<div className="flex flex-col">
										<span>{t("greeting")}</span>
										<span className="line-clamp-1">
											{user.name} {user.lastname}
										</span>
									</div>
								)}
							</SidebarMenuButton>
						</SidebarMenuItem>
					</SidebarMenu>
				</SidebarFooter>
			</Sidebar>
		</div>
	)
}
