// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

// Inicializamos i18next
i18n
  .use(HttpBackend) // Carga las traducciones vía HTTP
  .use(LanguageDetector) // Detecta automáticamente el idioma del navegador
  .use(initReactI18next) // Enlaza con React
  .init({
    fallbackLng: 'es', // Idioma predeterminado
    debug: false, // Cambia a true para depuración

    ns: ['profile', 'sidebar', 'header', 'modules', 'financialTriage','providers','inventory','sales','salesForm','login', 'invoice', 'debtSummary', 'transaction', 'actualInventory'], // Añadimos ambos namespaces
    defaultNS: 'profile', // Namespace predeterminado

    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json', // Ruta para cargar las traducciones
    },

    interpolation: {
      escapeValue: false, // React ya realiza el escape
    },

    react: {
      useSuspense: true, // Habilita Suspense para la carga asíncrona
    },
  });

// Función para cargar namespaces dinámicamente
i18n.loadNamespace = async (namespace) => {
  const language = i18n.language || i18n.options.fallbackLng[0];
  if (!i18n.hasResourceBundle(language, namespace)) {
    const response = await fetch(`/translations/${i18n.language}/${namespace}.json`);
    if (response.ok) {
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const resources = await response.json();
        i18n.addResourceBundle(i18n.language, namespace, resources, true, true);
      }
    } else {
      console.error(`No se pudo cargar el namespace '${namespace}' para el idioma '${i18n.language}'`);
    }
  }
};

// Pre-carga los namespaces iniciales
const initialNamespaces = i18n.options.ns;

initialNamespaces.forEach((namespace) => {
  i18n.loadNamespace(namespace);
});

export default i18n;
