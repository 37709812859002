import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/invoice" // Replace with your API base URL

// Create
export const createInvoice = async (data, module, entity) => {
	try {
		const response = await axios.post(
			`${VITE_BASE_URL}`,
			{
				data,
				module,
				entity,
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			},
		)
		return response.data
	} catch (error) {
		console.error("Error creating item:", error)
		throw error
	}
}

export const getInvoicesOnModuleId = async (id) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting workspaces:", error)
		throw error
	}
}

export const getInvoiceByUID = async (id) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/one/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting workspaces:", error)
		throw error
	}
}
export const updateInvoice = async (id, data) => {
	try {
		const response = await axios.put(`${VITE_BASE_URL}/${id}`, data, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})

		return response
	} catch (error) {
		console.error("Error updating item:", error)
		throw error
	}
}

export const depositDebt = async (id, data) => {
	try {
		const { amount, paymentType, paymentDate } = data
		const response = await axios.put(
			`${VITE_BASE_URL}/deposit/${id}`,
			{
				amount,
				paymentType,
				paymentDate,
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			},
		)
		return response.data
	} catch (error) {
		console.error("Error depositing debt:", error)
		throw error
	}
}

export const getAllNotDownloadedInvoiceByModule = async (moduleId) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/module/${moduleId}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting invoices by module:", error)
		throw error
	}
}

export const getAllNotUploadedInvoicesERP = async (invoiceId) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/upload/${invoiceId}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response
	} catch (error) {
		console.error(error)
		throw error
	}
}

export const uploadInvoiceToERP = async (id, pucCode) => {
	try {
		console.log("pucCode", pucCode)
		const response = await axios.post(
			`${VITE_BASE_URL}/${id}`,
			{ pucCode },
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			},
		)
		return response
	} catch (error) {
		console.error("Error uploading invoice to ERP:", error)
		throw error
	}
}
export const deleteInvoice = async (id) => {
	try {
		const response = await axios.delete(`${VITE_BASE_URL}/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response
	} catch (error) {
		console.error("Error deleting invoice:", error)
		throw error
	}
}

export const getXMLvalues = async (file) => {
	try {
		const response = await axios.post(`${VITE_BASE_URL}/xml/file`, file, {
			headers: {
				"Content-Type": "multipart/form-data",
				"Authorization": "Bearer " + Cookies.get("token"),
			},
		})
		return response
	} catch (error) {
		console.error("Error getting XML values:", error)
		throw error
	}
}

export const getDebtSummary = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/debt/summary`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})

		return response.data
	} catch (error) {
		console.error("Error getting debt summary:", error)
		throw error
	}
}

export const getInvoiceByVendorId = async (id) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/vendor/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting invoices by vendor:", error)
		throw error
	}
}

export const getInvoiceUploadedByVendorId = async (id) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/vendor/uploaded/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting invoices by vendor:", error)
		throw error
	}
}

export const getProviderInfoInInvoice = async (id) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/provider/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting invoices by vendor:", error)
		throw error
	}
}

export const getInvoicePayments = async (id) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/debt/summary/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting payments by invoice:", error)
		throw error
	}
}

export const getTaxSummary = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/tax/summary`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting tax summary:", error)
		throw error
	}
}

export const uploadTokenFile = async (file) => {
	const formData = new FormData() // Create a form-data object to send the file to the backend
	formData.append("file", file)

	try {
		const response = await axios.post(`${VITE_BASE_URL}/upload/token`, formData, {
			headers: {
				"Authorization": `Bearer ${Cookies.get("token")}`,
				"Content-Type": "multipart/form-data",
			},
		})
		console.log("response.data", response.data)
		return response.data.data // Store the comparison result in state
	} catch (error) {
		console.error("File upload failed", error)
		throw error
	}
}

export const getAllTaxesCurrentMonth = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/all/taxes`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting tax summary:", error)
		throw error
	}
}

export const getAllCountTaxes = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/count/all`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting tax summary:", error)
		throw error
	}
}

export const getPaidInvoices = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/paid/summary`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting paid invoices:", error)
		throw error
	}
}

export const getSentInvoices = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/uploaded/summary`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting sent invoices:", error)
		throw error
	}
}

export const getUploadedInvoiceSummary = async (id) => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/uploaded/invoice/summary/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error fetching uploaded invoice summary:", error)
		throw error
	}
}
export const getPendingInvoices = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/pending/all`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error fetching pending and unsent invoices:", error)
		throw error
	}
}
