// src/components/Header.jsx
import { useContext, useEffect, useRef, useState } from "react"
import { FaBars, FaTimes } from "react-icons/fa"
import { useSelector } from "react-redux"
import "./Header.css"
import { PaymentInstructionsPop } from "../popUps/payment_instructionsPopUp/payment_instructions.jsx"
import NotificationsPopup from "../popUps/notificationsPopup/NotificationsPopup.jsx"
import { ReactReduxContext } from "react-redux"
import PropTypes from "prop-types"
import { getIntegrationbyUID } from "../../../services/integrations.js"
import { DragNdrop } from "../popUps/dragNdrop/dragNdrop.jsx"
import { FilesProgress } from "../popUps/filesprogress/filesprogress.jsx"
import { useTranslation } from "react-i18next"
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
	BreadcrumbPage,
	BreadcrumbEllipsis,
} from "@/components/ui/breadcrumb.jsx"
import { useNavigate } from "react-router-dom"
import { Button } from "@/components/ui/button.jsx"
import { Bell } from "lucide-react"
import { Progress } from "@/components/ui/progress.jsx"
import { Label } from "@radix-ui/react-label"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import WindowApiKey from "@/components/new-front/WindowApiKey/WindowApiKey"
import WindowConfigurationEmail from "@/components/new-front/WindowConfigurationEmail/WindowConfigurationEmail"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { CheckCircledIcon } from "@radix-ui/react-icons"
import { useDispatch } from "react-redux"
import { setGlobalProgress, stopProgress } from "@/redux/progressSlice"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

function Header({ title, sections = [] }) {
	const { t } = useTranslation(["header"])
	const navigate = useNavigate()
	const [triggers, setTriggers] = useState({
		header: null,
		bell: null,
	})
	const [apiUser, setApiUser] = useState(null)
	const [apiSecret, setApiSecret] = useState(null)
	const [showKeyRequest, setShowKeyRequest] = useState(false)
	const navRef = useRef()
	const [showPaymentInstructionsPop, setShowPaymentInstructionsPop] = useState(false)
	const [uploadFiles, setUploadFiles] = useState({
		show: false,
		id: "",
	})
	const [showProgress, setShowProgress] = useState(false)
	const [fileStatuses, setFileStatuses] = useState([])

	const showNavBar = () => {
		navRef.current.classList.toggle("responsive_nav")
	}

	const { store } = useContext(ReactReduxContext)
	const notifications = store.getState().notifications.notifications.length

	const [position, setPosition] = useState(1)
	const [visible, setVisible] = useState(true)
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [progressCompleted, setProgressCompleted] = useState(true)
	const [remainingTime, setRemainingTime] = useState(900)
	const dispatch = useDispatch()

	const isNewUser = useSelector((state) => state.user.newUser)
	const progress = useSelector((state) => state.progress.progress)
	const loading = useSelector((state) => state.progress.isLoading)

	useEffect(() => {
		if (loading) {
			setProgressCompleted(false)
		} else if (progress >= 100 && !loading) {
			setProgressCompleted(true)
			setIsDialogOpen(true)
			setProgressCompleted(true)
			dispatch(stopProgress())
		}
	}, [dispatch, isNewUser, progress, loading])

	useEffect(() => {
		if (progressCompleted || !loading) return
		const interval = setInterval(() => {
			if (progress >= 100) {
				clearInterval(interval)
				return
			}
			const newProgress = progress + 1 / 9
			dispatch(setGlobalProgress(newProgress))
		}, 1000)

		return () => clearInterval(interval)
	}, [dispatch, isNewUser, progressCompleted, loading, progress])
	useEffect(() => {
		// Update remaining time based on progress
		const totalDuration = 900 // Total duration in seconds
		const remaining = totalDuration - progress * 9
		setRemainingTime(Math.ceil(remaining / 60)) // Update remaining time with one decimal place
	}, [progress])

	useEffect(() => {
		const handleScroll = () => {
			let moving = window.scrollY
			setVisible(position >= moving)
			setPosition(moving)
		}
		window.addEventListener("scroll", handleScroll)
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [position])

	const uploadData = async () => {
		try {
			const response = await getIntegrationbyUID()
			setApiUser(response.apiKey)
			setApiSecret(response.apiSecret)
			setShowKeyRequest(true)
		} catch (error) {
			console.error("Error uploading data:", error)
			if (error.response.status === 404) {
				setApiSecret("")
				setApiUser("")
				setShowKeyRequest(true)
			}
		}
	}

	return (
		<>
			<PaymentInstructionsPop
				show={showPaymentInstructionsPop}
				onClose={() => setShowPaymentInstructionsPop(false)}
			/>
			
			<WindowApiKey show={showKeyRequest} onClose={() => setShowKeyRequest(false)} />
				
			<DragNdrop
				show={uploadFiles.show}
				onClose={() => {
					setUploadFiles({
						show: false,
						id: "",
					})
				}}
				setShowProgress={setShowProgress}
				setFileStatuses={setFileStatuses}
			/>
			<FilesProgress
				show={showProgress}
				onClose={() => {
					setShowProgress(false)
				}}
				data={fileStatuses}
				setData={setFileStatuses}
			/>
			<header
				className={`text-[--light-color] flex items-center justify-between sticky top-0 bg-[rgba(var(--tertiary),0.8)] backdrop-blur-md z-50 h-20 w-full px-8 transition-transform duration-300 ease-out mb-2 border-b border-primary-grey-50 ${
					visible ? "translate-y-0" : "-translate-y-20"
				}`}
			>
				<Breadcrumb>
					<BreadcrumbList className="xl:text-lg">
						{sections.length > 0 && (
							<>
								<BreadcrumbItem>
									<BreadcrumbLink asChild>
										<button
											className="text-primary-grey-1000 hover:underline hover:text-current"
											onClick={() => {
												window.scrollTo({ top: 0 })
												navigate(sections[0].href)
											}}
										>
											{sections[0].title}
										</button>
									</BreadcrumbLink>
								</BreadcrumbItem>
								<BreadcrumbSeparator />
								{sections.length > 1 && (
									<>
										<BreadcrumbItem>
											<DropdownMenu>
												<DropdownMenuTrigger className="flex items-center gap-1">
													<BreadcrumbEllipsis className="h-4 w-4" />
													<span className="sr-only">Toggle menu</span>
												</DropdownMenuTrigger>
												<DropdownMenuContent align="start">
													{sections.slice(1).map((section, index) => (
														<DropdownMenuItem key={index}>
															<BreadcrumbLink asChild>
																<Button variant="ghost" className="normal-case" onClick={() => navigate(section.href)}>
																	{section.title}
																</Button>
															</BreadcrumbLink>
														</DropdownMenuItem>
													))}
												</DropdownMenuContent>
											</DropdownMenu>
										</BreadcrumbItem>
										<BreadcrumbSeparator />
									</>
								)}
							</>
						)}
						<BreadcrumbItem>
							<BreadcrumbPage className="text-primary-grey-1800">{title}</BreadcrumbPage>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
				{!progressCompleted && (
					<div className="flex justify-between items-center">
						<div className="grid min-h-[140px]  place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
							<svg
								className="text-gray-300 animate-spin"
								viewBox="0 0 64 64"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
							>
								<path
									d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
									stroke="currentColor"
									strokeWidth="5"
									strokeLinecap="round"
									strokeLinejoin="round"
								></path>
								<path
									d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
									stroke="currentColor"
									strokeWidth="5"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="text-primary-green-1200"
								></path>
							</svg>
						</div>
						<Label
							htmlFor="progress"
							className="hidden 2xl:block text-xs text-primary-grey-1800 text-balance max-w-48"
						>
							{t("header.labels.loading")}
						</Label>

						<TooltipProvider delayDuration={200}>
							<Tooltip>
								<TooltipTrigger className="flex items-center gap-1">
									<div className="grid gap-1 cursor-pointer">
										<Progress value={progress} className="w-48" />

										<span className="text-xs text-primary-grey-1800 text-center">
											{t("header.labels.remainingTime")} {remainingTime}{" "}
											{t("header.labels.minutes")}
										</span>
									</div>
									<span className="text-sm text-primary-grey-1800 ml-2">
										{Math.round(progress)}%
									</span>
								</TooltipTrigger>
								<TooltipContent className="2xl:hidden w-52 rounded-lg p-2 bg-primary-grey-25 border border-primary-grey-200 text-primary-grey-1800 shadow-sm">
									<Label htmlFor="progress" className="text-xs text-primary-grey-1800 text-balance">
										{t("header.labels.loading")}
									</Label>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>
				)}
				<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<DialogContent className="w-max h-max bg-[#F7FCF7] rounded-lg shadow-lg border-2 border-[#2A7128] px-6 py-10 flex flex-col items-center text-center">
						<div className="flex justify-center items-center">
							<CheckCircledIcon className="w-12 h-12 text-[#51DA4C]" />
						</div>
						<DialogHeader>
							<DialogTitle className="text-center mx-24 mt-4">
								{t("header.labels.uploadSuccess")}
							</DialogTitle>
							<DialogDescription className="flex flex-col items-center text-center gap-8">
								<strong className="mt-5 mx-20 font-[poppins] font-black text-primary-grey-1600">
									{t("header.labels.emailConfig")}
									<a
										className="underline text-primary-green-1200 cursor-pointer"
										href="https://support.google.com/mail/answer/10957?hl=es-419&visit_id=638724088349166516-866977975&rd=1"
										target="_blank"
										rel="noreferrer"
										aria-label="Google support"
									>
										{t("header.labels.here")}
									</a>
									.
								</strong>
								<p className="mx-20">{t("header.labels.uploadSuccessMessage")}</p>
								<Button
									className="w-fit"
									variant="secondary"
									onClick={() => {
										setIsDialogOpen(false)
										window.scrollTo({ top: 0 })
										navigate("/")
									}}
								>
									{t("header.buttons.visualize")}
								</Button>
							</DialogDescription>
						</DialogHeader>
					</DialogContent>
				</Dialog>

				<nav ref={navRef}>
					<ul className="flex gap-8 mr-2 h-20">
						<div className="user flex justify-center items-center h-20 gap-8">
							<div className="flex gap-4">
								<Button
									variant="primary"
									onClick={() =>
										setUploadFiles({
											show: true,
										})
									}
								>
									{t("header.buttons.subirFacturas")}
								</Button>
								<Button variant="secondary" onClick={uploadData}>
									{t("header.buttons.misAPIKeys")}
								</Button>
							</div>
							<div className="opt relative flex flex-col justify-center items-center cursor-pointer">
								<Button
									id="showNotifications"
									onClick={() =>
										setTriggers((prevTriggers) => ({
											...prevTriggers,
											bell: !prevTriggers.bell,
										}))
									}
									className="px-2 py-0 [&_svg]:size-7 hover:bg-primary-grey-200"
									variant="ghost"
								>
									<Bell className="text-primary-grey-1800" />
									{notifications > 0 && (
										<div className="absolute h-4 w-4 rounded-full bg-red-400 top-0.5 right-1.5 text-center text-xs">
											{notifications}
										</div>
									)}
								</Button>
								<NotificationsPopup trigger={triggers.bell} setTriggers={setTriggers} />
							</div>
						</div>
						<button className="nav-btn nav-close-btn hidden" onClick={showNavBar}>
							<FaTimes />
						</button>
					</ul>
				</nav>
				<div className="burger-menu h-full flex items-center md:hidden">
					<button
						className="nav-btn p-1 cursor-pointer bg-transparent text-black"
						onClick={showNavBar}
					>
						<FaBars />
					</button>
				</div>
			</header>
		</>
	)
}

Header.propTypes = {
	title: PropTypes.string,
	sections: PropTypes.array,
}

export default Header
