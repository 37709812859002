import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createIntegration,
  authorizeIntegration,
  getIntegrationbyUID
} from '@/services/integrations'
import { updateUserNew } from '@/services/user'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '@/components/ui/accordion'
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent
} from '@/components/ui/hover-card'
import { CalendarIcon } from 'lucide-react'
import { useDispatch } from 'react-redux'
import { startProgress } from '@/redux/progressSlice'
import WindowConfigurationEmail from '@/components/new-front/WindowConfigurationEmail/WindowConfigurationEmail'

/**
 * Componente para solicitar y validar las llaves de la API de Siigo.
 *
 * @param {boolean} show - Controla si el diálogo está visible o no.
 * @param {function} onClose - Callback para cuando se cierra el diálogo.
 */
function WindowApiKey ({ show, onClose }) {
  const { t } = useTranslation('windowApiKey')

  // Estados principales
  const [integrationLevel, setIntegrationLevel] = useState('')
  const [apiUser, setApiUser] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [haveApi, setHaveApi] = useState(false)
  const [showEmailConfig, setShowEmailConfig] = useState(false)

  const dispatch = useDispatch()

  /**
   * Al montar el componente, consultamos si ya existe la integración.
   * Si sí existe, guardamos los valores y bloqueamos los inputs.
   */
  useEffect(() => {
    const checkIntegration = async () => {
      try {
        const result = await getIntegrationbyUID()
        if (result?.apiKey && result?.apiSecret) {
          setApiUser(result.apiKey)
          setApiSecret(result.apiSecret)
          setHaveApi(true)
        }
      } catch (error) {
        console.error('Error fetching integration:', error)
      }
    }
    checkIntegration()
  }, [])

  // Manejo del envío del formulario
  const handleSubmit = async e => {
    e.preventDefault()

    // Si ya existe la API, no hacemos nada.
    if (haveApi) return

    setIsLoading(true)
    try {
      // 1. Autorizar la integración
      const authresponse = await authorizeIntegration({
        apiKey: apiUser,
        apiSecret: apiSecret
      })

      if (authresponse.status === 200) {
        // 2. Crear integración (si no existía) o actualizar
        await createIntegration({
          name: 'Siigo',
          onPremise: false,
          apiKey: apiUser,
          apiSecret: apiSecret
        })

        // await updateIntegration({
        // 	name: "Siigo",
        // 	apiKey: apiUser,
        // 	apiSecret: apiSecret,
        // })

        dispatch(startProgress())

        await updateUserNew()
      }
    } catch (error) {
      console.error('Error during submission:', error)
      // alert(t('errors.genericError'))
    } finally {
      if (onClose) onClose()
      setIsLoading(false)
    }
  }

  const handleOpenEmailConfig = () => {
    if (onClose) onClose()
    setShowEmailConfig(true)
  }

  return (
    <>
      <Dialog
        open={show}
        onOpenChange={val => {
          if (!val && onClose) onClose()
        }}
      >
        <DialogContent>
          <div className='px-14 py-6'>
            <DialogHeader>
              <DialogTitle>{t('dialogTitle')}</DialogTitle>
            </DialogHeader>

            {/* Acordeón con pasos para obtener llaves */}
            <Accordion type='single' collapsible className='w-full mt-2'>
              <AccordionItem value='item-1'>
                <AccordionTrigger>{t('accordion.step1Title')}</AccordionTrigger>
                <AccordionContent>
                  {t('accordion.stepDesc')}{' '}
                  <a
                    className='text-primary-green-1400 underline'
                    href='https://www.siigo.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {'siigo.com'}
                  </a>{' '}
                  {t('accordion.step1Desc')}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value='item-2'>
                <AccordionTrigger>{t('accordion.step2Title')}</AccordionTrigger>
                <AccordionContent>{t('accordion.step2Desc')}</AccordionContent>
              </AccordionItem>
              <AccordionItem value='item-3'>
                <AccordionTrigger>{t('accordion.step3Title')}</AccordionTrigger>
                <AccordionContent>{t('accordion.step3Desc')}</AccordionContent>
              </AccordionItem>
              <AccordionItem value='item-4'>
                <AccordionTrigger>{t('accordion.step4Title')}</AccordionTrigger>
                <AccordionContent>
                  {t('accordion.step4Desc')}{' '}
                  <Button
                    variant='link'
                    className='text-primary-green-1400 underline'
                    onClick={handleOpenEmailConfig}
                  >
                    {t('accordion.email')}
                  </Button>{' '}
                  {t('accordion.step5Desc')}
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            {/* Descripción del diálogo con HoverCard */}
            <DialogDescription className='mt-4'>
              {t('dialogDescription.beforeHoverCard')}{' '}
              <HoverCard>
                <HoverCardTrigger asChild>
                  <a
                    className='text-primary-green-1400 underline'
                    href='https://youtu.be/bijTt6s5sFs'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('dialogDescription.videoButton')}
                  </a>
                </HoverCardTrigger>
                <HoverCardContent className='w-80'>
                  <div className='flex justify-between space-x-4'>
                    <div className='space-y-1'>
                      <h4 className='text-sm font-semibold'>
                        {t('dialogDescription.videoTitle')}
                      </h4>
                      <p className='text-sm'>
                        {t('dialogDescription.videoDesc')}
                      </p>
                      <div className='flex items-center pt-2'>
                        <CalendarIcon className='mr-2 h-4 w-4 opacity-70' />
                        <span className='text-xs text-muted-foreground'>
                          {t('dialogDescription.videoDate')}
                        </span>
                      </div>
                    </div>
                  </div>
                </HoverCardContent>
              </HoverCard>
            </DialogDescription>

            {/* Formulario principal */}
            <form onSubmit={handleSubmit} className='mt-6 space-y-4'>
              {/* Input: API User */}
              <div className='flex flex-col gap-2'>
                <Label htmlFor='apiUser'>{t('form.apiUser')}</Label>
                <Input
                  id='apiUser'
                  value={apiUser}
                  onChange={e => setApiUser(e.target.value)}
                  readOnly={haveApi} // bloqueado si ya existe
                />
              </div>

              {/* Input: API Secret */}
              <div className='flex flex-col gap-2'>
                <Label htmlFor='apiSecret'>{t('form.apiSecret')}</Label>
                <Input
                  id='apiSecret'
                  value={apiSecret}
                  onChange={e => setApiSecret(e.target.value)}
                  readOnly={haveApi} // bloqueado si ya existe
                />
              </div>

              {/* Botón para conectar*/}
              <div className='flex justify-center'>
                <Button
                  variant='primary'
                  type='submit'
                  disabled={isLoading || haveApi}
                >
                  {isLoading
                    ? 'Conectando...'
                    : haveApi
                    ? 'Ya tienes una API Key configurada'
                    : 'Conectar'}
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>

      <WindowConfigurationEmail
        show={showEmailConfig}
        onClose={() => setShowEmailConfig(false)}
      />
    </>
  )
}

export default WindowApiKey
