import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux" // Importa el hook para acceder al estado de Redux
import Header from "../../common/Header/Header"
import "react-toastify/dist/ReactToastify.css"
import "./Home.css"

import { useTranslation } from "react-i18next"
import LookerDashboard from "../../common/LookerDashboard/LookerDashboard"
import WindowWelcome from "../../new-front/WindowWelcome/WindowWelcome"
import WindowApiKey from "../../new-front/WindowApiKey/WindowApiKey"
import { setWelcome } from "@/redux/userSlice"

export default function Home() {
	const { t } = useTranslation(["home"])

	// Estados para controlar las ventanas
	const [showWindowWelcome, setShowWindowWelcome] = useState(false)
	const [showWindowApiKey, setShowWindowApiKey] = useState(false)

	const user = useSelector((state) => state.user)
	const dispatch = useDispatch()

	useEffect(() => {
		if (user.newUser && !user.welcome) {
			setShowWindowWelcome(true)
			dispatch(setWelcome())
		}
	}, [user, dispatch])

	const handleOpenApiKey = () => {
		setShowWindowWelcome(false)
		setShowWindowApiKey(true)
	}

	// Callback para cerrar WindowApiKey
	const handleCloseApiKey = () => {
		setShowWindowApiKey(false)
	}

	return (
		<>
			<Header title={t("title")} sections={[{ title: t("home"), href: "/" }]} />
			<LookerDashboard />

			{/* Renderiza WindowWelcome si showWindowWelcome es true */}
			{showWindowWelcome && (
				<WindowWelcome
					// Paso la función como prop
					onOpenApiKey={handleOpenApiKey}
				/>
			)}

			{/* Renderiza WindowApiKey si showWindowApiKey es true */}
			{showWindowApiKey && <WindowApiKey onCloseApiKey={handleCloseApiKey} />}
		</>
	)
}
