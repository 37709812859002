import { useEffect, useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import moment from "moment"
import PropTypes from "prop-types"

// Import components
import { FilesProgress } from "../../common/popUps/filesprogress/filesprogress"
import { ErpUpload } from "../../common/popUps/filesprogress/erpUpload"
import Button from "../../common/Buttons/Button"
import { ToastContainer } from "react-toastify"
import { toggleToast } from "../modules/modules"

// Import helper
import { Each } from "../../../utils/Each"
import { Pagination } from "../../common/pagination/pagination"

// Import Services
import { getWoReport } from "../../../services/report"
import { deleteFile } from "@/services/file"

// Import Icons
import { FiDownload } from "react-icons/fi"

import { getTransactionEntitiesOnWorkSpaceId } from "../../../services/transactionEntity"

import { getAllNotUploadedInvoicesERP, uploadInvoiceToERP } from "../../../services/invoice"
import { getIntegrationbyUID } from "../../../services/integrations"

// Import styles
import "./invoiceModule.css"
import { ToolOptionsPopUp } from "../../common/popUps/toolOptionsInvoicePopUp/toolOptionsInvoicePopUp"
import { PayPopUp } from "../../common/popUps/payPopUp/payPopUp"

export default function InvoiceModule({ id, name }) {
	const location = useLocation()
	const [workflows, setWorkflows] = useState([])
	const [erpInvoices, setErpInvoices] = useState([])
	const [showProgress, setShowProgress] = useState(false)
	const [showERPProgress, setShowERPProgress] = useState(false)
	const [showPayPopUp, setShowPayPopUp] = useState({
		show: false,
		data: null,
	})
	const [fileStatuses, setFileStatuses] = useState([])
	const [sortedWorkflows, setSortedWorkflows] = useState([])
	const [activePopupId, setActivePopupId] = useState(null)
	const [filter, setFilter] = useState({ contado: false, credito: false })

	const firstLoad = useRef(true)
	const navigation = useNavigate()
	console.log(erpInvoices)
	const [currentPage, setCurrentPage] = useState(1)
	const itemsPerPage = 7

	const getWorkFlows = async () => {
		try {
			const response = await getTransactionEntitiesOnWorkSpaceId(id)
			setWorkflows(response.data)
		} catch (error) {
			console.error("Error getting workflows:", error)
		}
	}

	const validateCreds = async () => {
		try {
			await getIntegrationbyUID()
			return true
		} catch (error) {
			console.error("Error validating credentials:", error)
			return false
		}
	}

	const getErpInvoices = async () => {
		try {
			if (await validateCreds()) {
				const response = await getAllNotUploadedInvoicesERP(id)
				setErpInvoices(response.data)
				setShowERPProgress(true)
			} else {
				toggleToast("Por favor, configure las credenciales de Siigo")
			}
		} catch (error) {
			console.error("Error getting ERP invoices:", error)
		}
	}

	const sortWorkflowsByDate = (workflows) => {
		return workflows.sort((a, b) => new Date(b.created) - new Date(a.created))
	}

	const getPopUpOptions = (item) => {
		const options = [
			{
				text: "Eliminar",
				onClick: () => deleteInvoices(item._id),
			},
			{
				text: "Subir",
				onClick: () => uploadInvoiceToERP(item._id),
			},
		]
		if (item.longTermPayment) {
			options.push({
				text: "Abonar",
				onClick: () => {
					setShowPayPopUp({
						show: true,
						data: item,
					})
				},
			})
		}

		return options
	}

	const deleteInvoices = async (id) => {
		try {
			console.log(id)
			await deleteFile(id)
			toggleToast("Eliminación exitosa")
			getWorkFlows()
		} catch (error) {
			console.error("Error deleting invoice:", error)
		}
	}

	const getFilteredWorkflows = () => {
		return sortedWorkflows.filter((workflow) => {
			return (
				(!filter.contado && !filter.credito) ||
				(filter.contado && !workflow.longTermPayment) ||
				(filter.credito && workflow.longTermPayment)
			)
		})
	}

	useEffect(() => {
		getWorkFlows()
	}, [location.pathname])

	useEffect(() => {
		if (firstLoad.current) {
			getWorkFlows()
			firstLoad.current = false
			return
		}
		if (workflows.length > 0) {
			setSortedWorkflows(sortWorkflowsByDate([...workflows]))
		}
		const handler = (e) => {
			if (!e.target.closest(".popup")) {
				setActivePopupId(null)
			}
		}
		document.addEventListener("mousedown", handler)
		return () => {
			document.removeEventListener("mousedown", handler)
		}
	}, [workflows, currentPage])

	const togglePopup = (id) => {
		setActivePopupId((prevId) => (prevId === id ? null : id))
	}

	const currentData = getFilteredWorkflows().slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage,
	)

	const handleFilterChange = (e) => {
		const { name, checked } = e.target
		setFilter((prevFilter) => ({
			...prevFilter,
			[name]: checked,
		}))
	}

	return (
		<>
			<section className="workflows py-0">
				<div className="flex justify-between relative mb-8 border-b border-black pb-4">
					<h1 className="text-primary-grey-1800">{name.charAt(0).toUpperCase() + name.slice(1)}</h1>
					<div className="button-options">
						<button
							className="add-workflow-btn bg-[--primary] text-white"
							onClick={async () => getWoReport()}
						>
							<FiDownload />
						</button>
						<button
							className="add-workflow-btn bg-[--primary] text-white"
							onClick={() => getErpInvoices()}
						>
							Subir a ERP
						</button>
					</div>
				</div>
				<div className="items">
					<FilesProgress
						show={showProgress}
						onClose={() => {
							setShowProgress(false)
							getWorkFlows()
						}}
						data={fileStatuses}
						setData={setFileStatuses}
					/>
					<ErpUpload
						show={showERPProgress}
						onClose={() => setShowERPProgress(false)}
						data={erpInvoices}
						setData={setErpInvoices}
					/>
					<PayPopUp
						show={showPayPopUp.show}
						onClose={() =>
							setShowPayPopUp({
								show: false,
								data: null,
							})
						}
						data={showPayPopUp.data}
					/>
					{workflows.length === 0 ? (
						<p>No se encontraron facturas</p>
					) : (
						<>
							<div className="filter">
								<label className="filter-option">
									<input
										type="checkbox"
										name="contado"
										checked={filter.contado}
										onChange={handleFilterChange}
									/>
									Contado
								</label>
								<label className="filter-option">
									<input
										type="checkbox"
										name="credito"
										checked={filter.credito}
										onChange={handleFilterChange}
									/>
									Crédito
								</label>
							</div>
							<div className="item-subheader">
								<div className="item">
									<p>Nombre</p>
								</div>
								<div className="item">
									<p>Creado</p>
								</div>
								<div className="item">
									<p>Tipo de pago</p>
								</div>
								<div className="item">
									<p>Ver</p>
								</div>
							</div>
							<Each
								data={currentData}
								render={(item) => (
									<div
										className={`invoices-item ${item.consolidated ? "consolidated" : ""}`}
										key={item.id}
									>
										<div className="item">
											<p>{item.name}</p>
										</div>
										<div className="item">
											<p>{moment(item.created).format("DD/MM/YYYY HH:mm:ss")}</p>
										</div>
										<div className="item">
											<p>{item.longTermPayment ? "Crédito" : "Contado"}</p>
										</div>
										<div className="each-file-options">
											<div className="item">
												<div>
													<Button text="..." onClick={() => togglePopup(item._id)} />

													<ToolOptionsPopUp
														options={getPopUpOptions(item)}
														active={activePopupId === item._id}
														onClose={() => setActivePopupId(null)}
													/>
												</div>
												<Button text=">" onClick={() => navigation(`/get-report/${item._id}`)} />
											</div>
										</div>
									</div>
								)}
							/>
							<Pagination
								totalItems={sortedWorkflows.length}
								itemsPerPage={itemsPerPage}
								currentPage={currentPage}
								onPageChange={(page) => {
									setCurrentPage(page)
									getWorkFlows()
								}}
							/>
						</>
					)}
				</div>
			</section>
			<ToastContainer />
		</>
	)
}

InvoiceModule.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
}
