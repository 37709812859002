import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

// Import Loader
import { PulseLoader } from "react-spinners"

// Import SVG
import { FaBan } from "react-icons/fa"
import { FiSend } from "react-icons/fi"

// Import components
import Button from "../../Buttons/Button"
import { ConfirmationOptionPopUp } from "../confirmOptionPopUp.jsx/confirmOptionPopUp"
import SendTable from "../../Tables/sendTable"

// Import Utils
import { Each } from "../../../../utils/Each"

// Import Services
import { getProviderInfoInInvoice, uploadInvoiceToERP } from "../../../../services/invoice"
import { updateTransactionEntityConsolidatedStatusById } from "../../../../services/transactionEntity"
import { getIntegrationConfig } from "../../../../utils/config"

// Import PropTypes
import propTypes from "prop-types"

export const ErpUpload = ({ show, onClose, data, setData }) => {
	const popupContentRef = useRef(null)
	const [customerConfirmation, setCustomerConfirmation] = useState(false)
	const [config, setConfig] = useState({})
	const [provider, setProvider] = useState({})
	const [loading, setLoading] = useState(false)
	const [puc_code, setPuc_code] = useState()
	const navigate = useNavigate()
	const handleBackdropClick = (e) => {
		if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
			setData([])
			onClose()
		}
	}

	const handleRemoveFile = (index) => {
		const newData = [...data]
		newData.splice(index, 1)
		setData(newData)
	}

	const handleLogFinalItems = async () => {
		setLoading(true)
		const newData = [...data]
		const uploadPromises = newData.map(async (file, index) => {
			try {
				newData[index].status = "loading"
				setData([...newData])
				const response = await uploadInvoiceToERP(file._id, file.defaultPUC)
				await updateTransactionEntityConsolidatedStatusById(response.data.entityId)
				newData[index].status = response.status
				newData[index].message = response.data.response.body?.name || response.data.response?.name
			} catch (error) {
				console.error("Error uploading invoice to ERP:", error)
				newData[index].status = error.response?.status || "error"
				const errorcode = error.response?.data?.error?.body?.code || error.response?.data?.error?.data?.code || "unhandled_error"
				newData[index].message = await handleErrorMessage(errorcode, file)
			}
			setData([...newData])
		})

		await Promise.all(uploadPromises)
		setData([...newData])
	}

	const handleErrorMessage = async (error, file) => {
		try {
			switch (error) {
				case "unhandled_error":
					return "Error de ERP no controlado, contacte al administrador."
				case "invalid_document":
					return "El id del tipo de comprobante no corresponde al que estás creando"
				case "documents_service":
					return "El servicio de documentos no está disponible o algún campo de la factura es erróneo, intente en unos minutos."
				case "duplicated_document":
					return "El Comprobante ya existe en el ERP."
				case "invalid_code":
					return "El código del documento no es válido. El código no puede tener comillas simples (') ni espacios."
				case "requests_limit":
					return "Se ha superado el límite de solicitudes, intente en unos minutos."
				case "invalid_reference": {
					if (error.response.data.error.body.params[0]==="document.id"){
						return `Hay un error con el id del documento/modulo, revise que exista en su ERP "${error.response.data.error.body.message}"`
					}else{
						const response = await getProviderInfoInInvoice(file.id)
						setProvider(response[0])
						setCustomerConfirmation(true)
						return `El proveedor ${response[0].vendor.name} no existe, revise el NIT.`
					}
				}
				case "length_max":
					return "La longitud del campo descripción excede el máximo permitido."
				default:
					return (
						error.response?.data?.error[0]?.Message ||
						"Ocurrió un error, intentelo más tarde."
					)
			}
		} catch (error) {
			return "Ocurrió un error, valide los datos de subida."
		}
	}

	useEffect(() => {
		const getConfig = async () => {
			const response = await getIntegrationConfig()
			setConfig(response)
		}
		getConfig()
	}, [])

	if (!show) {
		return null
	}
	
	console.log(data)

	let content
	if (config) {
		content = <h3>No tiene ningún ERP configurado</h3>
	} else if (!loading) {
		content = (
			
			<SendTable
				data={data}
				puc_code={puc_code}
				setPuc_code={setPuc_code}
				setData={setData}
			/>
		)
	} else {
		content = (
			<Each
				data={data}
				render={(file, index) => (
					<div key={index} className="files-loader">
						<h3 className="file-name">
							{file.providerName} - {file.invoiceId}
						</h3>
						{file.status === "loading" && (
							<PulseLoader size={10} color={"var(--primary)"} loading={true} />
						)}
						{file.status === 500 && <FaBan color="red" size={25} />}
						{file.status !== "loading" && <p>{file.message}</p>}
						<Button text="X" onClick={() => handleRemoveFile(index)} />
					</div>
				)}
			/>
		)
	}

	return (
		<div className="popup-backdrop z-50" onClick={handleBackdropClick}>
			<div className="popup-content-no-click" ref={popupContentRef}>
				<ConfirmationOptionPopUp
					show={customerConfirmation}
					onClose={() => setCustomerConfirmation(false)}
					confirmation={() => {
						handleConfirmation()
					}}
					message={
						"El NIT del proveedor ingresado en la factura no existe, ¿Desea crearlo?"
					}
				/>
				{content}
				<span className="ml-2 text-sm text-gray-500 text-left ">El ultimo valor de cada factura fue estimado por IA</span>
				{!config && (
					<div className="mt-5">
						
						<Button className="pt-6" text={<FiSend />} onClick={handleLogFinalItems} />
					</div>
				)}

			</div>
		</div>
	)
}

ErpUpload.propTypes = {
	show: propTypes.bool.isRequired,
	onClose: propTypes.func.isRequired,
	data: propTypes.array.isRequired,
	setData: propTypes.func.isRequired,
}
