import Header from "@/components/common/Header/Header"
import { useTranslation } from "react-i18next"
import { DataTableDebts } from "../DataTables/DataTableDebts"
import { getDebtSummary, getInvoiceUploadedByVendorId } from "@/services/invoice"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchInvoicesSuccess } from "@/redux/invoice.slice"

export default function Debts() {
	const { t } = useTranslation(["debts"])
	const [data, setData] = useState([])
	const { fetchInvoices } = useSelector((state) => state.invoice)
	const dispatch = useDispatch()
	const [firstLoad, setFirstLoad] = useState(true)

	useEffect(() => {
		if (!firstLoad && !fetchInvoices) return

		const fetchDebtSummary = async () => {
			try {
				const response = await getDebtSummary()
				console.log(response)
				const transformedData = await Promise.all(response.map(async (item) => {
					const invoiceResponse = await getInvoiceUploadedByVendorId(item.vendorId)
					const hasInvoices = invoiceResponse.length > 0
					return {
						mongoId: item._id,
						id: item.vendorId,
						company: item.vendorName,
						deposit: item.deposit,
						debt: item.debt,
						total: item.totalInvoiceAmount,
						hasInvoices,
					}
				}))
				setData(transformedData)
			} catch (error) {
				console.error(t("messages.errorFetchingDebtSummary"), error)
			}
		}

		fetchDebtSummary()
		dispatch(fetchInvoicesSuccess())
		setFirstLoad(false)
	}, [t, dispatch, fetchInvoices, firstLoad])

	return (
		<>
			<Header title={t("title")} sections={[{ href: "/causation", title: t("causation") }]} />
			<main className="px-6">
				<DataTableDebts data={data} />
			</main>
		</>
	)
}
