import { useCallback, useEffect, useState } from "react"
import { LookerEmbedSDK } from "@looker/embed-sdk"
import styled from "@emotion/styled"
import RefreshIcon from "@mui/icons-material/Refresh"
import { Box, Divider, IconButton, Tooltip } from "@mui/material"
import Cookies from "js-cookie"
import LogoLoader from "@/components/new-front/LogoLoader/LogoLoader"
import { useSelector } from "react-redux"

function LookerDashboard() {
	const [refresh, setRefresh] = useState(0)
	const [loading, setLoading] = useState(true)
	const user = useSelector((state) => state.user)

	useEffect(() => {
		setLoading(true)
	}, [refresh])

	const makeDashboard = useCallback(
		(el) => {
			if (!el) {
				return
			}
			el.innerHTML = ""
			LookerEmbedSDK.init(
				"bucks.cloud.looker", // <your-looker-instance> without https and .com
				{
					url: `${import.meta.env.VITE_BASE_URL}/api/looker/auth`,
					headers: [
						{
							name: "Authorization",
							value: `Bearer ${Cookies.get("token")}`,
						},
					],
				},
			)
			LookerEmbedSDK.createDashboardWithId(user.id)
				.appendTo(el)
				.on("dashboard:loaded", () => {
					// see all listeners here: https://looker-open-source.github.io/embed-sdk/interfaces/LookerEmbedEventMap.html
					console.log("LookerEmbedSDK.createDashboardWithId()::Successfully Loaded!")
					setLoading(false)
				})
				.on("dashboard:run:start", () => {
					setLoading(false)
				})
				.build()
				.connect()
				.then(() => {
					console.log("Done")
				})
				.catch((error) => {
					console.error("An unexpected error occurred on looker dashboard", error)
				})
		},
		[refresh],
	)
	return (
		<Box>
			<Box
				sx={{
					p: 1,
					boxShadow: 2,
					borderRadius: 2,
					backgroundColor: "white",
				}}
			>
				<Box display="flex" justifyContent="space-between" alignContent="center" sx={{ mx: 1 }}>
					<h1>Looker Dashboard</h1>
					<Tooltip title={"Refresh dashboard after session time out"}>
						<IconButton
							size="large"
							sx={{ color: "black" }}
							onClick={() => {
								setRefresh((prev) => prev + 1)
							}}
						>
							<RefreshIcon />
						</IconButton>
					</Tooltip>
				</Box>
				<Divider />
				<div className="h-[90vh] w-full relative">
					{loading && (
						<div className="absolute bg-white/20 backdrop-blur-sm h-full w-full">
							<LogoLoader className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-20 w-20" />
						</div>
					)}
					<Container ref={makeDashboard}></Container>
				</div>
			</Box>
		</Box>
	)
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	& > iframe {
		width: 100%;
		height: 100%;
	}
`

export default LookerDashboard
