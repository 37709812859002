import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/provider"

export async function getAllProviders({ limit, page } = { limit: 10, page: 1 }) {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/all`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
			params: {
				limit,
				page,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error reading providers:", error)
		throw error
	}
}

export async function searchProviders(
	{ limit = 10, page = 1, search = "" } = { limit: 10, page: 1, search: "" }
) {
	if (!search) {
		return await getAllProviders({ limit })
	}
	try {
		const response = await axios.get(`${VITE_BASE_URL}/search`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
			params: {
				limit,
				page,
				filter: search,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error searching providers:", error)
		throw error
	}
}

export async function getProviderById(id) {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting provider by Id:", error)
		throw error
	}
}

export async function getProviderByDocument(id) {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/doc/${id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error getting provider by Document:", error)
		throw error
	}
}

export async function createProvider(provider) {
	try {
		const response = await axios.post(`${VITE_BASE_URL}`, provider, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error creating provider:", error)
		throw error
	}
}

export async function putProvider(provider) {
	try {
		const response = await axios.put(`${VITE_BASE_URL}/${provider._id}`, provider, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error editing provider:", error)
		throw error
	}
}

export async function deleteProvider(provider) {
	try {
		const response = await axios.delete(`${VITE_BASE_URL}/${provider._id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error removing provider:", error)
		throw error
	}
}

export function providerToProveedor(provider) {
	let tipo
	if (provider.type === "customer") {
		tipo = "Cliente"
	} else if (provider.type === "supplier") {
		tipo = "Proveedor"
	} else {
		tipo = "Otro"
	}
	return {
		id: provider._id,
		tipoDoc: provider.idType === "13" ? "CC" : "NIT",
		numDoc: provider.id,
		tipo,
		nombre: provider.name,
		tipoPersona: provider.personType === "Company" ? "Empresa" : "Persona",
		contacts: provider.contacts,
		activity: provider.activity,
		concepto: provider.concept,
		PUC: provider.PUC,
		defaultPUC: provider.defaultPUC,
		addresses: provider.addresses,
	}
}

export function proveedorToProvider(proveedor) {
	let type
	if (proveedor.tipo === "Cliente") {
		type = "customer"
	} else if (proveedor.tipo === "Proveedor") {
		type = "supplier"
	} else {
		type = "other"
	}
	return {
		_id: proveedor.id,
		id: proveedor.numDoc,
		idType: proveedor.tipoDoc === "CC" ? "13" : "31",
		type,
		name: proveedor.nombre,
		personType: proveedor.tipoPersona === "Empresa" ? "Company" : "Person",
		contacts: proveedor.contacts,
		activity: proveedor.actividad,
		concept: proveedor.concepto,
		PUC: proveedor.PUC,
		defaultPUC: proveedor.defaultPUC,
	}
}
