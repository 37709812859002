import { useTranslation } from "react-i18next"
import Header from "../../common/Header/Header"
import Button from "../../common/Buttons/Button"
import FormSelectInput from "../../common/inputs/FormSelectInput"
import FormTextInput from "../../common/inputs/FormTextInput"
import { ToastContainer } from "react-toastify"
import { TbSearch } from "react-icons/tb"
import { useEffect, useState } from "react"
import {
	createEmployee,
	deleteEmployee,
	getEmployeeById,
	getEmployees,
	updateEmployee,
} from "../../../services/employees"

export default function Employees() {
	const { t } = useTranslation(["employees"])

	const [employees, setEmployees] = useState([])

	const defaultEmployee = {
		employeeId: "",
		name: {
			first: "",
			last: "",
		},
		position: "",
		contracType: "",
		salary: {
			base: 0,
			extraHourRate: 0,
			comissionPercentage: 0,
		},
		status: "active",
	}

	useEffect(() => {
		getEmployees().then(async (data) => {
			const tmp = await Promise.all(
				data.map(async (employee) => await getEmployeeById(employee.employeeId)),
			)
			setEmployees(tmp)
		})
	}, [])

	const [selectedEmployee, setSelectedEmployee] = useState(defaultEmployee)

	const handleCreate = async () => {
		await createEmployee(selectedEmployee)
		setEmployees([...employees, selectedEmployee])
		setSelectedEmployee(defaultEmployee)
	}

	const handleUpdate = async () => {
		await updateEmployee(selectedEmployee)
		setEmployees(
			employees.map((employee) =>
				employee.employeeId === selectedEmployee.employeeId ? selectedEmployee : employee,
			),
		)
		setSelectedEmployee(defaultEmployee)
	}

	const handleDelete = async () => {
		await deleteEmployee(selectedEmployee.employeeId)
		setEmployees(
			employees.filter((employee) => employee.employeeId !== selectedEmployee.employeeId),
		)
		setSelectedEmployee(defaultEmployee)
	}

	return (
		<>
			<Header title={t("title")} section={{ title: "Management", href: "/" }} />
			<main className="px-12 w-full pt-[3px]">
				<section>
					<div className="flex gap-4">
						{/* Formulario de Información del Proveedor */}
						<div className="m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all outline-none w-1/2 2xl:w-1/3 flex flex-col gap-6 h-min">
							<h2>{t("labels.info")}</h2>
							<div className="flex flex-col xl:grid xl:grid-cols-2 gap-x-8 gap-y-6">
								<div className="flex flex-col col-span-2">
									<FormTextInput
										id="id"
										label="id"
										value={selectedEmployee.employeeId}
										onChange={(e) => {
											setSelectedEmployee({
												...selectedEmployee,
												employeeId: e.target.value,
											})
										}}
									/>
								</div>
								<div className="flex flex-col">
									<FormTextInput
										id="name"
										label={t("labels.name")}
										value={selectedEmployee.name.first}
										onChange={(e) => {
											setSelectedEmployee({
												...selectedEmployee,
												name: { ...selectedEmployee.name, first: e.target.value },
											})
										}}
									/>
								</div>

								<div className="flex flex-col">
									<FormTextInput
										id="lastname"
										label={t("labels.lastname")}
										value={selectedEmployee.name.last}
										onChange={(e) =>
											setSelectedEmployee({
												...selectedEmployee,
												name: { ...selectedEmployee.name, last: e.target.value },
											})
										}
									/>
								</div>

								<div className="flex flex-col">
									<FormTextInput
										id="position"
										label={t("labels.position")}
										value={selectedEmployee.position}
										onChange={(e) =>
											setSelectedEmployee({
												...selectedEmployee,
												position: e.target.value,
											})
										}
									/>
								</div>

								{/* Tipo Persona */}
								<div className="flex flex-col">
									<FormSelectInput
										id="contract"
										label={t("labels.contract")}
										value={selectedEmployee.contracType}
										className=""
										options={["Full-time", "Part-time", "Contractor"]}
										onChange={(e) =>
											setSelectedEmployee({
												...selectedEmployee,
												contracType: e.target.value,
											})
										}
									/>
								</div>

								<div className="flex flex-col">
									<FormTextInput
										id="salary"
										label={t("labels.salary")}
										value={selectedEmployee.salary.base}
										number
										onChange={(e) =>
											setSelectedEmployee({
												...selectedEmployee,
												salary: { ...selectedEmployee.salary, base: e.target.value },
											})
										}
									/>
								</div>

								<div className="flex flex-col">
									<FormTextInput
										id="extraHourRate"
										label="Extra hour rate"
										value={selectedEmployee.salary.extraHourRate}
										number
										onChange={(e) =>
											setSelectedEmployee({
												...selectedEmployee,
												salary: { ...selectedEmployee.salary, extraHourRate: e.target.value },
											})
										}
									/>
								</div>

								<div className="flex flex-col">
									<FormTextInput
										id="comissionPercentage"
										label="Comission %"
										value={selectedEmployee.salary.comissionPercentage}
										number
										onChange={(e) =>
											setSelectedEmployee({
												...selectedEmployee,
												salary: { ...selectedEmployee.salary, comissionPercentage: e.target.value },
											})
										}
									/>
								</div>

								<div className="col-span-2 flex justify-center gap-4">
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-green-600"
										onClick={handleCreate}
									>
										{t("buttons.create")}
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-yellow-400"
										onClick={handleUpdate}
									>
										{t("buttons.save")}
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-red-500"
										onClick={handleDelete}
									>
										{t("buttons.delete")}
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-gray-500"
										onClick={() => setSelectedEmployee(defaultEmployee)}
									>
										{t("buttons.clear")}
									</button>
								</div>
							</div>
						</div>

						{/* Lista de Empleados */}
						<div className="relative w-1/2 2xl:w-2/3 m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all outline-none max-h-full">
							<div className="flex flex-col w-full gap-2">
								<h2>{t("labels.list")}</h2>
								<div className="relative">
									<TbSearch className="absolute top-1/2 -translate-y-1/2 left-1" />
									<input
										type="text"
										className="pl-5 border border-gray-600 rounded-md h-full w-full"
										placeholder={t("search")}
									/>
								</div>
								<ul className="grid xl:grid-cols-2 2xl:grid-cols-3 gap-2 overflow-y-scroll mb-10">
									{employees.length === 0 && (
										<li className="text-center col-span-3">No employees found</li>
									)}
									{employees.map((employee) => (
										<li key={employee.employeeId}>
											<button
												className="flex flex-col 2xl:flex-row gap-2 p-2 rounded-md border-2 border-gray-200 shadow-sm items-center justify-between text-start w-full"
												onClick={() => {
													employee._id = undefined
													setSelectedEmployee(employee)
												}}
											>
												<div className="flex flex-col w-3/4">
													<span className="text-lg text-ellipsis line-clamp-1 text-center 2xl:text-left w-full">
														{employee.name.first} {employee.name.last}
													</span>
													<div className="flex gap-1 text-sm text-gray-500 self-center 2xl:self-start w-full">
														<span className="text-ellipsis line-clamp-1 max-w-1/2">
															{employee.position}
														</span>
														<span className="text-ellipsis line-clamp-1 w-1/2">
															{employee.contracType}
														</span>
													</div>
												</div>
												<div className="flex 2xl:flex-col 2xl:gap-2 items-end justify-around 2xl:justify-normal w-full 2xl:w-min">
													<span className="rounded-full bg-green-100 px-2 text-xs w-fit">
														{employee.employeeId}
													</span>
													<span className="rounded-full bg-green-100 px-2 text-xs w-fit">
														{employee.salary.base}
													</span>
												</div>
											</button>
										</li>
									))}
								</ul>
								<div className="flex justify-self-end self-center gap-4 pt-2 absolute bottom-3">
									<Button text={t("buttons.before")} onClick={() => {}} />
									<button className="bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer">
										{1}
									</button>
									<Button text={t("buttons.next")} onClick={() => {}} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<ToastContainer />
		</>
	)
}
