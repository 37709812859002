import React, { useMemo, useEffect, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Doughnut } from 'react-chartjs-2';
import Header from '../../common/Header/Header';
import dataJson from './pruebaData.json';

import { Chart as ChartJS, CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend);

export default function Contributions() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [period, setPeriod] = useState('sinFiltro');

    useEffect(() => {
        setData(dataJson);
        setFilteredData(dataJson);
    }, []);

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(year, month - 1, day);
    };

    const filterDataByPeriod = () => {
        const now = new Date();
        let filtered = data;

        if (period === 'quincenal') {
            const startOfPeriod = new Date(now);
            startOfPeriod.setDate(now.getDate() - 15);

            filtered = data.filter((row) => {
                const date = parseDate(row.date);
                return date >= startOfPeriod && date <= now;
            });
        } else if (period === 'mensual') {
            const startOfPeriod = new Date(now);
            startOfPeriod.setMonth(now.getMonth() - 1);

            filtered = data.filter((row) => {
                const date = parseDate(row.date);
                return date >= startOfPeriod && date <= now;
            });
        } else {
            filtered = data;
        }

        setFilteredData(filtered);
    };

    useEffect(() => {
        filterDataByPeriod();
    }, [period, data]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Nombre del Trabajador',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
                
            },
            {
                accessorKey: 'healthContribution',
                header: 'Salud',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } },
                size: 50  // Reduciendo el ancho de la columna
            },
            {
                accessorKey: 'pensionContribution',
                header: 'Pensión',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } },
                size: 50  // Reduciendo el ancho de la columna
            },
            {
                accessorKey: 'riskContribution',
                header: 'Riesgos Laborales',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } },
                size: 70
            },
            {
                accessorKey: 'totalContribution',
                header: 'Total de Aportes',
                Cell: ({ row }) => (
                    row.original.healthContribution + row.original.pensionContribution + row.original.riskContribution
                ),
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
            },
            {
                accessorKey: 'date',
                header: 'Fecha',
                muiTableHeadCellProps: { style: { color: 'var(--primary)' } }
                
            }
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: filteredData,
        enableRowSelection: true,
        enableColumnOrdering: false,
        enableGlobalFilter: true,
        initialState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0
            }
        },
        enablePagination: true,
        enableFullScreenToggle: true
    });

    const selectedRows = table.getSelectedRowModel().flatRows;
    const displayedData = selectedRows.length > 0
        ? selectedRows.map((row) => row.original)
        : filteredData;

    const totalHealth = displayedData.reduce((sum, row) => sum + row.healthContribution, 0);
    const totalPension = displayedData.reduce((sum, row) => sum + row.pensionContribution, 0);
    const totalRisk = displayedData.reduce((sum, row) => sum + row.riskContribution, 0);
    const totalContributions = totalHealth + totalPension + totalRisk;

    const healthPercentage = ((totalHealth / totalContributions) * 100).toFixed(2);
    const pensionPercentage = ((totalPension / totalContributions) * 100).toFixed(2);
    const riskPercentage = ((totalRisk / totalContributions) * 100).toFixed(2);

    const chartData = {
        labels: ['Aporte a Salud', 'Aporte a Pensión', 'Aporte a Riesgos Laborales'],
        datasets: [
            {
                label: 'Total de Aportes',
                data: [totalHealth, totalPension, totalRisk],
                backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
                borderWidth: 1
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Proporción de Aportes por Tipo'
            }
        }
    };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    return (
        <>
            <Header title={"Aportes"} />
            <main className="w-full pt-[3px]">
                <div className="flex flex-wrap justify-center items-center font-bold p-4 gap-2">
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem",margin: "0 0.5rem" }}
                    >
                        Total de Aportes: ${totalContributions}
                    </p>
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Porcentaje Salud: {healthPercentage}%
                    </p>
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem"}}
                    >
                        Porcentaje Pensión: {pensionPercentage}%
                    </p>
                    <p
                        className="bg-secondary text-center p-3 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
                        style={{ fontSize: "0.9rem", margin: "0 0.5rem" }}
                    >
                        Porcentaje Riesgos Laborales: {riskPercentage}%
                    </p>
                    <select
                        className="appearance-none border-none outline-none p-3 pr-8 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)] text-center relative "
                        value={period}
                        onChange={handlePeriodChange}
                        style={{
                            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='black'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a 1 1 0 010-1.414z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
                            backgroundPosition: 'right 0.5rem center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '1rem',
                            fontSize: "0.9rem", margin: "0 0.5rem"
                        }}
                    >
                        <option value="sinFiltro">Sin Filtro</option>
                        <option value="quincenal">Quincenal</option>
                        <option value="mensual">Mensual</option>
                    </select>
                </div>

                {/* Table for contributions breakdown */}
                <MaterialReactTable table={table} />

                {/* Doughnut Chart */}
                <div className="mt-8 flex justify-center">
                    <div className="w-full max-w-[400px] h-[300px]">
                        <Doughnut data={chartData} options={chartOptions} />
                    </div>
                </div>
            </main>
        </>
    );
}
