// src/components/actualInventory/ActualInventory.jsx
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import propTypes from "prop-types";

// Import components
import Button from "../../common/Buttons/Button";
import { PayPopUp } from "../../common/popUps/payPopUp/payPopUp";
import { FaMoneyCheck } from "react-icons/fa";
import { GoHistory } from "react-icons/go";
import { TableComponent } from "../../common/tableComponent/tableComponent";
import { PaymentHistoryPopUp } from "../../common/popUps/paymentsHistoryPopUp/paymentHistoryPopUp";
import { ToastContainer } from "react-toastify";

// Import Services
import { getInvoiceUploadedByVendorId } from "../../../services/invoice";
import { getTransactionEntityById } from "../../../services/transactionEntity";

// Import Utils
import { formatPrice } from "../../../utils/helpers";

// Import Styles
import "./actualInventory.css";
import Header from "../../common/Header/Header";

// Import useTranslation
import { useTranslation } from 'react-i18next';

// Componente ActionButtons
export const ActionButtons = ({ item, reloadData, successDelay = 5000 }) => { // Se agrega reloadData como prop
    const { t } = useTranslation(['actualInventory']);
    const [showAbonarPopUp, setShowAbonarPopUp] = useState(false);
    const [showHistoryPopUp, setShowHistoryPopUp] = useState(false);

    // Función llamada después de un abono exitoso
    const handlePaymentSuccess = (updatedInvoice) => {
        setShowAbonarPopUp(false);
        // Inicia un temporizador para recargar datos después de `successDelay` milisegundos
        setTimeout(() => {
            reloadData();
        }, successDelay);
    };
    return (
        <div className="flex gap-1">
            <PayPopUp
                show={showAbonarPopUp}
                data={item}
                onClose={() => setShowAbonarPopUp(false)}
                onPaymentSuccess={handlePaymentSuccess} 
            />

            <PaymentHistoryPopUp
                show={showHistoryPopUp}
                data={item}
                onClose={() => setShowHistoryPopUp(false)}
            />

            <div className="tooltip">
                <Button
                    svg={<FaMoneyCheck />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowAbonarPopUp(true);
                    }}
                />
                <span className="tooltiptext">{t('actualInventory.labels.abonar')}</span>
            </div>
            <div className="tooltip">
                <Button
                    svg={<GoHistory />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowHistoryPopUp(true);
                    }}
                />
                <span className="tooltiptext">{t('actualInventory.labels.historial')}</span>
            </div>
            <ToastContainer />
        </div>
    );
};

ActionButtons.propTypes = {
    item: propTypes.object.isRequired,
    reloadData: propTypes.func.isRequired,  
    successDelay: propTypes.number, 
};

const ActualInventory = () => {
    const { t } = useTranslation(['actualInventory']);
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const { id } = useParams();
    const firstLoad = useRef(true);

    const navigation = useNavigate();

    const [filter, setFilter] = useState({
        contado: false,
        credito: false,
        vencidas: false,
        porVencer: false,
        pagadas: false,
        from: "",
        to: "",
    });

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilter((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const isAnyFilterSelected = () => {
        const { contado, credito, vencidas, porVencer, pagadas, from, to } = filter;
        return contado || credito || vencidas || porVencer || pagadas || from !== "" || to !== "";
    };

    const getFilteredData = (dataToFilter) => {
        if (!isAnyFilterSelected()) {
            return dataToFilter;
        }
        console.log(filter);
        return dataToFilter.filter((item) => {
            const itemDate = moment(item.date);
            if (filter.contado && item.longTermPayment) return false;
            if (filter.credito && !item.longTermPayment) return false;
            if (filter.vencidas && itemDate.isAfter(moment(), "day")) return false;
            if (
                filter.porVencer &&
                !itemDate.isBetween(moment(), moment().add(7, "days"), "day", "[]")
            )
                return false;
            if (filter.pagadas && item.remaining > 0) return false;
            if (filter.from && itemDate.isBefore(moment(filter.from), "day")) return false;
            if (filter.to && itemDate.isAfter(moment(filter.to), "day")) return false;
            return true;
        });
    };

    const getConsolidatedData = async () => {
        try {
            const response = await getInvoiceUploadedByVendorId(id);

            setOriginalData(response);

            const transformedData = response.map((item) => ({
                "Nit": item.nit,
                "Razón Social": item.name,
                "Factura": item.invoice,
                "Fecha": moment(item.date).format("DD/MM/YYYY"),
                "Total": formatPrice(item.total),
                "Retenciones": formatPrice(item.tax),
                "Valor a cancelar": formatPrice(item.net),
                "Abonos": formatPrice(item.deposits),
                "Restante": formatPrice(item.remaining),
                "Tipo de pago": item.longTermPayment ? t('actualInventory.labels.credito') : t('actualInventory.labels.contado'),
                "erpName": item.erpName,
            }));

            setData(getFilteredData(transformedData));
        } catch (error) {
            console.error(t('actualInventory.messages.errorFetchingConsolidatedData'), error);
        }
    };

    useEffect(() => {
        if (firstLoad.current) {
            getConsolidatedData();
            firstLoad.current = false;
        }
    }, []);

    useEffect(() => {
        const transformedData = getFilteredData(originalData).map((item) => ({
            "Nit": item.nit,
            "Razón Social": item.name,
            "Factura": item.invoice,
            "Fecha": moment(item.date).format("DD/MM/YYYY"),
            "Total": formatPrice(item.total),
            "Retenciones": formatPrice(item.tax),
            "Valor a cancelar": formatPrice(item.net),
            "Abonos": formatPrice(item.deposits),
            "Restante": formatPrice(item.remaining),
            "Tipo de pago": item.longTermPayment ? t('actualInventory.labels.credito') : t('actualInventory.labels.contado'),
            "erpName": item.erpName,
        }));
        setData(transformedData);
    }, [filter, originalData, t]);

    const findOriginalItem = (invoiceId) => {
        return originalData.find((item) => item.invoice === invoiceId.Factura);
    };

    const onClick = async (item) => {
        try {
            const realItem = findOriginalItem(item);
            const transactionEntity = await getTransactionEntityById(realItem.entity);
            console.log(realItem);
            navigation(
                `/get-report/${transactionEntity._id}`
            );
        } catch (error) {
            console.error(t('actualInventory.messages.errorFetchingTransactionEntity'), error);
        }
    };

    // Nueva función para recargar datos después de un abono
    const reloadData = () => {
        getConsolidatedData();
    };

    return (
        <>
            <Header />
            <div className="custom-container">
                <div className="holder">
                    <div className="workflows">
                        <div className="page-header">
                            <h1>{t('actualInventory.labels.consolidatedInfo')}</h1>
                        </div>
                        <div className="filters">
                            <h2>{t('actualInventory.labels.filter')}</h2>
                            <div className="date-filters">
                                <label>
                                    {t('actualInventory.labels.from')}
                                    <input
                                        type="date"
                                        name="from"
                                        value={filter.from}
                                        onChange={handleFilterChange}
                                    />
                                </label>
                                <label>
                                    {t('actualInventory.labels.to')}
                                    <input
                                        type="date"
                                        name="to"
                                        value={filter.to}
                                        onChange={handleFilterChange}
                                    />
                                </label>
                            </div>
                            <div className="checkbox-filters">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="contado"
                                        checked={filter.contado}
                                        onChange={handleFilterChange}
                                    />
                                    <span className="checkmark"></span>
                                    {t('actualInventory.labels.contado')}
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="credito"
                                        checked={filter.credito}
                                        onChange={handleFilterChange}
                                    />
                                    <span className="checkmark"></span>
                                    {t('actualInventory.labels.credito')}
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="vencidas"
                                        checked={filter.vencidas}
                                        onChange={handleFilterChange}
                                    />
                                    <span className="checkmark"></span>
                                    {t('actualInventory.labels.vencidas')}
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="porVencer"
                                        checked={filter.porVencer}
                                        onChange={handleFilterChange}
                                    />
                                    <span className="checkmark"></span>
                                    {t('actualInventory.labels.porVencer')}
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="pagadas"
                                        checked={filter.pagadas}
                                        onChange={handleFilterChange}
                                    />
                                    <span className="checkmark"></span>
                                    {t('actualInventory.labels.pagadas')}
                                </label>
                            </div>
                        </div>
                        {data.length === 0 ? (
                            <p>{t('actualInventory.messages.noData')}</p>
                        ) : (
                            <div className="consolidate-data">
                                <TableComponent
                                    data={data}
                                    onClick={onClick}
                                    ActionComponent={(props) => {
                                        const originalItem = findOriginalItem(props.item);
                                        return <ActionButtons {...props} item={originalItem} reloadData={reloadData} />;
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

ActualInventory.propTypes = {};

export default ActualInventory;
