// src/components/invoices/TransactionEntity.jsx
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

// Import components
import { Loader } from "../../common/loader/loader"
import { PdfComp } from "../../common/pdfcomp/pdfcomp"

// Import Services
import { getInvoiceByUID } from "../../../services/invoice"
import { getFile } from "../../../services/file"

// Import Helpers
import { DataExtraction } from "../../common/popUps/data_extraction_pop/data_extraction"

// Import Styles
import "./transactionEntity.css"
import Header from "../../common/Header/Header"
import { useTranslation } from "react-i18next" // Import useTranslation

export const TransactionEntity = () => {
	const { t } = useTranslation(["transaction"])
	const { id } = useParams()
	const [data, setData] = useState("")
	const [fileData, setFileData] = useState(false)
	const [showData, setShowData] = useState([])
	const [timeoutErr, setTimeoutErr] = useState(false)
	const [sections, setSections] = useState([
		{ title: t("transaction.causation"), href: "/causation" },
	])

	useEffect(() => {
		const handleGetDocumentKeyValuePairs = async (id) => {
			try {
				const response = await getInvoiceByUID(id)
				setFileData(response)
				if (!response) {
					setTimeoutErr(true)
				}
				if (response.uploadedERP) {
					setSections([
						{ title: t("transaction.causation"), href: "/causation" },
						{ title: t("transaction.debts"), href: "/debts" },
						{ title: t("transaction.consolidatedInfo"), href: `/consolidated-info/${fileData.supplierId}` },
					])
				} else {
					setSections([
						{ title: t("transaction.causation"), href: "/causation" },
						{ title: t("transaction.invoicesNotSent"), href: "/invoices-not-sent" },
					])
				}
				return response
			} catch (error) {
				console.error(t("transaction.messages.errorFetchingDocument"), error)
			}
		}

		const getData = async () => {
			try {
				setShowData(true)
				const response = await getFile(id)
				const fileUrl = URL.createObjectURL(response)
				setData(fileUrl)

				await handleGetDocumentKeyValuePairs(id)
			} catch (error) {
				console.error(t("transaction.messages.errorFetchingFile"), error)
			}
		}

		getData()
	}, [id, t, fileData.supplierId])

	// Refactored logic for rendering based on fileData and timeoutErr
	let content
	if (!fileData) {
		if (timeoutErr) {
			content = <Loader text={t("transaction.messages.errorOpeningFile")} handleError={true} />
		} else {
			content = <Loader text={t("transaction.messages.loading")} handleError={false} />
		}
	} else {
		content = (
			<div>
				<h2 className="ml-4">
					{fileData.invoiceId
						? `${t("transaction.labels.invoiceNumber")} ${fileData.invoiceId}`
						: t("transaction.labels.loadingInvoice")}
				</h2>
				<div>
					<div>
						<PdfComp pdfFile={data} />
					</div>
				</div>
				<div className="states">
					<div className="state">
						<DataExtraction show={showData} fileData={fileData} />
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<Header
				title={
					fileData.invoiceId
						? `${t("transaction.labels.invoiceNumber")} ${fileData.invoiceId}`
						: t("transaction.labels.loadingInvoice")
				}
				sections={sections}
			/>
			<div>
				<div>{content}</div>
			</div>
		</>
	)
}

export default TransactionEntity
