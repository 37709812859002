import Header from "@/components/common/Header/Header"
import { useTranslation } from "react-i18next"
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { DataTableConsolidatedInfo } from "../DataTables/DataTableConsolidatedInfo"
import { getInvoiceUploadedByVendorId, getUploadedInvoiceSummary } from "@/services/invoice" // Importar el nuevo método
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { formatNumber } from "@/utils/formatNumber"
import { getProviderById } from "@/services/providers"

export default function ConsolidatedInfo() {
	const { t } = useTranslation(["consolidatedInfo"])
	const [data, setData] = useState([])
	const { id } = useParams()
	const [companyData, setCompanyData] = useState({})
	const [invoiceSummary, setInvoiceSummary] = useState({
		totalInvoices: 0,
		totalPending: 0,
		totalPaid: 0,
	})

	useEffect(() => {
		// Obtener datos consolidados de la compañía
		const getConsolidatedData = async () => {
			try {
				const company = await getProviderById(id)
				setCompanyData({
					company: company.name,
					id: company.id,
				})

				const response = await getInvoiceUploadedByVendorId(company.id)

				const transformedData = response.map((item) => ({
					mongoId: item._id,
					entity: item.entity,
					id: item.invoice,
					date: new Date(item.date).toLocaleDateString(),
					total: item.total,
					withholdings: item.tax,
					amount: item.net,
					payment: item.deposits,
					remaining: item.remaining,
					paymentMethod: item.longTermPayment ? t("labels.credito") : t("labels.contado"),
					erp: item.erpName,
				}))

				setData(transformedData)
			} catch (error) {
				console.error(t("actualInventory.messages.errorFetchingConsolidatedData"), error)
			}
		}

		// Obtener el resumen de facturas subidas
		const getSummaryData = async () => {
			try {
				const summary = await getUploadedInvoiceSummary(companyData.id)
				setInvoiceSummary(summary)
			} catch (error) {
				console.error(t("actualInventory.messages.errorFetchingSummaryData"), error)
			}
		}

		getConsolidatedData()
		getSummaryData()
	}, [t, companyData.id, id])

	return (
		<>
			<Header
				title={t("title")}
				sections={[
					{ href: "/causation", title: t("causation") },
					{ href: "/debts", title: t("debts") },
				]}
			/>
			<div className="m-5 flex flex-col justify-between gap-5">
				<div className="flex flex-row justify-between gap-5 2xl:gap-10">
					{/* Carta 1 */}
					<Card className="h-full 2xl:w-2/6 flex flex-row bg-primary-grey-50 p-4 px-5 gap-5 justify-center">
						<div className="gap-10 2xl:gap-0 flex flex-col">
							<CardHeader className="flex flex-row justify-between items-center p-0">
								<CardTitle className="text-xl w-full 2xl:whitespace-nowrap">
									{t("leftCard.razonSocialTitle")}
								</CardTitle>
							</CardHeader>
							<CardDescription>{companyData.company}</CardDescription>
						</div>
						<div className="gap-10 2xl:gap-0 flex flex-col">
							<CardHeader className="flex flex-row justify-between items-center p-0 text-center">
								<CardTitle className="text-xl w-full 2xl:whitespace-nowrap">
									{t("leftCard.nitTitle")}
								</CardTitle>
							</CardHeader>
							<CardDescription>{companyData.id}</CardDescription>
						</div>
					</Card>

					<div className="flex flex-row w-full gap-5 2xl:gap-4">
						{/* Carta 2 */}
						<Card className="h-full w-full flex flex-col bg-secondary-violet-200 border border-secondary-violet-1200 justify-evenly p-4 2xl:gap-0">
							<CardHeader className="flex flex-row self-center justify-between items-center p-0">
								<CardTitle className="text-xl w-full 2xl:whitespace-nowrap">
									{t("rightCards.totalFacturasTitle")}
								</CardTitle>
							</CardHeader>
							<CardDescription className="text-grey-1800 text-center text-2xl">
								{formatNumber(invoiceSummary.totalInvoices)}
							</CardDescription>
						</Card>

						{/* Carta 3 */}
						<Card className="h-full w-full flex flex-col bg-secondary-orange-200 border border-secondary-orange-1200 justify-evenly p-4 2xl:gap-0">
							<CardHeader className="flex flex-row justify-between items-center p-0 self-center">
								<CardTitle className="text-xl w-full 2xl:whitespace-nowrap">
									{t("rightCards.facturasPendientesTitle")}
								</CardTitle>
							</CardHeader>
							<CardDescription className="text-grey-1800 text-center text-2xl">
								{invoiceSummary.totalPending}
							</CardDescription>
						</Card>

						{/* Carta 4 */}
						<Card className="h-full w-full flex flex-col bg-primary-green-200 border border-primary-green-1200 justify-evenly p-4 2xl:gap-0">
							<CardHeader className="flex flex-row justify-between items-center p-0 self-center">
								<CardTitle className="text-xl w-full 2xl:whitespace-nowrap">
									{t("rightCards.totalPagadoTitle")}
								</CardTitle>
							</CardHeader>
							<CardDescription className="text-grey-1800 text-center text-2xl">
								{formatNumber(invoiceSummary.totalPaid)}
							</CardDescription>
						</Card>
					</div>
				</div>
				{/* Filters */}
				<div className="flex items-center">
					<p className="text-lg font-bold">{t("subTitleFilter")}</p>
					<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
				</div>
				<DataTableConsolidatedInfo data={data} />
			</div>
		</>
	)
}
