import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex capitalize items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        neutral:
          'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80', // shadcn default
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        primary:
          'bg-primary-green-1200 text-primary-grey-1800 border font-bold hover:bg-primary-green-900 hover:border-primary-green-1400 rounded-[22px] px-2.5 py-1',
        secondary:
          'bg-primary-grey-1800 text-primary-green-1200 border font-bold hover:border-primary-grey-1800 hover:bg-primary-grey-1300 rounded-[22px] px-2.5 py-1',
        quiet:
          'hover:scale-110 transition-transform duration-300 ease-in-out bg-white border border-primary-grey-1800 text-primary-grey-1800  hover:border hover:border-primary-grey-800 rounded-[22px] px-2.5 py-1',
        calendar:
          'bg-primary-grey-50 text-primary-grey-1800   rounded-[22px] px-2.5 py-1',
        tertiary:
          ' text-primary-grey-1800 hover:text-primary-green-1500 rounded-[22px] px-2.5 py-1',
        negative:
          'bg-semantic-red-1200 text-white hover:bg-semantic-red-1200 hover:border hover:border-semantic-red-1400 rounded-[22px] px-2.5 py-1',
        arrowGrey:
          'border-2 border-primary-grey-1800 hover:border-white rounded-full [&_svg]:size-8 [&_svg]:hover:stroke-white [&_svg]:hover:scale-110 [&_svg]:transition-colors [&_svg]:transition-transform [&_svg]:duration-500 [&_svg]:ease-in-out duration-500 ease-in-out',
        arrowWhite:
          'border-2 border-white hover:border-primary-green-1200 rounded-full [&_svg]:stroke-white [&_svg]:size-8 [&_svg]:hover:stroke-primary-green-1200 [&_svg]:hover:scale-110 [&_svg]:transition-colors [&_svg]:transition-transform [&_svg]:duration-500 [&_svg]:ease-in-out duration-500 ease-in-out'
      },
      size: {
        default: 'h-9 px-4 py-2',
        circle: 'h-12 w-12 p-0	',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-8 w-8'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
