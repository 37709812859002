import propTypes from "prop-types"
import { useState } from "react"
import { MdOutlineDelete, MdOutlineEdit, MdOutlineSave } from "react-icons/md"

// Función para formatear los valores como moneda
const formatCurrency = (value) => {
	return new Intl.NumberFormat("es-CO", {
		style: "currency",
		currency: "COP",
		minimumFractionDigits: 2,
	}).format(value)
}

export default function ItemRow({
	description,
	quantity,
	total,
	unitPrice,
	tax,
	handleSave,
	handleDelete,
	enabled = false,
}) {
	const [edit, setEdit] = useState(enabled)
	const [currentValue, setCurrentValue] = useState({
		description,
		quantity,
		total,
		unitPrice,
		tax,
	})

	const handleChange = (e) => {
		setCurrentValue((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}))
	}

	return (
		<tr className="group hover:bg-slate-50 relative">
			{/* Descripción */}
			<td>
				<input
					type="text"
					name="description"
					className="text-clip disabled:border-none"
					value={currentValue.description}
					disabled={!edit}
					onChange={handleChange}
				/>
			</td>

			{/* Cantidad */}
			<td>
				<input
					type="number"
					name="quantity"
					className="text-clip disabled:border-none"
					value={currentValue.quantity}
					disabled={!edit}
					onChange={handleChange}
				/>
			</td>

			{/* Total - Muestra el valor formateado si no está en modo edición */}
			<td>
				{edit ? (
					<input
						type="number"
						name="total"
						className="text-clip disabled:border-none"
						value={currentValue.total}
						disabled={!edit}
						onChange={handleChange}
					/>
				) : (
					<span>{formatCurrency(currentValue.total)}</span>
				)}
			</td>

			{/* Precio Unitario - Muestra el valor formateado si no está en modo edición */}
			<td>
				{edit ? (
					<input
						type="number"
						name="unitPrice"
						className="text-clip disabled:border-none"
						value={currentValue.unitPrice}
						disabled={!edit}
						onChange={handleChange}
					/>
				) : (
					<span>{formatCurrency(currentValue.unitPrice)}</span>
				)}
			</td>

			{/* Impuesto - Muestra el valor formateado si no está en modo edición */}
			<td>
				{edit ? (
					<input
						type="number"
						name="tax"
						className="text-clip disabled:border-none"
						value={currentValue.tax}
						disabled={!edit}
						onChange={handleChange}
					/>
				) : (
					<span>{formatCurrency(currentValue.tax)}</span>
				)}
			</td>

			{/* Botones de acción */}
			<div className="absolute right-2 top-1/2 -translate-y-1/2 group-hover:opacity-100 opacity-0 flex gap-2">
				{edit ? (
					<>
						<button
							type="button"
							onClick={() => {
								setEdit(false)
								handleSave(currentValue)
							}}
						>
							<MdOutlineSave
								className="text-green-600 opacity-50 hover:opacity-100"
								size={24}
							/>
						</button>
						<button type="button" onClick={handleDelete}>
							<MdOutlineDelete
								className="text-red-600 opacity-50 hover:opacity-100"
								size={24}
							/>
						</button>
					</>
				) : (
					<button type="button" onClick={() => setEdit(true)}>
						<MdOutlineEdit
							className="text-yellow-600 opacity-50 hover:opacity-100"
							size={24}
						/>
					</button>
				)}
			</div>
		</tr>
	)
}

ItemRow.propTypes = {
	description: propTypes.string.isRequired,
	quantity: propTypes.number.isRequired,
	total: propTypes.number.isRequired,
	unitPrice: propTypes.number.isRequired,
	tax: propTypes.number.isRequired,
	handleSave: propTypes.func.isRequired,
	handleDelete: propTypes.func.isRequired,
	enabled: propTypes.bool,
}
