// src/components/inventory/Inventory.jsx
import { useEffect, useState } from "react"
import propTypes from "prop-types"
import { useTranslation } from "react-i18next"

// Import necessary components
import { ToastContainer } from "react-toastify"
import Button from "../../common/Buttons/Button"
import {
	TbCircleOff,
	TbCloudDownload,
	TbMinus,
	TbPlus,
	TbSearch,
	TbTriangleInvertedFilled,
} from "react-icons/tb"

import FormCheckInput from "../../common/inputs/FormCheckInput"
import {
	createProduct,
	deleteProduct,
	getAllCategories,
	getAllProducts,
	productoToProduct,
	productToProducto,
	putProduct,
	searchProducts,
} from "../../../services/products"
import { toggleToast } from "../modules/modules"
import ForbiddenPopUp from "../../common/popUps/forbiddenPopUp/ForbiddenPopUp"
import { getIntegrationbyUID, updateInventoryIntegration } from "../../../services/integrations"
import { useDispatch } from "react-redux"
import { syncNotifications } from "../../../redux/notifications.slice"
import { getAllNotificationsByFilter } from "../../../services/notifications"
import { useLocation, useNavigate } from "react-router-dom"
import Header from "../../common/Header/Header"
import FormTextInput from "../../common/inputs/FormTextInput"
import FormSelectInput from "../../common/inputs/FormSelectInput"

const defaultProduct = {
	_id: "",
	code: "",
	name: "",
	prices: [],
	active: true,
	type: "",
	reference: "",
	available_quantity: 0,
	inventory_type: "",
	category: {
		code: "",
		name: "",
	},
	unit: {
		code: "",
		name: "",
	},
	unit_label: "",
	stock_control: false,
	ware_houses: [],
	tax_included: false,
	tax_classification: "",
	tax_consumption_value: 0,
}

export default function Inventory() {
	const { t } = useTranslation(["inventory"])
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [isButtonDisabled, setIsButtonDisabled] = useState(false)
	const [products, setProducts] = useState([])
	const [categories, setCategories] = useState([])
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState("")
	const [fieldErrors, setFieldErrors] = useState({})
	const [forbidden, setForbidden] = useState(false)

	const [selected, setSelected] = useState(defaultProduct)

	const getCategories = async () => {
		const res = await getAllCategories()
		let fetchedCategories
		if (res) {
			fetchedCategories = res
		} else {
			fetchedCategories = []
			setForbidden(true)
		}
		setCategories(fetchedCategories)
	}

	useEffect(() => {
		getCategories()
	}, [])

	const getProducts = async () => {
		let limit
		if (window.innerWidth >= 1536) {
			limit = 27
		} else if (window.innerWidth >= 1280) {
			limit = 14
		} else {
			limit = 8
		}
		let fetchedProducts
		if (filter !== "") {
			fetchedProducts = await searchProducts({ limit, page, search: filter })
		} else {
			const res = await getAllProducts({ limit, page })
			if (res) {
				fetchedProducts = res
			} else {
				fetchedProducts = []
				setForbidden(true)
			}
		}
		setProducts(fetchedProducts.map(productToProducto).reverse())
	}

	useEffect(() => {
		getProducts()
	}, [page, filter])

	const validateFields = (creating = true) => {
		const errors = {}
		if (!selected.code) errors.code = t("messages.codigoRequired")
		if (!selected.type) errors.type = t("messages.tipoRequired")
		if (!selected.name) errors.name = t("messages.nombreRequired")
		if (!selected.inventory_type) errors.inventory_type = t("messages.categoriaRequired")
		if (!selected.available_quantity && selected.available_quantity !== 0)
			errors.available_quantity = t("messages.cantidadRequired")
		if (!selected.reference) errors.reference = t("messages.referenciaRequired")
		if (!selected.unit.code) errors.unit_code = t("messages.unitCodeRequired")
		if (!selected.unit.name) errors.unit_name = t("messages.unitNameRequired")
		if (!selected.unit_label) errors.unit_label = t("messages.unitLabelRequired")
		if (!selected.tax_classification)
			errors.tax_classification = t("messages.taxClassificationRequired")
		if (selected.tax_consumption_value === "")
			errors.tax_consumption_value = t("messages.taxValueRequired")
		if (new Set(selected.prices.map((val) => val.currency_code)).size !== selected.prices.length)
			errors.price = t("messages.duplicateCurrencyCode")
		selected.prices.forEach((price) => {
			if (!price.currency_code) errors.currency_code = t("messages.currencyCodeRequired")
		})
		selected.prices.forEach((price) => {
			price.price_list.forEach((product) => {
				if (!product.value) {
					errors.prices = errors.prices || {}
					errors.prices[product.id] = t("messages.productValueRequired")
				}
				if (!product.name) {
					errors.prices = errors.prices || {}
					errors.prices[product.id] = t("messages.productNameRequired")
				}
			})
		})
		validateSelection(creating, errors)

		setFieldErrors(errors)

		return Object.keys(errors).length === 0
	}

	const validateSelection = (creating = true, errors = {}) => {
		if (creating && selected._id) errors.id = t("messages.deleteSelectionToCreate")
		else if (!creating && !selected._id) errors.id = t("messages.selectProductToEditDelete")

		setFieldErrors(errors)

		return Object.keys(errors).length === 0
	}

	const createSelectedProduct = async () => {
		if (!validateFields()) return

		try {
			await createProduct(productoToProduct(selected))
			setProducts((prev) => [selected, ...prev.slice(0, -1)])
			toggleToast(t("messages.productoCreado"))
			setSelected(defaultProduct)
			getProducts()
		} catch (error) {
			toggleToast(t("messages.errorCreandoProducto"))
		}
	}

	const editSelectedProduct = async () => {
		if (!validateFields(false)) return

		try {
			await putProduct(productoToProduct(selected))
			toggleToast(t("messages.productoEditado"))
			setSelected(defaultProduct)
			getProducts()
		} catch (error) {
			toggleToast(t("messages.errorEditandoProducto"))
		}
	}

	const deleteSelectedProduct = async () => {
		if (!validateSelection(false)) return

		try {
			await deleteProduct(productoToProduct(selected))
			toggleToast(t("messages.productoEliminado"))
			setSelected(defaultProduct)
			getProducts()
		} catch (error) {
			toggleToast(t("messages.errorEliminandoProducto"))
		}
	}

	useEffect(() => {
		if (location.state?.item) {
			setSelected({ ...defaultProduct, name: location.state.item?.name })
			navigate(location.pathname, { replace: true })
		}
	}, [location.state, navigate, location.pathname])

	return (
		<>
			<Header title={t("title")} sections={[{ title: t("management"), href: "/" }]} />
			{forbidden && (
				<div className="z-10 w-full h-full backdrop-blur-[2px] bg-white/20 absolute top-0">
					<ForbiddenPopUp className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
				</div>
			)}
			<main className="px-12 w-full pt-[3px]">
				<section>
					<div className="flex gap-4">
						{/* Formulario de Información del Producto */}
						<div className="m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all outline-none w-1/2 2xl:w-1/3 flex flex-col gap-6 h-min">
							<h2>{t("labels.informacionProducto")}</h2>
							<div className="flex flex-col xl:grid xl:grid-cols-2 gap-x-8 gap-y-6">
								{/* ID */}
								<div className="relative flex flex-col lg:col-span-2">
									<FormTextInput
										id="id"
										label={t("labels.id")}
										className="w-full"
										value={selected._id}
										disabled
									/>
									<button
										className="absolute top-2 right-2 h-5 w-5 rounded-md hover:bg-red-500/20"
										onClick={() => setSelected({ ...selected, _id: "" })}
									>
										<TbCircleOff className="text-red-500 m-auto" />
									</button>
									{fieldErrors.id && <span className="text-red-500">{fieldErrors.id}</span>}
								</div>

								{/* Código */}
								<div className="flex flex-col lg:col-span-2">
									<FormTextInput
										id="codigo"
										label={t("labels.codigo")}
										className="w-full"
										value={selected.code}
										onChange={(e) => setSelected({ ...selected, code: e.target.value })}
									/>
									{fieldErrors.code && <span className="text-red-500">{fieldErrors.code}</span>}
								</div>

								{/* Tipo */}
								<div className="flex flex-col">
									<FormSelectInput
										id="tipo"
										label={t("labels.tipo")}
										value={selected.type}
										className=""
										options={Object.values(t("options.tipo", { returnObjects: true }))}
										onChange={(e) => setSelected({ ...selected, type: e.target.value })}
									/>
									{fieldErrors.type && <span className="text-red-500">{fieldErrors.type}</span>}
								</div>

								{/* Categoría */}
								<div className="flex flex-col">
									<FormSelectInput
										id="categoria"
										label={t("labels.categoria")}
										value={selected.category?.name}
										className=""
										options={categories.map((category) => category.name)}
										onChange={(e) => {
											const category = categories.find(
												(category) => category.name === e.target.value,
											)
											setSelected({
												...selected,
												inventory_type: category?.id,
												category,
											})
										}}
									/>
									{fieldErrors.inventory_type && (
										<span className="text-red-500">{fieldErrors.inventory_type}</span>
									)}
								</div>

								{/* Nombre */}
								<div className="flex flex-col">
									<FormTextInput
										id="nombre"
										label={t("labels.nombre")}
										className=""
										value={selected.name}
										onChange={(e) => setSelected({ ...selected, name: e.target.value })}
									/>
									{fieldErrors.name && <span className="text-red-500">{fieldErrors.name}</span>}
								</div>

								{/* Cantidad */}
								<div>
									<FormTextInput
										id="cantidad"
										label={t("labels.cantidad")}
										className=""
										value={selected.available_quantity}
										number
										onChange={(e) =>
											setSelected({
												...selected,
												available_quantity: e.target.value,
											})
										}
									/>
									{fieldErrors.available_quantity && (
										<span className="text-red-500">{fieldErrors.available_quantity}</span>
									)}
								</div>

								{/* Activo y Control de Stock */}
								<div className="flex flex-col justify-between">
									<FormCheckInput
										id="activo"
										label={t("labels.activo")}
										value={selected.active}
										onChange={(e) => setSelected({ ...selected, active: e.target.checked })}
									/>
									<FormCheckInput
										id="controlStock"
										label={t("labels.controlStock")}
										value={selected.stock_control}
										onChange={(e) =>
											setSelected({
												...selected,
												stock_control: e.target.checked,
											})
										}
									/>
								</div>

								{/* Referencia */}
								<div>
									<FormTextInput
										id="referencia"
										label={t("labels.referencia")}
										value={selected.reference}
										onChange={(e) => setSelected({ ...selected, reference: e.target.value })}
									/>
									{fieldErrors.reference && (
										<span className="text-red-500">{fieldErrors.reference}</span>
									)}
								</div>

								{/* Unidad */}
								<h3 className="-mb-2 lg:col-span-2">{t("labels.unidad")}</h3>
								<div>
									<FormTextInput
										id="codigoUnidad"
										label={t("labels.codigoUnidad")}
										value={selected.unit.code}
										onChange={(e) =>
											setSelected({
												...selected,
												unit: { ...selected.unit, code: e.target.value },
											})
										}
									/>
									{fieldErrors.unit_code && (
										<span className="text-red-500">{fieldErrors.unit_code}</span>
									)}
								</div>
								<div>
									<FormTextInput
										id="nombreUnidad"
										label={t("labels.nombreUnidad")}
										value={selected.unit.name}
										onChange={(e) =>
											setSelected({
												...selected,
												unit: { ...selected.unit, name: e.target.value },
											})
										}
									/>
									{fieldErrors.unit_name && (
										<span className="text-red-500">{fieldErrors.unit_name}</span>
									)}
								</div>
								<div>
									<FormTextInput
										id="etiquetaUnidad"
										label={t("labels.etiquetaUnidad")}
										className=""
										value={selected.unit_label}
										onChange={(e) => setSelected({ ...selected, unit_label: e.target.value })}
									/>
									{fieldErrors.unit_label && (
										<span className="text-red-500">{fieldErrors.unit_label}</span>
									)}
								</div>

								{/* Impuestos */}
								<h3 className="-mb-2 lg:col-span-2">{t("labels.impuestos")}</h3>
								<div>
									<FormCheckInput
										id="impuestoIncluido"
										label={t("labels.impuestoIncluido")}
										value={selected.tax_included}
										onChange={(e) =>
											setSelected({
												...selected,
												tax_included: e.target.checked,
											})
										}
										className="py-auto h-full"
									/>
								</div>
								<div>
									<FormSelectInput
										id="tipoImpuesto"
										label={t("labels.tipoImpuesto")}
										value={selected.tax_classification}
										options={Object.values(t("options.tipoImpuesto", { returnObjects: true }))}
										onChange={(e) =>
											setSelected({
												...selected,
												tax_classification: e.target.value,
											})
										}
									/>
									{fieldErrors.tax_classification && (
										<span className="text-red-500">{fieldErrors.tax_classification}</span>
									)}
								</div>
								<div className="lg:col-span-2">
									<FormTextInput
										id="valorImpuestoConsumo"
										label={t("labels.valorImpuestoConsumo")}
										value={selected.tax_consumption_value}
										number
										onChange={(e) =>
											setSelected({
												...selected,
												tax_consumption_value: e.target.value,
											})
										}
									/>
									{fieldErrors.tax_consumption_value && (
										<span className="text-red-500">{fieldErrors.tax_consumption_value}</span>
									)}
								</div>

								{/* Precios */}
								<h3 className="-mb-2 lg:col-span-2">{t("labels.precios")}</h3>
								{selected?.prices.map((price) => (
									<Currency
										key={price.id}
										price={price}
										setSelected={setSelected}
										errors={fieldErrors}
										t={t}
									/>
								))}
								{fieldErrors.price && (
									<span className="text-red-500 lg:col-span-2">{fieldErrors.price}</span>
								)}
								<div className="lg:col-span-2">
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-green-600"
										onClick={() => {
											const last_id = selected?.prices[selected.prices.length - 1]?.id || 0
											setSelected({
												...selected,
												prices: [
													...selected.prices,
													{
														id: last_id + 1,
														currency_code: "",
														price_list: [],
													},
												],
											})
										}}
									>
										{t("buttons.añadirMoneda")}
									</button>
								</div>

								{/* Botones de Acción */}
								<div className="col-span-2 flex justify-center gap-4">
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-green-600"
										onClick={() => createSelectedProduct()}
									>
										{t("buttons.crear")}
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-yellow-400"
										onClick={() => editSelectedProduct()}
									>
										{t("buttons.guardar")}
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-red-500"
										onClick={() => deleteSelectedProduct()}
									>
										{t("buttons.borrar")}
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-gray-500"
										onClick={() => {
											setSelected(defaultProduct)
											setFieldErrors({})
										}}
									>
										{t("buttons.limpiar")}
									</button>
								</div>
							</div>
						</div>

						{/* Lista de Productos */}
						<div className="relative w-1/2 2xl:w-2/3 m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all outline-none max-h-full">
							<div className="flex flex-col w-full gap-2">
								<h2>{t("labels.listaProductos")}</h2>
								<div className="relative">
									<TbSearch className="absolute top-1/2 -translate-y-1/2 left-1" />
									<input
										type="text"
										className="pl-5 border border-gray-600 rounded-md h-full w-full"
										onChange={(e) => {
											setFilter(e.target.value)
											setPage(1)
										}}
									/>
								</div>
								<ul className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-2 overflow-y-scroll mb-10">
									{products.length === 0 && (
										<div className="col-span-full text-center flex flex-col items-center gap-4">
											<span>{t("messages.noProductos")}</span>
											<button
												className={`px-4 py-2 rounded-md border text-wrap flex w-min gap-2 ${
													isButtonDisabled
														? "bg-gray-400 border-gray-500 cursor-not-allowed text-white"
														: "bg-green-500/20 hover:bg-green-500/30 border-green-500 text-black"
												}`}
												onClick={async () => {
													const integration = await getIntegrationbyUID()

													await updateInventoryIntegration(integration)
													dispatch(
														syncNotifications(
															await getAllNotificationsByFilter({
																type: {
																	provider: true,
																	inventory: true,
																},
																status: { pending: true },
															}),
														),
													)
													setIsButtonDisabled(true) // Deshabilita el botón después del primer clic
												}}
												disabled={isButtonDisabled} // Deshabilita el botón si es true
											>
												<TbCloudDownload className="h-12 w-12" />
												{t("buttons.sincronizarInformacion")}
											</button>
										</div>
									)}
									{products.map((product) => (
										<li key={product.code}>
											<button
												className="flex flex-col 2xl:flex-row gap-2 p-2 rounded-md border-2 border-gray-200 shadow-sm items-center justify-between text-start w-full"
												onClick={() => {
													const category = categories.find(
														(category) => category.id === product.inventory_type,
													)
													product.category = category
													product.prices = product.prices || []
													product.prices.forEach((price, index) => (price.id = index))
													setSelected(product)
												}}
											>
												<div className="flex flex-col w-3/4">
													<span className="text-lg text-ellipsis line-clamp-1 text-center 2xl:text-left w-full">
														{product.name}
													</span>
													<div className="flex gap-1 text-sm text-gray-500 self-center 2xl:self-start w-full">
														<span className="text-ellipsis line-clamp-1 max-w-1/2">
															{product.code}
														</span>
														<span className="text-ellipsis line-clamp-1 w-1/2">{product.type}</span>
													</div>
												</div>
												<div className="flex 2xl:flex-col 2xl:gap-2 items-end justify-around 2xl:justify-normal w-full 2xl:w-min">
													<span className="rounded-full bg-green-100 px-2 text-xs w-fit">
														{product.active ? t("labels.activo") : t("labels.inactivo")}
													</span>
													<span className="rounded-full bg-green-100 px-2 text-xs w-fit">
														{product.available_quantity}
													</span>
												</div>
											</button>
										</li>
									))}
								</ul>
								<div className="flex justify-self-end self-center gap-4 pt-2 absolute bottom-3">
									<Button
										text={t("buttons.anterior")}
										onClick={() => setPage(page - 1)}
										disabled={page <= 1}
									/>
									<button className="bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer">
										{page}
									</button>
									<Button text={t("buttons.siguiente")} onClick={() => setPage(page + 1)} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<ToastContainer />
		</>
	)
}

function Currency({ price, setSelected, errors, t }) {
	const [toggle, setToggle] = useState(false)
	return (
		<div key={price.id} className="lg:col-span-2">
			<div className="flex gap-2 items-center">
				<FormSelectInput
					id={`moneda-${price.id}`}
					label={t("labels.moneda")}
					value={price.currency_code}
					className="w-1/3"
					options={[
						"COP",
						"EUR",
						"USD",
						"ANG",
						"ARS",
						"AUD",
						"BOB",
						"BRL",
						"CAD",
						"CHF",
						"CLP",
						"CRC",
						"GBP",
						"GTQ",
						"HNL",
						"JPY",
						"MXN",
						"NZD",
						"PAB",
						"PEN",
						"SGD",
						"UYU",
					]}
					onChange={(e) =>
						setSelected((prev) => {
							return {
								...prev,
								prices: prev.prices.map((val) => {
									if (val.id === price.id) {
										return {
											...val,
											currency_code: e.target.value,
										}
									} else {
										return val
									}
								}),
							}
						})
					}
				/>
				<button
					className="rounded-md hover:bg-neutral-100 h-5 w-5"
					onClick={() => setToggle(!toggle)}
				>
					<TbTriangleInvertedFilled
						size={12}
						className={`transition-transform m-auto ${toggle ? "rotate-0" : "-rotate-90"}`}
					/>
				</button>
				<button
					className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-red-500"
					onClick={() =>
						setSelected((prev) => {
							return {
								...prev,
								prices: prev.prices.filter((val) => val.id !== price.id),
							}
						})
					}
				>
					{t("buttons.borrar")}
				</button>
			</div>
			{toggle && (
				<div className="flex flex-col gap-4 mt-4">
					{price.price_list.map((val) => (
						<div key={val.position} className="flex gap-2 items-end">
							<FormTextInput
								id={`moneda-${price.id}-${val.position}-nombre`}
								label={t("labels.nombre")}
								value={val.name}
								className="w-1/3"
								onChange={(e) =>
									setSelected((prev) => {
										return {
											...prev,
											prices: prev.prices.map((priceItem) => {
												if (priceItem.id === price.id) {
													return {
														...priceItem,
														price_list: priceItem.price_list.map((el) => {
															if (el.position === val.position) {
																return {
																	...el,
																	name: e.target.value,
																}
															} else {
																return el
															}
														}),
													}
												} else {
													return priceItem
												}
											}),
										}
									})
								}
							/>
							<FormTextInput
								id={`moneda-${price.id}-${val.position}-valor`}
								label={t("labels.valor")}
								value={val.value}
								number
								className="w-1/3"
								onChange={(e) =>
									setSelected((prev) => {
										return {
											...prev,
											prices: prev.prices.map((priceItem) => {
												if (priceItem.id === price.id) {
													return {
														...priceItem,
														price_list: priceItem.price_list.map((el) => {
															if (el.position === val.position) {
																return {
																	...el,
																	value: e.target.value,
																}
															} else {
																return el
															}
														}),
													}
												} else {
													return priceItem
												}
											}),
										}
									})
								}
							/>
							<button className="h-5 w-5 rounded-md hover:bg-red-500/20">
								<TbMinus
									size={16}
									className="text-red-500 m-auto"
									onClick={() =>
										setSelected((prev) => {
											return {
												...prev,
												prices: prev.prices.map((priceItem) => {
													if (priceItem.id === price.id) {
														return {
															...priceItem,
															price_list: priceItem.price_list.filter(
																(el) => el.position !== val.position,
															),
														}
													} else {
														return priceItem
													}
												}),
											}
										})
									}
								/>
							</button>
						</div>
					))}
					<button
						className="h-5 w-5 rounded-md hover:bg-green-500/20"
						onClick={() =>
							setSelected((prev) => {
								return {
									...prev,
									prices: prev.prices.map((val) => {
										if (val.id === price.id) {
											const last_id = val?.price_list[val.price_list.length - 1]?.position || 0
											return {
												...val,
												price_list: val.price_list.concat({
													position: last_id + 1,
													price: 0,
												}),
											}
										} else {
											return val
										}
									}),
								}
							})
						}
					>
						<TbPlus size={16} className="text-[#006e25] m-auto" />
					</button>
				</div>
			)}
			{errors && errors.prices && errors.prices[price.id] && (
				<p className="text-red-500 text-sm">{errors.prices[price.id]}</p>
			)}
		</div>
	)
}

Currency.propTypes = {
	price: propTypes.any.isRequired,
	setSelected: propTypes.func.isRequired,
	errors: propTypes.any.isRequired,
	t: propTypes.func.isRequired,
}
