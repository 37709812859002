import { useEffect } from "react"
import Cookies from "js-cookie"
import Routing from "../navigation/Routes"
import { useDispatch } from "react-redux"
import { login } from "../redux/userSlice"
import { authToken } from "../services/user"

import { pdfjs } from "react-pdf"
import { syncNotifications } from "../redux/notifications.slice"
import { getAllNotificationsByFilter } from "../services/notifications"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.mjs",
	import.meta.url,
).toString()

const App = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		const verifyToken = async () => {
			try {
				const verifyTokenData = await authToken(Cookies.get("token"))
				if (verifyTokenData.status === 200) {
					dispatch(login(verifyTokenData.data))
					dispatch(
						syncNotifications(
							await getAllNotificationsByFilter({
								type: { provider: true, inventory: true, uploadERP: true },
								status: { pending: true },
								priority: { info: true, warning: true, error: true },
							}),
						),
					)
				}
			} catch (error) {
				console.error(error)
				Cookies.remove("token")
				window.location.reload()
			}
		}

		if (Cookies.get("token")) {
			verifyToken()
		}
	}, [dispatch])

	return <Routing />
}

export default App
