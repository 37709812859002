// src/components/sales/ProviderBySales.jsx
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

// Services
import { getSalesGroupByCustomer } from "../../../services/sales";

// Components
import { TableComponent } from "../../common/tableComponent/tableComponent"; // Asegúrate de tener este componente
import Header from "../../common/Header/Header";
import ForbiddenPopUp from "../../common/popUps/forbiddenPopUp/ForbiddenPopUp"; // Si utilizas este componente

// Selectors
const selectUser = createSelector(
	(state) => state.user,
	(user) => user
);

export default function ProviderBySales() {
	const { t } = useTranslation(['sales']);
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	
	const [preferences, setPreferences] = useState({
		lastPull: t('sales.labels.sinFechaRegistrada'),
		pullFrequency: t('sales.labels.sincronizarInformacion'),
	});
	const [salesInvoices, setSalesInvoices] = useState([]);
	const [paginationPreferences, setPaginationPreferences] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [totalPages, setTotalPages] = useState(1);
	const [searchQuery, setSearchQuery] = useState(""); // Para buscar por nombre de proveedor
	const [forbidden, setForbidden] = useState(false);

	const frequencyTranslations = {
		monthly: t('sales.options.frecuencia.monthly'),
		annual: t('sales.options.frecuencia.annual'),
		fortnightly: t('sales.options.frecuencia.fortnightly'),
	};

	const transformSalesInvoices = (salesInvoices) => {
		return salesInvoices.map((invoice) => {
			return {
				[t('sales.labels.numeroCliente')]: invoice.providerName,
				[t('sales.labels.identificacion')]: invoice._id, // Asegúrate de que esta clave exista en el JSON
				[t('sales.labels.total')]: invoice.totalAmount,
				[t('sales.labels.facturas')]: invoice.totalInvoices,
			};
		});
	};

	const getSales = async () => {
		try {
			const response = await getSalesGroupByCustomer(paginationPreferences, searchQuery);
			if (response && response.salesByCustomer) {
				setSalesInvoices(response.salesByCustomer);
				setTotalPages(Math.ceil(response.total / paginationPreferences.limit));
			} else {
				setSalesInvoices([]);
				setTotalPages(1);
			}
		} catch (error) {
			console.error("Error getting sales invoices:", error);
			setSalesInvoices([]);
			setTotalPages(1);
			// Puedes agregar una notificación de error aquí si lo deseas
		}
	};

	const handleLimitChange = (event) => {
		const newLimit = parseInt(event.target.value, 10);
		setPaginationPreferences((prev) => ({
			...prev,
			limit: newLimit,
			currentPage: 1, // Reiniciar a la primera página al cambiar el límite
		}));
	};

	const handlePreviousPage = () => {
		setPaginationPreferences((prev) => ({
			...prev,
			currentPage: Math.max(prev.currentPage - 1, 1),
		}));
	};

	const handleNextPage = () => {
		setPaginationPreferences((prev) => ({
			...prev,
			currentPage: Math.min(prev.currentPage + 1, totalPages),
		}));
	};

	const handleRowClick = (item) => {
		if (item) {
			navigate(`/sales/${item[t('sales.labels.identificacion')]}`);
		}
	};

	useEffect(() => {
		const lastSalesPull = user.preferences?.lastSalesPull;
		let frequency = user.preferences?.salesPullConcurrency || "---";
		let formattedDate = t('sales.labels.sinFechaRegistrada');

		if (lastSalesPull) {
			const date = new Date(lastSalesPull);
			if (!isNaN(date.getTime())) {
				formattedDate = date.toLocaleString("es-ES", {
					year: "numeric",
					month: "long",
					day: "numeric",
				});
			}
		}
		const translatedFrequency = frequencyTranslations[frequency] || "---";

		setPreferences({
			lastPull: formattedDate,
			pullFrequency: translatedFrequency,
		});
	}, [user, t]);

	useEffect(() => {
		getSales();
	}, [paginationPreferences, searchQuery]);

	return (
		<>
			<Header title={t('sales.title')} />
			{forbidden && (
				<div className="z-10 w-full h-full backdrop-blur-[2px] bg-white/20 absolute top-0">
					<ForbiddenPopUp className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
				</div>
			)}
			<div className="px-12 w-full pt-[3px]">
				<div className="flex justify-between m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all">
					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-4">
							<h2>{t('sales.labels.ultimaRecoleccion')}: {preferences.lastPull}</h2>
							<h2>
								{t('sales.labels.frecuenciaRecoleccion')}: {preferences.pullFrequency}
							</h2>
						</div>
						<div className="w-auto">
							<label
								htmlFor="limit"
								className="block text-sm font-medium text-gray-700"
							>
								{t('sales.labels.limiteRegistros')}:
							</label>
							<select
								id="limit"
								value={paginationPreferences.limit}
								onChange={handleLimitChange}
								className="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
								style={{ width: "120px" }}
							>
								{Object.keys(t('sales.options.limiteRegistros', { returnObjects: true })).map((limit) => (
									<option key={limit} value={limit}>
										{t(`sales.options.limiteRegistros.${limit}`)}
									</option>
								))}
							</select>
						</div>
						<div className="flex gap-4 items-center">
							<input
								type="text"
								placeholder={t('sales.labels.buscarProveedor')}
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								className="border border-gray-300 p-2 rounded-md"
							/>
							{/* Si tienes campos de fecha, puedes internacionalizarlos también */}
							{/* 
							<label htmlFor="startDate">{t('sales.labels.desde')}:</label>
							<input
								type="date"
								id="startDate"
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
								className="border border-gray-300 p-2 rounded-md"
							/>
							<label htmlFor="endDate">{t('sales.labels.hasta')}:</label>
							<input
								type="date"
								id="endDate"
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
								className="border border-gray-300 p-2 rounded-md"
							/>
							*/}
							<button
								onClick={getSales}
								className="bg-blue-500 text-white px-4 py-2 rounded-md"
							>
								{t('sales.buttons.buscar')}
							</button>
						</div>
					</div>
					<button
						onClick={() => {
							window.scrollTo({ top: 0 });
							navigate("/sales/form");
						}}
						className="bg-green-500/20 h-min px-4 py-2 rounded-md border-green-500/25 text-green-700 border hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out shadow-md hover:shadow-lg"
					>
						{t('sales.buttons.subirFactura')}
					</button>
				</div>

				<div className="mt-4">
					{salesInvoices.length > 0 ? (
						<TableComponent
							data={transformSalesInvoices(salesInvoices)}
							onClick={handleRowClick}
						/>
					) : (
						<p>{t('sales.messages.noFacturasDisponibles')}</p>
					)}
				</div>

				<div className="flex justify-center items-center mt-4 gap-4">
					<button
						className={`bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer ${
							paginationPreferences.currentPage === 1
								? "cursor-not-allowed bg-gray-300"
								: ""
						}`}
						onClick={handlePreviousPage}
						disabled={paginationPreferences.currentPage === 1}
					>
						{t('sales.buttons.anterior')}
					</button>
					<span>
						{t('sales.labels.paginaActual')} {paginationPreferences.currentPage} {t('sales.labels.paginaTotal')} {totalPages}
					</span>
					<button
						className={`bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer ${
							paginationPreferences.currentPage === totalPages
								? "cursor-not-allowed bg-gray-300"
								: ""
						}`}
						onClick={handleNextPage}
						disabled={paginationPreferences.currentPage === totalPages}
					>
						{t('sales.buttons.siguiente')}
					</button>
				</div>
			</div>
		</>
	);
}
