import { useEffect, useState } from "react"
import Header from "../../common/Header/Header"
import "react-toastify/dist/ReactToastify.css"
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { useTranslation } from "react-i18next"
import NumberFlow from "@number-flow/react"
import { getAllTaxesCurrentMonth, getAllCountTaxes } from "../../../services/invoice"

// Importa tus dos componentes

function Taxes() {
	const { t } = useTranslation(["taxes"])
	const [taxSummary, setTaxSummary] = useState({
		ivaTaxes: 0,
		reteICA: 0,
		rentTaxes: 0,
		otherTaxes: 0,
	})
	const [count, setCount] = useState({
		IVA: 0,
		ICA: 0,
		rent: 0,
		other: 0,
	})
	const fetchTaxSummary = async () => {
		const response = await getAllTaxesCurrentMonth()
		const response2 = await getAllCountTaxes()
		setTaxSummary(response)
		setCount(response2)
	}

	useEffect(() => {
		fetchTaxSummary()
	}, [])

	return (
		<>
			<Header title={t("taxes")} />
			<section className="m-5 flex flex-col justify-between gap-5">
				<div className="flex items-center">
					<p className="text-lg font-bold">{t("IVA")}</p>
					<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
				</div>
				{/*Impuesto IVA */}
				<div className="flex flex-row 2xl:h-32 justify-between gap-5 2xl:gap-10">
					<Card className=" w-2/5 flex flex-row bg-primary-green-200 border border-primary-green-1200 gap-2 p-3 2xl:gap-0">
						<CardHeader className="flex flex-col items-center 2xl:justify-center h-full">
							<CardTitle className="text-3xl text-center">Total de facturas</CardTitle>
						</CardHeader>
						<CardDescription className="self-center">
							<NumberFlow
								className="text-5xl"
								value={count.IVA}
								trend={0}
								format={{ notation: "compact" }}
							/>
						</CardDescription>
					</Card>

					<Card className="h-full w-full flex flex-col bg-primary-grey-50 border border-primary-grey-200 p-3 pl-5 pt-5 2xl:gap-0">
						<CardHeader className="flex flex-row justify-between p-0">
							<CardTitle className="text-2xl w-full 2xl:whitespace-nowrap">Total Pagado</CardTitle>
						</CardHeader>
						<CardDescription>
							<NumberFlow
								className="text-4xl "
								value={taxSummary.ivaTaxes}
								trend={0}
								format={{
									style: "currency", // Ejemplo de formato como moneda
									currency: "COP", // Cambiar según la moneda
									minimumFractionDigits: 2, // Configurar decimales
								}}
							/>
						</CardDescription>
					</Card>
				</div>

				<div className="flex items-center">
					<p className="text-lg font-bold">{t("ICA")}</p>
					<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
				</div>
				{/*Impuesto ICA */}
				<div className="flex flex-row 2xl:h-32 justify-between gap-5 2xl:gap-10">
					<Card className=" w-2/5 flex flex-row bg-primary-green-200 border border-primary-green-1200 gap-2 p-3 2xl:gap-0">
						<CardHeader className="flex flex-col items-center 2xl:justify-center h-full">
							<CardTitle className="text-3xl text-center">Total de facturas</CardTitle>
						</CardHeader>
						<CardDescription className="self-center">
							<NumberFlow
								className="text-5xl"
								value={count.ICA}
								trend={0}
								format={{ notation: "compact" }}
							/>
						</CardDescription>
					</Card>

					<Card className="h-full w-full flex flex-col bg-primary-grey-50 border border-primary-grey-200 p-3 pl-5 pt-5 2xl:gap-0">
						<CardHeader className="flex flex-row justify-between p-0">
							<CardTitle className="text-2xl w-full 2xl:whitespace-nowrap">Total Pagado</CardTitle>
						</CardHeader>
						<CardDescription className="">
							<NumberFlow
								className="text-4xl"
								value={taxSummary.reteICA}
								trend={0}
								format={{
									style: "currency", // Ejemplo de formato como moneda
									currency: "COP", // Cambiar según la moneda
									minimumFractionDigits: 2, // Configurar decimales
								}}
							/>
						</CardDescription>
					</Card>
				</div>
				<div className="flex items-center">
					<p className="text-lg font-bold">{t("RENTA")}</p>
					<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
				</div>
				{/*Impuesto RENTA */}
        <div className="flex flex-row 2xl:h-32 justify-between gap-5 2xl:gap-10">
					<Card className=" w-2/5 flex flex-row bg-primary-green-200 border border-primary-green-1200 gap-2 p-3  2xl:gap-0">
						<CardHeader className="flex flex-col items-center 2xl:justify-center h-full">
							<CardTitle className="text-3xl text-center">Total de facturas</CardTitle>
						</CardHeader>
						<CardDescription className=" self-center ">
							<NumberFlow
								className="text-5xl"
								value={count.rent}
								trend={0}
								format={{ notation: "compact" }}
							/>
						</CardDescription>
					</Card>

					<Card className="h-full w-full flex flex-col bg-primary-grey-50 border border-primary-grey-200 p-3 pl-5 pt-5 2xl:gap-0">
						<CardHeader className="flex flex-row justify-between p-0">
							<CardTitle className="text-2xl w-full 2xl:whitespace-nowrap">Total Pagado</CardTitle>
						</CardHeader>
						<CardDescription className="">
							<NumberFlow
								className="text-4xl"
								value={taxSummary.rentTaxes}
								trend={0}
								format={{
									style: "currency", // Ejemplo de formato como moneda
									currency: "COP", // Cambiar según la moneda
									minimumFractionDigits: 2, // Configurar decimales
								}}
							/>
						</CardDescription>
					</Card>
				</div>
				<div className="flex items-center">
					<p className="text-lg font-bold">{t("OTROS")}</p>
					<div className="flex-grow border-t border-primary-grey-400 ml-8"></div>
				</div>
				{/*Impuesto OTROS */}
        <div className="flex flex-row 2xl:h-32 justify-between gap-5 2xl:gap-10">
					<Card className=" w-2/5 flex flex-row bg-primary-green-200 border border-primary-green-1200 gap-2 p-3 2xl:gap-0">
						<CardHeader className="flex flex-col items-center 2xl:justify-center h-full">
							<CardTitle className="text-3xl text-center">Total de facturas</CardTitle>
						</CardHeader>
						<CardDescription className=" self-center ">
							<NumberFlow
								className="text-5xl"
								value={count.other}
								trend={0}
								format={{ notation: "compact" }}
							/>
						</CardDescription>
					</Card>

					<Card className="h-full w-full flex flex-col bg-primary-grey-50 border border-primary-grey-200 p-3 pl-5 pt-5 2xl:gap-0">
						<CardHeader className="flex flex-row justify-between p-0">
							<CardTitle className="text-2xl w-full 2xl:whitespace-nowrap">Total Pagado</CardTitle>
						</CardHeader>
						<CardDescription className="">
							<NumberFlow
								className="text-4xl"
								value={taxSummary.otherTaxes}
								trend={0}
								format={{
									style: "currency", // Ejemplo de formato como moneda
									currency: "COP", // Cambiar según la moneda
									minimumFractionDigits: 2, // Configurar decimales
								}}
							/>
						</CardDescription>
					</Card>
				</div>
			</section>
		</>
	)
}

export default Taxes
