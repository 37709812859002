import { useState } from "react"
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronRight, Download, Trash2 } from "lucide-react"
import { ToastContainer } from "react-toastify"
import { ErpUpload } from "../../common/popUps/filesprogress/erpUpload"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { useTranslation } from "react-i18next"
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from "@/components/ui/pagination"
import { getWoReport } from "@/services/report"
import { deleteFile } from "@/services/file"
import { getAllNotUploadedInvoicesERP } from "@/services/invoice"
import { getIntegrationbyUID } from "../../../services/integrations"
import { useNavigate } from "react-router-dom"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { toggleToast } from "@/components/pages/modules/modules"

export function DataTableInvoices({ data, setData, pageSize = 10 }) {
	const [sorting, setSorting] = useState([])
	const [columnFilters, setColumnFilters] = useState([])
	const [columnVisibility, setColumnVisibility] = useState({})
	const [rowSelection, setRowSelection] = useState({})
	const [radioGroupValue, setRadioGroupValue] = useState("")
	const [page, setPage] = useState(0)
	const [globalFilter, setGlobalFilter] = useState("")
	const [selectedInvoices, setSelectedInvoices] = useState([]) // Facturas seleccionadas
	const [showERPProgress, setShowERPProgress] = useState(false)
	const [selectedInvoiceDetails, setSelectedInvoiceDetails] = useState([]) // Detalles de las facturas seleccionadas

	const { t } = useTranslation(["invoicesNotSent"])
	const navigate = useNavigate()
	const validateCreds = async () => {
		try {
			await getIntegrationbyUID()
			return true
		} catch (error) {
			console.error("Error validating credentials:", error)
			return false
		}
	}

	const handleUploadToERP = async () => {
		const invoiceDetails = []
		try {
			if (await validateCreds()) {
				// Iterar sobre cada factura seleccionada
				for (const invoice of selectedInvoices) {
					try {
						const response = await getAllNotUploadedInvoicesERP(invoice.mongoId) // Llamada a la API para cada factura
						if (response && response.data.length > 0) {
							invoiceDetails.push(...response.data)
						}
					} catch (error) {
						console.error(
							`Error al obtener información de la factura con ID ${invoice.mongoId}:`,
							error,
						)
					}
				}

				if (invoiceDetails.length > 0) {
					setSelectedInvoiceDetails(invoiceDetails) // Enviar las facturas al popup
					setShowERPProgress(true) // Mostrar el popup
				} else {
					toggleToast("No se pudo cargar información para las facturas seleccionadas.")
				}
			} else {
				toggleToast("Por favor, configure las credenciales de Siigo.")
			}
		} catch (error) {
			console.error("Error al procesar las facturas para el ERP:", error)
			toggleToast("Ocurrió un error al procesar las facturas.")
		}
	}

	const handleRowSelectionChange = (row, isSelected) => {
		setSelectedInvoices((prev) =>
			isSelected ? [...prev, row] : prev.filter((item) => item.mongoId !== row.mongoId),
		)
	}

	const deleteInvoices = async (id) => {
		try {
			await deleteFile(id)
		} catch (error) {
			console.error("Error deleting invoice:", error)
		}
	}

	const columns = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => {
						table.toggleAllPageRowsSelected(!!value)
						setSelectedInvoices(value ? table.getRowModel().rows.map((row) => row.original) : [])
					}}
					aria-label="Select all"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => {
						row.toggleSelected(!!value)
						handleRowSelectionChange(row.original, !!value)
					}}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "id",
			header: <div className="w-52">{t("table.invoice")}</div>,
			cell: ({ row }) => row.getValue("id"),
		},
		{
			accessorKey: "creationDate",
			header: ({ column }) => {
				return (
					<Button
						variant="ghost"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						{t("table.creationDate")}
						<ArrowUpDown />
					</Button>
				)
			},
			cell: ({ row }) => <div className="ml-4">{row.getValue("creationDate")}</div>,
		},
		{
			accessorKey: "paymentMethod",
			header: () => <div className="text-right">{t("table.paymentMethod")}</div>,
			cell: ({ row }) => (
				<div className="capitalize text-right">{row.getValue("paymentMethod")}</div>
			),
		},
		{
			id: "actions",
			enableHiding: false,
			header: () => <div className="w-8"></div>,
			cell: ({ row }) => {
				const payment = row.original

				return (
					<div className="w-fit ml-auto flex items-center">
						<Button
							variant="ghost"
							className="h-8 w-8 p-0"
							onClick={() => navigate(`/get-report/${payment.mongoId}`)}
						>
							<ChevronRight className="stroke-primary-grey-1600" />
						</Button>
					</div>
				)
			},
		},
	]
	const table = useReactTable({
		data,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			globalFilter,
			pagination: {
				pageIndex: page,
				pageSize,
			},
		},
	})

	return (
		<div className="w-full">
			<div className="flex w-full mb-2">
				<div className="flex flex-grow">
					<RadioGroup
						className="flex"
						value={radioGroupValue}
						onClick={(event) => {
							if (!event.target.value || event.target.value === radioGroupValue) {
								table.getColumn("paymentMethod")?.setFilterValue("")
								setRadioGroupValue("")
							} else {
								table.getColumn("paymentMethod")?.setFilterValue(event.target.value)
								setRadioGroupValue(event.target.value)
							}
						}}
					>
						<div className="flex items-center">
							<RadioGroupItem value={t("labels.cash")} id="r1" />
							<Label htmlFor="r1">{t("labels.cash")}</Label>
						</div>
						<div className="flex items-center">
							<RadioGroupItem value={t("labels.credit")} id="r2" />
							<Label htmlFor="r2">{t("labels.credit")}</Label>
						</div>
					</RadioGroup>
					<div className="w-full flex items-center ml-3 mr-40">
						<Input
							type="text"
							placeholder={t("inputPlaceholder")}
							variant="search"
							className="h-8 text-sm w-full"
							autoComplete="off"
							spellCheck="false"
							value={globalFilter}
							onChange={(event) => setGlobalFilter(event.target.value)}
						/>
					</div>
				</div>
				<div className="flex gap-3 items-center justify-end ml-auto">
					<Button
						variant="icon"
						className="h-6 w-6 [&_svg]:size-6 hover:scale-110 p-0 transition-transform duration-300 ease-in-out cursor-pointer"
						onClick={getWoReport}
					>
						<Download />
					</Button>
					<Button
						variant="icon"
						className="h-6 w-6 [&_svg]:size-6 p-0 hover:scale-110 transition-transform duration-300 ease-in-out cursor-pointer"
						onClick={() =>
							table.getSelectedRowModel().rows.forEach((row) => {
								setData((prev) => prev.filter((item) => item.mongoId !== row.original.mongoId))
								deleteInvoices(row.original.mongoId)
							})
						}
					>
						<Trash2 className="stroke-semantic-red-1200" />
					</Button>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger delayDuration={0}>
								<Button
									variant="primary"
									onClick={handleUploadToERP}
									disabled={selectedInvoices.length === 0}
								>
									{t("button.send")}
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								{selectedInvoices.length === 0 && (
									<div className="bg-primary-grey-50 text-primary-grey-1000 p-2 rounded-md">
										{t("tooltipSendERP")}
									</div>
								)}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</div>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Pagination className="mt-2">
				<PaginationContent>
					<PaginationItem>
						<PaginationPrevious
							onClick={() => {
								if (table.getCanPreviousPage()) setPage(page - 1)
							}}
							disabled={!table.getCanPreviousPage()}
							displayName={t("pagination.previous")}
						/>
					</PaginationItem>
					{page > 1 && (
						<>
							<PaginationItem>
								<PaginationLink onClick={() => setPage(0)}>1</PaginationLink>
							</PaginationItem>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
						</>
					)}
					{page > 0 && (
						<PaginationItem>
							<PaginationLink onClick={() => setPage(page - 1)}>{page}</PaginationLink>
						</PaginationItem>
					)}
					<PaginationItem>
						<PaginationLink isActive>{page + 1}</PaginationLink>
					</PaginationItem>
					{table.getPageCount() >= page + 2 && (
						<PaginationItem>
							<PaginationLink onClick={() => setPage(page + 1)}>{page + 2}</PaginationLink>
						</PaginationItem>
					)}
					{table.getPageCount() > page + 2 && (
						<>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
							<PaginationItem>
								<PaginationLink onClick={() => setPage(table.getPageCount() - 1)}>
									{table.getPageCount()}
								</PaginationLink>
							</PaginationItem>
						</>
					)}
					<PaginationItem>
						<PaginationNext
							onClick={() => {
								if (table.getCanNextPage()) setPage(page + 1)
							}}
							disabled={!table.getCanNextPage()}
							displayName={t("pagination.next")}
						/>
					</PaginationItem>
				</PaginationContent>
			</Pagination>
			<ToastContainer />
			<ErpUpload
				show={showERPProgress}
				onClose={() => setShowERPProgress(false)}
				data={selectedInvoiceDetails}
				setData={setSelectedInvoiceDetails}
			/>
		</div>
	)
}
