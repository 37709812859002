import { useEffect, useState } from "react"
import Header from "../../common/Header/Header"
import { cn } from "@/lib/utils"
import { TbPencil, TbX } from "react-icons/tb"
import {
	getPeriodDates,
	editPayrollSettings,
	getPayrollSettings,
	createPayrollRecord,
	getPayrollRecord,
} from "../../../services/payroll"
import {
	countEmployeesByPeriod,
	getEmployeeById,
	getEmployees,
	updateEmployee,
} from "../../../services/employees"
import FormTextInput from "../../common/inputs/FormTextInput"
import FormSelectInput from "../../common/inputs/FormSelectInput"

export default function PayrollPeriods() {
	const [showEditDates, setShowEditDates] = useState("")
	const [showEditEmployees, setShowEditEmployees] = useState(false)
	const [settings, setSettings] = useState(false)
	const [employees, setEmployees] = useState([])
	const [selectedEmployee, setSelectedEmployee] = useState(null)

	const defaultPayrollRecord = {
		employeeId: "",
		period: {
			start: "",
			end: "",
			paymentDate: "",
		},
		earnings: {
			baseSalary: 0,
			extraHours: {
				hours: 0,
				totalPayment: 0,
			},
			bonuses: [],
			commissions: {
				salesAmount: 0,
				commissionPayment: 0,
			},
			totalEarned: 0,
		},
		contributions: {
			riskContribution: 0,
			healthContribution: 0,
			pensionContribution: 0,
			totalContributions: 0,
		},
		deductions: {
			retention: 0,
			healthDeductions: 0,
			pensionDeductions: 0,
			totalDeductions: 0,
		},
		netSalary: 0,
		status: "processed",
	}
	const [payrollRecord, setPayrollRecord] = useState(defaultPayrollRecord)

	const [payrollPeriods, setPayrollPeriods] = useState([
		{
			type: "monthly",
			default: true,
			startDate: "",
			endDate: "",
			employees: 0,
			amount: 0,
			paymentDate: "",
		},
		{
			type: "bi-weekly",
			default: false,
			startDate: "",
			endDate: "",
			employees: 0,
			amount: 0,
			paymentDate: "",
		},
	])

	const [formDates, setFormDates] = useState({
		startDate: 0,
		paymentDate: 0,
		firstDate: 0,
		secondDate: 0,
		firstPaymentDate: 0,
		secondPaymentDate: 0,
	})

	useEffect(() => {
		syncDates()
		countEmployeesByPeriod("monthly").then((data) => {
			setPayrollPeriods((prev) =>
				prev.map((period) => {
					if (period.type === "monthly") {
						period.employees = data
					}
					return period
				}),
			)
		})
		countEmployeesByPeriod("bi-weekly").then((data) => {
			setPayrollPeriods((prev) =>
				prev.map((period) => {
					if (period.type === "bi-weekly") {
						period.employees = data
					}
					return period
				}),
			)
		})
		getEmployees().then(async (data) => {
			const tmp = await Promise.all(
				data.map(async (employee) => await getEmployeeById(employee.employeeId)),
			)
			setEmployees(tmp)
		})

		getPayrollRecord("monthly").then((data) => {
			setPayrollPeriods((prev) =>
				prev.map((period) => {
					if (period.type === "monthly") {
						period.amount = data.payrollRecords.reduce((acc, record) => acc + record.netSalary, 0)
					}
					return period
				}),
			)
		})

		getPayrollRecord("bi-weekly").then((data) => {
			setPayrollPeriods((prev) =>
				prev.map((period) => {
					if (period.type === "bi-weekly") {
						period.amount = data.payrollRecords.reduce((acc, record) => acc + record.netSalary, 0)
					}
					return period
				}),
			)
		})
	}, [])

	const syncDates = async () => {
		getPayrollSettings().then((data) => setSettings(data))

		const periodsInfo = await getPeriodDates()
		setPayrollPeriods((prev) =>
			prev
				.map((period) => {
					if (period.type === "monthly") {
						period.startDate = periodsInfo.monthly.start
						period.endDate = periodsInfo.monthly.end
						period.paymentDate = periodsInfo.monthly.payment
					} else {
						period.startDate = periodsInfo["bi-weekly"].start
						period.endDate = periodsInfo["bi-weekly"].end
						period.paymentDate = periodsInfo["bi-weekly"].payment
					}
					period.default = period.type === periodsInfo.default
					return period
				})
				.sort((a) => (a.default ? -1 : 1)),
		)
	}

	const saveDates = async () => {
		const data = { startPeriodDay: {}, paymentDay: {} }

		if (showEditDates === "monthly") {
			data.startPeriodDay.monthly = formDates.startDate > 1 ? formDates.startDate : undefined
			data.paymentDay.monthly = formDates.paymentDate > 1 ? formDates.paymentDate : undefined
		} else {
			data.startPeriodDay["bi-weekly"] = {
				first: formDates.firstDate > 1 ? formDates.firstDate : undefined,
				second: formDates.secondDate > 1 ? formDates.secondDate : undefined,
			}
			data.paymentDay["bi-weekly"] = {
				first: formDates.firstPaymentDate > 1 ? formDates.firstPaymentDate : undefined,
				second: formDates.secondPaymentDate > 1 ? formDates.secondPaymentDate : undefined,
			}
		}

		await editPayrollSettings(data)
		await syncDates()
		setShowEditDates("")
	}

	const savePayrollRecord = async (e) => {
		e.preventDefault()
		const formatDate = (dateStr) => {
			const [day, month, year] = dateStr.split("/").map(Number)
			return new Date(year, month - 1, day)
		}

		const period = {
			start: formatDate(payrollPeriods.find(() => selectedEmployee.paymentFrequency).startDate),
			end: formatDate(payrollPeriods.find(() => selectedEmployee.paymentFrequency).endDate),
			paymentDate: formatDate(
				payrollPeriods.find(() => selectedEmployee.paymentFrequency).paymentDate,
			),
		}
		const hoursPayment =
			payrollRecord.earnings.extraHours.hours * selectedEmployee?.salary.extraHourRate
		const commissionsPayment =
			(payrollRecord.earnings.commissions.salesAmount *
				selectedEmployee?.salary.comissionPercentage) /
			100
		const totalEarned = payrollRecord.earnings.baseSalary + hoursPayment + commissionsPayment
		const totalContributions =
			payrollRecord.contributions.riskContribution +
			payrollRecord.contributions.healthContribution +
			payrollRecord.contributions.pensionContribution
		const totalDeductions =
			payrollRecord.deductions.retention +
			payrollRecord.deductions.healthDeductions +
			payrollRecord.deductions.pensionDeductions
		const netSalary = totalEarned + totalContributions - totalDeductions

		const newPayrollRecord = {
			...payrollRecord,
			period,
			earnings: {
				...payrollRecord.earnings,
				extraHours: {
					...payrollRecord.earnings.extraHours,
					totalPayment: hoursPayment,
				},
				commissions: {
					...payrollRecord.earnings.commissions,
					commissionPayment: commissionsPayment,
				},
				totalEarned,
			},
			contributions: {
				...payrollRecord.contributions,
				totalContributions,
			},
			deductions: {
				...payrollRecord.deductions,
				totalDeductions,
			},
			netSalary,
		}
		setPayrollRecord(newPayrollRecord)
		setShowEditEmployees(false)
		setSelectedEmployee(null)
		await createPayrollRecord(newPayrollRecord)
		await updateEmployee({
			employeeId: selectedEmployee.employeeId,
			paymentFrequency: selectedEmployee.paymentFrequency,
			salary: {
				base: payrollRecord.earnings.baseSalary,
			},
		})
	}

	const markAsDefault = async (type) => {
		await editPayrollSettings({ paymentFrequency: type })
		setPayrollPeriods((prev) =>
			prev
				.map((period) => {
					period.default = period.type === type
					return period
				})
				.sort((a) => (a.default ? -1 : 1)),
		)
	}

	return (
		<>
			<Header title="Payroll Periods" />
			<div
				className={cn(
					"left-0 top-0 h-screen w-full bg-black/20 z-50",
					showEditDates ? "absolute" : "hidden",
				)}
			>
				<article className="w-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[--tertiary] p-8 rounded-xl">
					<div className="flex justify-between">
						<h1 className="text-2xl">Edit dates</h1>
						<button onClick={() => setShowEditDates("")}>
							<TbX className="h-6 w-6" />
						</button>
					</div>
					<div className="flex gap-8 justify-center mt-4">
						{showEditDates === "monthly" ? (
							<>
								<label htmlFor="startDate" className="w-1/4">
									<span className="block text-sm">Start date:</span>
									<input
										type="text"
										id="startDate"
										className="px-2 py-1 rounded-md border border-[--separator] w-full"
										value={formDates.startDate}
										onChange={(e) => {
											const num = Number(e.target.value)
											if (isNaN(num)) {
												return
											}
											if (num > 31) {
												setFormDates((prev) => ({ ...prev, startDate: 31 }))
											} else {
												setFormDates((prev) => ({ ...prev, startDate: num }))
											}
										}}
									/>
								</label>
								<label htmlFor="paymentDate" className="w-1/4">
									<span className="block text-sm">Payment date:</span>
									<input
										type="text"
										id="paymentDate"
										value={formDates.paymentDate}
										className="px-2 py-1 rounded-md border border-[--separator] w-full"
										onChange={(e) => {
											const num = Number(e.target.value)
											if (isNaN(num)) {
												return
											}
											if (num > 31) {
												setFormDates((prev) => ({ ...prev, paymentDate: 31 }))
											} else {
												setFormDates((prev) => ({ ...prev, paymentDate: num }))
											}
										}}
									/>
								</label>
							</>
						) : (
							<>
								<label htmlFor="firstDate" className="w-full">
									<span className="block text-sm">First date:</span>
									<input
										type="text"
										id="firstDate"
										className="px-2 py-1 rounded-md border border-[--separator] w-full"
										value={formDates.firstDate}
										onChange={(e) => {
											const num = Number(e.target.value)
											if (isNaN(num)) {
												return
											}
											if (num > 31) {
												setFormDates((prev) => ({ ...prev, firstDate: 31 }))
											} else {
												setFormDates((prev) => ({ ...prev, firstDate: num }))
											}
										}}
									/>
								</label>
								<label htmlFor="secondDate" className="w-full">
									<span className="block text-sm">Second date:</span>
									<input
										type="text"
										id="secondDate"
										className="px-2 py-1 rounded-md border border-[--separator] w-full"
										value={formDates.secondDate}
										onChange={(e) => {
											const num = Number(e.target.value)
											if (isNaN(num)) {
												return
											}
											if (num > 31) {
												setFormDates((prev) => ({ ...prev, secondDate: 31 }))
											} else {
												setFormDates((prev) => ({ ...prev, secondDate: num }))
											}
										}}
									/>
								</label>
								<label htmlFor="firstPaymentDate" className="w-full">
									<span className="block text-sm">First payment date:</span>
									<input
										type="text"
										id="firstPaymentDate"
										value={formDates.firstPaymentDate}
										className="px-2 py-1 rounded-md border border-[--separator] w-full"
										onChange={(e) => {
											const num = Number(e.target.value)
											if (isNaN(num)) {
												return
											}
											if (num > 31) {
												setFormDates((prev) => ({ ...prev, firstPaymentDate: 31 }))
											} else {
												setFormDates((prev) => ({ ...prev, firstPaymentDate: num }))
											}
										}}
									/>
								</label>
								<label htmlFor="secondPaymentDate" className="w-full">
									<span className="block text-sm">Second payment date:</span>
									<input
										type="text"
										id="secondPaymentDate"
										value={formDates.secondPaymentDate}
										className="px-2 py-1 rounded-md border border-[--separator] w-full"
										onChange={(e) => {
											const num = Number(e.target.value)
											if (isNaN(num)) {
												return
											}
											if (num > 31) {
												setFormDates((prev) => ({ ...prev, secondPaymentDate: 31 }))
											} else {
												setFormDates((prev) => ({ ...prev, secondPaymentDate: num }))
											}
										}}
									/>
								</label>
							</>
						)}
					</div>
					<button
						className="block mt-4 mx-auto w-min text-nowrap px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
						onClick={saveDates}
					>
						save
					</button>
				</article>
			</div>
			<div
				className={cn(
					"left-0 top-0 h-screen w-full bg-black/20 z-50",
					showEditEmployees ? "absolute" : "hidden",
				)}
			>
				<article className="w-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[--tertiary] p-8 rounded-xl">
					<div className="flex justify-between">
						<h1 className="text-2xl">Edit employees</h1>
						<button
							onClick={() => {
								setShowEditEmployees(false)
								setSelectedEmployee(null)
							}}
						>
							<TbX className="h-6 w-6" />
						</button>
					</div>
					{selectedEmployee ? (
						<form className="mt-4 grid grid-cols-2 gap-4">
							<FormTextInput
								id="salary"
								label="Base salary"
								number
								value={payrollRecord.earnings.baseSalary}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										earnings: { ...prev.earnings, baseSalary: Number(e.target.value) },
									}))
								}
							/>
							<FormSelectInput
								id="paymentFrequency"
								label="Base salary"
								value={selectedEmployee.paymentFrequency}
								onChange={(e) =>
									setSelectedEmployee((prev) => ({
										...prev,
										paymentFrequency: e.target.value,
									}))
								}
								options={["monthly", "bi-weekly"]}
							/>
							<FormTextInput
								id="extraHours"
								label="Extra hours"
								number
								value={payrollRecord.earnings.extraHours.hours}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										earnings: {
											...prev.earnings,
											extraHours: {
												...prev.earnings.extraHours,
												hours: Number(e.target.value),
											},
										},
									}))
								}
							/>
							<FormTextInput
								id="commitions"
								label="Commitions"
								number
								value={payrollRecord.earnings.commissions.salesAmount}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										earnings: {
											...prev.earnings,
											commissions: {
												...prev.earnings.commissions,
												salesAmount: Number(e.target.value),
											},
										},
									}))
								}
							/>
							<FormTextInput
								id="riskContributions"
								label="Risk contributions"
								number
								value={payrollRecord.contributions.riskContribution}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										contributions: {
											...prev.contributions,
											riskContribution: Number(e.target.value),
										},
									}))
								}
							/>
							<FormTextInput
								id="healthContributions"
								label="Health contributions"
								number
								value={payrollRecord.contributions.healthContribution}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										contributions: {
											...prev.contributions,
											healthContribution: Number(e.target.value),
										},
									}))
								}
							/>
							<FormTextInput
								id="pensionContributions"
								label="Pension contributions"
								number
								value={payrollRecord.contributions.pensionContribution}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										contributions: {
											...prev.contributions,
											pensionContribution: Number(e.target.value),
										},
									}))
								}
							/>
							<FormTextInput
								id="retention"
								label="Retention"
								number
								value={payrollRecord.deductions.retention}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										deductions: {
											...prev.deductions,
											retention: Number(e.target.value),
										},
									}))
								}
							/>
							<FormTextInput
								id="healthDeductions"
								label="Health deductions"
								number
								value={payrollRecord.deductions.healthDeductions}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										deductions: {
											...prev.deductions,
											healthDeductions: Number(e.target.value),
										},
									}))
								}
							/>
							<FormTextInput
								id="pensionDeductions"
								label="Pension deductions"
								number
								value={payrollRecord.deductions.pensionDeductions}
								onChange={(e) =>
									setPayrollRecord((prev) => ({
										...prev,
										deductions: {
											...prev.deductions,
											pensionDeductions: Number(e.target.value),
										},
									}))
								}
							/>

							<button
								className="col-span-2 w-min mx-auto text-nowrap px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
								onClick={async (e) => {
									await savePayrollRecord(e)
									setShowEditEmployees(false)
									setSelectedEmployee(null)
								}}
							>
								Save
							</button>
						</form>
					) : (
						<table className="border-collapse mt-4 mx-auto">
							<tbody>
								<tr className="[&>*]:px-2 border border-[--separator] bg-slate-200">
									<th>ID</th>
									<th>Name</th>
									<th>Payment frequency</th>
									<th>Total earned</th>
									<th></th>
								</tr>
								{employees.map((employee) => (
									<tr
										key={employee.employeeId}
										className="[&>*]:px-2 text-center border border-[--separator]"
									>
										<td>{employee.employeeId}</td>
										<td>
											{employee.name.first} {employee.name.last}
										</td>
										<td>{employee.paymentFrequency ?? "Not set"}</td>
										<td>{employee.totalEarned ?? 0}</td>
										<td>
											<button
												onClick={() => {
													setSelectedEmployee(employee)
													setPayrollRecord({
														...payrollRecord,
														employeeId: employee.employeeId,
														earnings: {
															...payrollRecord.earnings,
															baseSalary: employee.salary.base,
														},
													})
												}}
											>
												<TbPencil />
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</article>
			</div>
			<main className="px-12 py-8 w-full flex flex-col h-[calc(100%-5rem)] gap-8">
				{payrollPeriods.map((period) => (
					<section
						key={period.type}
						className="h-1/2 bg-[--tertiary] shadow-lg px-8 py-6 rounded-xl"
					>
						<div className="flex justify-between">
							<h1 className="text-2xl">{period.type === "monthly" ? "Monthly" : "Bi weekly"}:</h1>
							{period.default ? (
								<p className="px-4 py-1 text-[--tertiary] font-bold bg-slate-400 rounded-full">
									Default
								</p>
							) : (
								<button
									className="w-min text-nowrap px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
									onClick={() => markAsDefault(period.type)}
								>
									Mark as default
								</button>
							)}
						</div>
						<article className="grid grid-cols-3 h-[calc(100%-64px)]">
							<span className="col-span-3 text-[--separator]">
								{period.startDate} - {period.endDate}
							</span>
							<div className="flex flex-col text-center">
								<span className="text-3xl text-green-700 xl:text-5xl">{period.amount}</span>
								<span className="text-[--separator] text-lg xl:text-2xl">Amount</span>
							</div>
							<div className="flex flex-col text-center">
								<span className="text-3xl text-green-700 xl:text-5xl">{period.paymentDate}</span>
								<span className="text-[--separator] text-lg xl:text-2xl">Payment date</span>
							</div>
							<div className="flex flex-col text-center">
								<span className="text-3xl text-green-700 xl:text-5xl">{period.employees}</span>
								<span className="text-[--separator] text-lg xl:text-2xl">Employees</span>
							</div>
						</article>
						<div className="mx-auto w-min flex gap-2">
							<button
								className="w-min text-nowrap px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
								onClick={() => {
									setShowEditDates(period.type)
									if (period.type === "monthly") {
										setFormDates({
											startDate: settings.startPeriodDay.monthly,
											paymentDate: settings.paymentDay.monthly,
										})
									} else {
										setFormDates({
											firstDate: settings.startPeriodDay["bi-weekly"].first,
											secondDate: settings.startPeriodDay["bi-weekly"].second,
											firstPaymentDate: settings.paymentDay["bi-weekly"].first,
											secondPaymentDate: settings.paymentDay["bi-weekly"].second,
										})
									}
								}}
							>
								Edit dates
							</button>
							<button
								className="w-min text-nowrap px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
								onClick={() => setShowEditEmployees(period.type)}
							>
								Edit employees
							</button>
						</div>
					</section>
				))}
			</main>
		</>
	)
}
