// src/components/invoices/Invoices.jsx
import { useEffect } from "react"
import { useRef } from "react"
import { useState } from "react"
import { ToastContainer } from "react-toastify"
import { getModules } from "../../../services/modules"
import InvoiceModule from "./InvoiceModule"
import Header from "../../common/Header/Header"
import { useTranslation } from "react-i18next" // Import useTranslation

export default function Invoices() {
	const { t } = useTranslation(["invoice"])
	const [modules, setModules] = useState([])

	const getInvoicesModules = async () => {
		try {
			const response = await getModules()
			setModules(response)
		} catch (error) {
			console.error(t("messages.errorFetchingModules"), error)
		}
	}

	const firstLoad = useRef(true)

	useEffect(() => {
		if (firstLoad.current) {
			getInvoicesModules()
			firstLoad.current = false
		}
	}, [])

	return (
		<>
			<Header title={t("title")} section={{ title: t("causation"), href: "/modules" }} />
			<div className="w-full px-8 flex flex-col">
				{modules.length === 0 ? (
					<p>{t("messages.noData")}</p>
				) : (
					<>
						{modules.map(({ _id, name }) => (
							<InvoiceModule key={_id} id={_id} name={name} />
						))}
					</>
				)}
			</div>
			<ToastContainer />
		</>
	)
}
