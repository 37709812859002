import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import Cookies from "js-cookie"
import { TbCheckbox, TbEye, TbEyeClosed, TbPencil, TbPencilCancel, TbX } from "react-icons/tb"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import Header from "../../common/Header/Header"
import Button from "../../common/Buttons/Button"
import { editUser } from "../../../redux/userSlice"
import {
	updatePassword,
	updateUser,
	updatePreferences,
	updateFrecuencyEmails,
	getFrecuencyEmails,
} from "../../../services/user"
import useLoadNamespaces from "../../../utils/useLoadNamespaces"
import {
	/* updateUserNotificationFrequency, */ sendNotificationEmail,
} from "../../../services/notifications"

const selectUser = createSelector(
	(state) => state.user,
	(user) => user,
)

export default function Profile() {
	useLoadNamespaces(["profile"]) // Cargar los namespaces necesarios
	const { t, i18n } = useTranslation(["profile"]) // Cargar los namespaces 'buttons' y 'profile'
	const location = useLocation()

	useEffect(() => {
		if (location.state?.scrollTo) {
			const element = document.getElementById(location.state.scrollTo)
			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [location.state])

	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const [name, setName] = useState(user.name)
	const [phone, setPhone] = useState(user.phone)
	const [editPhone, setEditPhone] = useState(false)
	const [showPasswordModal, setShowPasswordModal] = useState(false)
	const [pass, setPass] = useState("")
	const [confirmPass, setConfirmPass] = useState("")
	const [showPass, setShowPass] = useState(false)
	const [showConfirmPass, setShowConfirmPass] = useState(false)
	const [error, setError] = useState("")
	const [showError, setShowError] = useState(false)
	const [lastname, setLastname] = useState(user.lastname)
	const [editName, setEditName] = useState(false)
	const [editLastname, setEditLastname] = useState(false)
	const [preferences, setPreferences] = useState({
		inventoryManagement: user.preferences?.inventoryManagement || "general",
		salesPullConcurrency: user.preferences?.salesPullConcurrency || "fortnightly",
		inferPucAi: user.preferences?.inferPucAi || false,
	})

	const handlePreferenceChange = async (event) => {
		const { name, value } = event.target

		setPreferences((prev) => ({
			...prev,
			[name]: value,
		}))

		// Actualizar preferencias en la base de datos
		await updatePreferences(Cookies.get("token"), {
			preferences: {
				...preferences,
				[name]: value,
			},
		})
	}

	const handleLanguageChange = (event) => {
		const newLang = event.target.value
		i18n
			.changeLanguage(newLang)
			.then(() => {
				console.log(`Idioma cambiado a ${newLang}`)
			})
			.catch((err) => {
				console.error(`Error al cambiar el idioma a ${newLang}:`, err)
			})
	}

	/* const handleNotificationChange = async (e) => {
		const newFrequency = e.target.value;

		try {

			// Enviar el correo
			const from = "no-reply@bucks-hub.com";
			const to = user.email;
			const subject = `${newFrequency}`;
			const userName = user.name;
			const invoiceCount = 0;
			const totalSum = 0;

			await sendNotificationEmail({ from, to, subject, userName, invoiceCount, totalSum });

			console.log("Preferencias actualizadas y correo enviado");
		} catch (error) {
			console.error("Error al actualizar preferencias o enviar el correo:", error);
		}
	}; */

	const handleNotificationChange = async (e) => {
		const newFrequency = e.target.value
		try {
			// Actualizar la frecuencia de notificaciones en la base de datos
			await updateFrecuencyEmails(newFrequency)
			console.log(newFrequency)

			console.log("Preferencias actualizadas")
		} catch (error) {
			console.error("Error al actualizar preferencias", error)
		}
	}

	const setCurrentFrequency = async (id, valueToSelect) => {
		const element = document.getElementById(id)
		element.value = valueToSelect
	}

	useEffect(() => {
		const fetchFrequency = async () => {
			try {
				const frecuencia = await getFrecuencyEmails()
				setCurrentFrequency("notificationFrequency", frecuencia) // Establecer la frecuencia inicial
			} catch (error) {
				console.error("Error al obtener la frecuencia de notificaciones:", error)
			}
		}

		if (user?.preferences) {
			setPreferences({
				inventoryManagement: user.preferences.inventoryManagement || "general",
				salesPullConcurrency: user.preferences.salesPullConcurrency || "fortnightly",
				inferPucAi: user.preferences.inferPucAi || true,
			})
		}

		fetchFrequency()
	}, [user])

	return (
		<>
			<Header title={t("profile.title")} />
			<main className="px-12 w-full pb-8">
				{/* Modal para cambiar contraseña */}
				<div
					className={`${showPasswordModal ? "block" : "hidden"} fixed w-screen h-screen bg-neutral-800/10 top-0 left-0 z-20`}
				>
					<div className="relative bg-white border border-neutral-300 p-4 rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-8 w-1/4">
						<button className="absolute top-2 right-2" onClick={() => setShowPasswordModal(false)}>
							<TbX className="text-black hover:text-neutral-700" size={24} />
						</button>
						<h1 className="text-center">{t("profile.changePassword")}</h1>
						<div className="flex flex-col gap-4">
							<div className="border border-neutral-300 rounded-md relative">
								<input
									value={pass}
									onChange={(e) => setPass(e.target.value)}
									className="px-2 py-1 w-full rounded-md"
									type={showPass ? "text" : "password"}
									placeholder={t("profile.newPassword")}
								/>
								<button
									type="button"
									className="absolute right-2.5 top-1"
									onClick={() => setShowPass(!showPass)}
								>
									{showPass ? (
										<TbEye className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									) : (
										<TbEyeClosed className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									)}
								</button>
							</div>
							<div className="border border-neutral-300 rounded-md relative">
								<input
									value={confirmPass}
									onChange={(e) => setConfirmPass(e.target.value)}
									className="px-2 py-1 w-full rounded-md"
									type={showConfirmPass ? "text" : "password"}
									placeholder={t("profile.confirmPassword")}
								/>
								<button
									type="button"
									className="absolute right-2.5 top-1"
									onClick={() => setShowConfirmPass(!showConfirmPass)}
								>
									{showConfirmPass ? (
										<TbEye className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									) : (
										<TbEyeClosed className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									)}
								</button>
							</div>
						</div>
						<div
							className={`${showError ? "block" : "hidden"} absolute bottom-11 w-full left-0 text-red-600 text-center`}
						>
							{error}
						</div>
						<button
							className="mx-auto bg-green-500/20 text-green-700 border border-green-500/25 rounded-lg px-4 hover:bg-green-500/30"
							onClick={() => {
								if (pass.length < 8) {
									setError(t("profile.errors.passwordLength"))
									setShowError(true)
								} else if (pass !== confirmPass) {
									setError(t("profile.errors.passwordMismatch"))
									setShowError(true)
								} else {
									setError("")
									setShowError(false)
									updatePassword(Cookies.get("token"), pass)
									setShowPasswordModal(false)
									setPass("")
									setConfirmPass("")
								}
							}}
						>
							{t("profile.confirm")}
						</button>
					</div>
				</div>

				{/* Sección principal del perfil */}
				<section className="flex flex-col gap-12 w-full">
					<div className="flex flex-col justify-between items-center">
						<h1 className="text-[2rem] text-neutral-800 mx-auto mt-10">
							{t("profile.personalInfo")}
						</h1>
						<h2 className="text-xl text-neutral-800 mx-auto mt-2">{t("profile.manageInfo")}</h2>
					</div>
					<div className="flex flex-col gap-6 w-2/3 2xl:w-1/2 mx-auto">
						{/* Información Básica */}
						<div className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden shadow">
							<h1 className="text-2xl px-6">{t("profile.basicInfo")}</h1>
							<p className="border-neutral-600 text-sm px-6">{t("profile.infoCaution")}</p>
							<div className="flex flex-col pt-4 relative">
								{/* Nombre */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300 group">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">{t("profile.Nombre")}</span>
									{editName ? (
										<input
											className="border border-neutral-300 rounded-md px-2"
											type="text"
											value={name}
											onChange={(e) => setName(e.target.value)}
											autoFocus
										/>
									) : (
										<span className="px-2">{user.name}</span>
									)}
									<div className="hidden group-hover:block absolute right-4">
										{editName ? (
											<>
												<button
													type="button"
													onClick={() => {
														dispatch(editUser({ name }))
														updateUser(Cookies.get("token"), { name })
														setEditName(false)
													}}
												>
													<TbCheckbox
														className="text-green-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
												<button
													type="button"
													onClick={() => {
														setName(user.name)
														setEditName(false)
													}}
												>
													<TbPencilCancel
														className="text-red-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
											</>
										) : (
											<button
												type="button"
												onClick={() => {
													setEditName(true)
													setName(user.name)
												}}
											>
												<TbPencil
													className="text-yellow-600 opacity-50 hover:opacity-100"
													size={24}
												/>
											</button>
										)}
									</div>
								</div>

								{/* Apellido */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300 group">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">{t("profile.Apellido")}</span>
									{editLastname ? (
										<input
											className="border border-neutral-300 rounded-md px-2"
											type="text"
											value={lastname}
											onChange={(e) => setLastname(e.target.value)}
											autoFocus
										/>
									) : (
										<span className="px-2">{user.lastname}</span>
									)}
									<div className="hidden group-hover:block absolute right-4">
										{editLastname ? (
											<>
												<button
													type="button"
													onClick={() => {
														dispatch(editUser({ lastname }))
														updateUser(Cookies.get("token"), { lastname })
														setEditLastname(false)
													}}
												>
													<TbCheckbox
														className="text-green-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
												<button
													type="button"
													onClick={() => {
														setLastname(user.lastname)
														setEditLastname(false)
													}}
												>
													<TbPencilCancel
														className="text-red-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
											</>
										) : (
											<button
												type="button"
												onClick={() => {
													setEditLastname(true)
													setLastname(user.lastname)
												}}
											>
												<TbPencil
													className="text-yellow-600 opacity-50 hover:opacity-100"
													size={24}
												/>
											</button>
										)}
									</div>
								</div>
							</div>
						</div>

						{/* Información de Contacto */}
						<div className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden relative shadow">
							<h1 className="text-2xl border-neutral-800 px-6">{t("profile.contactInfo")}</h1>
							<div className="flex flex-col pt-4">
								{/* Correo */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">{t("profile.Correo")}</span>
									<span className="px-2">{user.email}</span>
								</div>
								{/* Celular */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300 group">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">{t("profile.Celular")}</span>
									{editPhone ? (
										<input
											className="border border-neutral-300 rounded-md px-2"
											type="text"
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
											autoFocus
										/>
									) : (
										<span className="px-2">{user.phone}</span>
									)}
									<div className="hidden group-hover:block absolute right-4">
										{editPhone ? (
											<>
												<button
													type="button"
													onClick={() => {
														dispatch(editUser({ phone }))
														updateUser(Cookies.get("token"), { phone })
														setEditPhone(false)
													}}
												>
													<TbCheckbox
														className="text-green-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
												<button
													type="button"
													onClick={() => {
														setPhone(user.phone)
														setEditPhone(false)
													}}
												>
													<TbPencilCancel
														className="text-red-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
											</>
										) : (
											<button
												type="button"
												onClick={() => {
													setEditPhone(true)
													setPhone(user.phone)
												}}
											>
												<TbPencil
													className="text-yellow-600 opacity-50 hover:opacity-100"
													size={24}
												/>
											</button>
										)}
									</div>
								</div>
								{/* Contraseña */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">
										{t("profile.Contraseña")}
									</span>
									<button
										className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-lg px-4 hover:bg-green-500/30 mx-2"
										onClick={() => setShowPasswordModal(true)}
									>
										{t("profile.buttons.Cambiar")}
									</button>
								</div>
							</div>
						</div>

						{/* Preferencias */}
						{/*
						<div
							id="preferences"
							className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden relative shadow"
						>
							<h1 className="text-2xl border-neutral-800 px-6">{t("profile.preferences")}</h1>
							<div className="flex flex-col pt-4">

								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.languagePreference")}
									</span>
									<select
										onChange={handleLanguageChange}
										className="border border-neutral-300 rounded-lg px-6 py-2 bg-white text-neutral-600 mx-2"
										defaultValue={i18n.language} // Muestra el idioma actual como valor inicial
									>
										<option value="es">{t("profile.buttons.Español")}</option>
										<option value="en">{t("profile.buttons.English")}</option>
									</select>
								</div>

								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.inventoryPreference")}
									</span>
									<select
										value={preferences.inventoryManagement}
										name="inventoryManagement"
										onChange={handlePreferenceChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="general">{t("profile.options.general")}</option>
										<option value="standard">{t("profile.options.standard")}</option>
										<option value="specific">{t("profile.options.specific")}</option>
									</select>
								</div>

								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.salesUpdateFrequency")}
									</span>
									<select
										value={preferences.salesPullConcurrency}
										name="salesPullConcurrency"
										onChange={handlePreferenceChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="fortnightly">{t("profile.options.fortnightly")}</option>
										<option value="monthly">{t("profile.options.monthly")}</option>
										<option value="quarterly">{t("profile.options.quarterly")}</option>
									</select>
								</div>

								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.notificationsFrequency")}
									</span>
									<select
										id="notificationFrequency"
										onChange={handleNotificationChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="Daily">{t("profile.buttons.Diario")}</option>
										<option value="Weekly">{t("profile.buttons.Semanal")}</option>
										<option value="Fortnightly">{t("profile.buttons.Quincenal")}</option>
									</select>
								</div>

								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.inferPucAi")}
									</span>
									<input
										id="inferPucAi"
										type="checkbox"
										className="h-5 w-5 border-gray-300 rounded focus:border-[#006e25] group-hover:border-[#006e25]"
										checked={preferences.inferPucAi}
										onChange={(event) => {
											handlePreferenceChange({
												target: {
													name: "inferPucAi",
													value: event.target.checked,
												},
											})
										}}
									/>
								</div>
							</div>
						</div> */}

						{/* Planes */}
						<div
							id="plans"
							className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden relative shadow"
						>
							<h1 className="text-2xl border-neutral-800 px-6">{t("profile.plans")}</h1>
							<div className="grid grid-cols-3 pt-4">
								{/* Plan Básico */}
								<div className="flex flex-col hover:bg-neutral-50 px-8 py-4 border-t border-neutral-300 gap-4">
									<header className="flex flex-col gap-2">
										<h2 className="text-lg">{t("profile.basic")}</h2>
										<h2 className="text-xl flex items-center gap-2">
											$0.00
											<span className="text-xs text-neutral-500 my-auto inline-block">
												{t("profile.options.userPerMonth", { price: "$0.00" })}
											</span>
										</h2>
										<Button
											className="self-center w-min py-1"
											text={user.role === 0 ? t("profile.current") : t("profile.start")}
											disabled={user.role === 0}
										/>
									</header>
									<p className="text-neutral-500 text-balance">
										{t("profile.planDescriptions.basic")}
									</p>
								</div>

								{/* Plan Standard */}
								<div className="flex flex-col hover:bg-neutral-50 px-8 py-4 border-t border-l border-neutral-300 gap-4">
									<header className="flex flex-col gap-2">
										<h2 className="text-lg">{t("profile.standard")}</h2>
										<h2 className="text-xl flex items-center gap-2">
											$0.00
											<span className="text-xs text-neutral-500 my-auto inline-block">
												{t("profile.options.userPerMonth", { price: "$0.00" })}
											</span>
										</h2>
										<Button
											className="self-center w-min py-1"
											text={user.role === 1 ? t("profile.current") : t("profile.start")}
											disabled={user.role === 1}
										/>
									</header>
									<p className="text-neutral-500 text-balance">
										{t("profile.planDescriptions.standard")}
									</p>
								</div>

								{/* Plan Pro */}
								<div className="flex flex-col hover:bg-neutral-50 px-6 py-4 border-t border-l border-neutral-300 gap-4">
									<header className="flex flex-col gap-2">
										<h2 className="text-lg">{t("profile.pro")}</h2>
										<h2 className="text-xl flex items-center gap-2">
											$0.00
											<span className="text-xs text-neutral-500 my-auto inline-block">
												{t("profile.options.userPerMonth", { price: "$0.00" })}
											</span>
										</h2>
										<Button
											className="self-center w-min py-1"
											text={user.role === 2 ? t("profile.current") : t("profile.start")}
											disabled={user.role === 2}
										/>
									</header>
									<p className="text-neutral-500 text-balance">
										{t("profile.planDescriptions.pro")}
									</p>
								</div>
							</div>
						</div>

						{/* Cuenta */}
						<div className="border border-neutral-300 rounded-lg py-4 bg-white overflow-hidden flex px-6 shadow">
							<h1 className="text-2xl border-neutral-800 w-2/5 2xl:w-1/2">
								{t("profile.account")}
							</h1>
							<button
								className="bg-red-500/20 text-red-700 border border-red-500/25 rounded-lg px-4 hover:bg-red-500/30 mx-2"
								onClick={() => {
									Cookies.remove("token")
									window.location.reload()
								}}
							>
								{t("profile.buttons.Logout")}
							</button>
						</div>
					</div>
				</section>
			</main>
		</>
	)
}
