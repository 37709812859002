import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import Header from "../../common/Header/Header"

import {
	updatePreferences,
	getFrecuencyEmails,
	updateFrecuencyEmails,
} from "../../../services/user"
import useLoadNamespaces from "../../../utils/useLoadNamespaces"
import { editUser } from "@/redux/userSlice"

const selectUser = createSelector(
	(state) => state.user,
	(user) => user,
)

export default function Settings() {
	useLoadNamespaces(["profile"])
	const { t, i18n } = useTranslation(["profile"])
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		if (location.state?.scrollTo) {
			const element = document.getElementById(location.state.scrollTo)
			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [location.state])

	const user = useSelector(selectUser)
	const [preferences, setPreferences] = useState({
		inventoryManagement: user.preferences?.inventoryManagement || "general",
		salesPullConcurrency: user.preferences?.salesPullConcurrency || "fortnightly",
		inferPucAi: user.preferences?.inferPucAi || false,
	})

	const handlePreferenceChange = async (event) => {
		const { name, value } = event.target

		setPreferences((prev) => ({
			...prev,
			[name]: value,
		}))

		// Actualizar preferencias en la base de datos
		try {
			const token = Cookies.get("token")
			console.log(`Updating ${name} to:`, value)
			await updatePreferences(token, {
				preferences: {
					...preferences,
					[name]: value,
				},
			})
			dispatch(editUser({ preferences: { ...preferences, [name]: value } }))
			console.log(`${name} updated successfully.`)
		} catch (error) {
			console.error(`Error updating ${name}:`, error)
		}
	}

	const handleLanguageChange = (event) => {
		const newLang = event.target.value
		i18n
			.changeLanguage(newLang)
			.then(() => {
				console.log(`Idioma cambiado a ${newLang}`)
			})
			.catch((err) => {
				console.error(`Error al cambiar el idioma a ${newLang}:`, err)
			})
	}

	const handleNotificationChange = async (e) => {
		const newFrequency = e.target.value
		try {
			await updateFrecuencyEmails(newFrequency)
			console.log("Frecuencia de notificaciones actualizada:", newFrequency)
		} catch (error) {
			console.error("Error al actualizar preferencias de notificaciones:", error)
		}
	}

	const setCurrentFrequency = async (id, valueToSelect) => {
		const element = document.getElementById(id)
		if (element) {
			element.value = valueToSelect
		}
	}

	useEffect(() => {
		const fetchFrequency = async () => {
			try {
				const frecuencia = await getFrecuencyEmails()
				setCurrentFrequency("notificationFrequency", frecuencia)
			} catch (error) {
				console.error("Error al obtener la frecuencia de notificaciones:", error)
			}
		}

		if (user?.preferences) {
			setPreferences({
				inventoryManagement: user.preferences.inventoryManagement || "general",
				salesPullConcurrency: user.preferences.salesPullConcurrency || "fortnightly",
				inferPucAi: user.preferences.inferPucAi || false,
			})
		}

		fetchFrequency()
	}, [user])

	return (
		<>
			<Header title={t("profile.titleSettings")} />
			<main className="px-12 w-full pb-8">
				{/* Sección principal del perfil */}
				<section className="flex flex-col gap-12 w-full">
					<div className="flex flex-col justify-between items-center">
						<h1 className="text-[2rem] text-neutral-800 mx-auto mt-10">
							{t("profile.titleSettings")}
						</h1>
					</div>
					<div className="flex flex-col gap-6 w-2/3 2xl:w-1/2 mx-auto">
						{/* Preferencias */}
						<div
							id="preferences"
							className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden relative shadow"
						>
							<h1 className="text-2xl border-neutral-800 px-6">{t("profile.preferences")}</h1>
							<div className="flex flex-col pt-4">
								{/* Preferencia de Lenguaje */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.languagePreference")}
									</span>
									<select
										onChange={handleLanguageChange}
										className="border border-neutral-300 rounded-lg px-6 py-2 bg-white text-neutral-600 mx-2"
										defaultValue={i18n.language} // Muestra el idioma actual como valor inicial
									>
										<option value="es">{t("profile.buttons.Español")}</option>
										<option value="en">{t("profile.buttons.English")}</option>
									</select>
								</div>

								{/* Preferencia de Inventario */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.inventoryPreference")}
									</span>
									<select
										value={preferences.inventoryManagement}
										name="inventoryManagement"
										onChange={handlePreferenceChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="general">{t("profile.options.general")}</option>
										<option value="standard">{t("profile.options.standard")}</option>
										<option value="specific">{t("profile.options.specific")}</option>
									</select>
								</div>

								{/* Frecuencia de Actualización de Ventas */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.salesUpdateFrequency")}
									</span>
									<select
										value={preferences.salesPullConcurrency}
										name="salesPullConcurrency"
										onChange={handlePreferenceChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="fortnightly">{t("profile.options.fortnightly")}</option>
										<option value="monthly">{t("profile.options.monthly")}</option>
										<option value="quarterly">{t("profile.options.quarterly")}</option>
									</select>
								</div>

								{/* Preferencia de notificaciones */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.notificationsFrequency")}
									</span>
									<select
										id="notificationFrequency"
										onChange={handleNotificationChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="Daily">{t("profile.buttons.Diario")}</option>
										<option value="Weekly">{t("profile.buttons.Semanal")}</option>
										<option value="Fortnightly">{t("profile.buttons.Quincenal")}</option>
									</select>
								</div>

								{/* Inferir PUC por IA */}
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										{t("profile.inferPucAi")}
									</span>
									<input
										id="inferPucAi"
										type="checkbox"
										className="h-5 w-5 border-gray-300 rounded focus:border-[#006e25] group-hover:border-[#006e25]"
										checked={preferences.inferPucAi}
										onChange={(event) => {
											handlePreferenceChange({
												target: {
													name: "inferPucAi",
													value: event.target.checked,
												},
											})
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	)
}
