import { configureStore } from "@reduxjs/toolkit"

// Import Reducers
import userReducer from "./userSlice"
import invoiceSlice from "./invoice.slice"
import notificationsSlice from "./notifications.slice"
import providersSlice from "./providers.slice"
import progressSlice from "./progressSlice"

export const store = configureStore({
	reducer: {
		user: userReducer,
		invoice: invoiceSlice,
		notifications: notificationsSlice,
		providers: providersSlice,
		progress: progressSlice,
	},
})
