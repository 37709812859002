import { useEffect, useState } from "react"
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { CalendarIcon, ChevronRight, DollarSign, History } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { useTranslation } from "react-i18next"
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from "@/components/ui/pagination"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { Calendar } from "@/components/ui/calendar"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { formatNumber } from "@/utils/formatNumber"
import { PayPopUp } from "@/components/common/popUps/payPopUp/payPopUp"
import { PaymentHistoryPopUp } from "@/components/common/popUps/paymentsHistoryPopUp/paymentHistoryPopUp"

export function DataTableConsolidatedInfo({ data }) {
	const [sorting, setSorting] = useState([])
	const [columnFilters, setColumnFilters] = useState([])
	const [columnVisibility, setColumnVisibility] = useState({})
	const [rowSelection, setRowSelection] = useState({})
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [paymentTypeFilter, setPaymentTypeFilter] = useState("")
	const [paymentStatusFilter, setPaymentStatusFilter] = useState("")
	const [showAbonarPopUp, setShowAbonarPopUp] = useState(false)
	const [showHistoryPopUp, setShowHistoryPopUp] = useState(false)
	const [item, setItem] = useState({})

	const navigate = useNavigate()
	const { t } = useTranslation(["consolidatedInfo"])

	const handlePaymentSuccess = () => {
		setShowAbonarPopUp(false)
		setItem(null)
	}

	const columns = [
		{
			accessorKey: "id",
			header: <div>{t("table.invoice")}</div>,
			cell: ({ row }) => row.getValue("id"),
		},
		{
			accessorKey: "date",
			header: <div>{t("table.date")}</div>,
			cell: ({ row }) => row.getValue("date"),
			filterFn: (row, columnId, filterValue) => {
				const { startDate, endDate } = filterValue
				const date = moment(row.getValue("date"), "DD/MM/YYYY").toDate()

				return (!startDate || date >= startDate) && (!endDate || date <= endDate)
			},
		},
		{
			accessorKey: "total",
			header: <div>{t("table.total")}</div>,
			cell: ({ row }) => formatNumber(row.getValue("total")),
		},
		{
			accessorKey: "withholdings",
			header: <div>{t("table.withholdings")}</div>,
			cell: ({ row }) => formatNumber(row.getValue("withholdings")),
		},
		{
			accessorKey: "amount",
			header: <div>{t("table.amount")}</div>,
			cell: ({ row }) => formatNumber(row.getValue("amount")),
		},
		{
			accessorKey: "payment",
			header: <div>{t("table.payment")}</div>,
			cell: ({ row }) => formatNumber(row.getValue("payment")),
			filterFn: (row, columnId, filterValue) => {
				const paymentStatusFilter = filterValue
				const payment = row.getValue("payment")
				const total = row.getValue("total")
				const dueDate = moment(row.getValue("date"), "DD/MM/YYYY").toDate()
				const weekToDueDate = moment(dueDate).subtract(7, "days").toDate()
				const currentDate = new Date()

				if (paymentStatusFilter === "pagadas") {
					return payment >= total
				}
				if (paymentStatusFilter === "vencidas") {
					return payment < total && currentDate > dueDate
				}
				if (paymentStatusFilter === "vencer") {
					return payment < total && currentDate <= dueDate && currentDate >= weekToDueDate
				}

				return true
			},
		},
		{
			accessorKey: "remaining",
			header: <div>{t("table.remaining")}</div>,
			cell: ({ row }) => formatNumber(row.getValue("remaining")),
		},
		{
			accessorKey: "paymentMethod",
			header: <div>{t("table.paymentMethod")}</div>,
			cell: ({ row }) => row.getValue("paymentMethod"),
		},
		{
			accessorKey: "erp",
			header: <div>{t("table.erp")}</div>,
			cell: ({ row }) => row.getValue("erp"),
		},
		{
			id: "actions",
			enableHiding: false,
			cell: ({ row }) => {
				const payment = row.original

				return (
					<div className="w-fit ml-auto flex items-center">
						<Button
							variant="ghost"
							className="h-8 w-8 p-0"
							onClick={() => {
								setItem(payment)
								setShowAbonarPopUp(true)
							}}
						>
							<DollarSign className="stroke-primary-grey-1600" />
						</Button>
						<Button
							variant="ghost"
							className="h-8 w-8 p-0"
							onClick={() => {
								setItem(payment)
								setShowHistoryPopUp(true)
							}}
						>
							<History className="stroke-primary-grey-1600" />
						</Button>
						<Button
							variant="ghost"
							className="h-8 w-8 p-0"
							onClick={() => navigate(`/get-report/${payment.entity}`)}
						>
							<ChevronRight className="stroke-primary-grey-1600" />
						</Button>
					</div>
				)
			},
		},
	]

	const table = useReactTable({
		data,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
	})

	useEffect(() => {
		table.getColumn("date").setFilterValue({ startDate, endDate })
	}, [startDate, endDate, table])

	return (
		<div className="w-full">
			<div className="flex w-full flex-grow gap-5 mb-4">
				<div className="flex flex-col gap-1">
					<p>{t("labels.from")}: </p>
					<form
						className="space-y-8"
						onSubmit={(e) => {
							e.preventDefault()
							// handleSubmit()
						}}
					>
						<div className="flex flex-col">
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant="calendar"
										className={cn(
											"pl-3 text-left font-normal lowercase",
											!startDate && "text-muted-foreground",
										)}
									>
										{startDate ? startDate.toLocaleDateString() : <span>{t("dd/mm/aaaa")}</span>}
										<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0" align="start">
									<Calendar
										mode="single"
										selected={startDate}
										onSelect={setStartDate}
										disabled={(date) => date > new Date() || (endDate && date > endDate)}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						</div>
					</form>
				</div>
				<div className="flex flex-col gap-1">
					<p>{t("labels.to")}: </p>
					<form
						className="space-y-8"
						onSubmit={(e) => {
							e.preventDefault()
							// handleSubmit()
						}}
					>
						<div className="flex flex-col">
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant="calendar"
										className={cn(
											"pl-3 text-left font-normal lowercase",
											!endDate && "text-muted-foreground",
										)}
									>
										{endDate ? endDate.toLocaleDateString() : <span>{t("dd/mm/aaaa")}</span>}
										<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0" align="start">
									<Calendar
										mode="single"
										selected={endDate}
										onSelect={setEndDate}
										disabled={(date) => date > new Date() || (startDate && date < startDate)}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						</div>
					</form>
				</div>

				{/* RadioGroup */}
				<div className="flex flex-col gap-4 justify-center">
					<RadioGroup
						value={paymentTypeFilter}
						className="flex flex-wrap gap-4"
						onClick={(event) => {
							if (!event.target.value || event.target.value === paymentTypeFilter) {
								table.getColumn("paymentMethod")?.setFilterValue("")
								setPaymentTypeFilter("")
							} else {
								table.getColumn("paymentMethod")?.setFilterValue(event.target.value)
								setPaymentTypeFilter(event.target.value)
							}
						}}
					>
						<div className="flex items-center">
							<RadioGroupItem value={t("labels.contado")} id="r1" />
							<Label htmlFor="r1">{t("labels.contado")}</Label>
						</div>
						<div className="flex items-center">
							<RadioGroupItem value={t("labels.credito")} id="r2" />
							<Label htmlFor="r2">{t("labels.credito")}</Label>
						</div>
					</RadioGroup>
					<RadioGroup
						value={paymentStatusFilter}
						className="flex flex-wrap gap-4"
						onClick={(event) => {
							if (!event.target.value || event.target.value === paymentStatusFilter) {
								table.getColumn("payment")?.setFilterValue("")
								setPaymentStatusFilter("")
							} else {
								table.getColumn("payment")?.setFilterValue(event.target.value)
								setPaymentStatusFilter(event.target.value)
							}
						}}
					>
						<div className="flex items-center">
							<RadioGroupItem value="vencidas" id="r3" />
							<Label htmlFor="r3">{t("labels.vencidas")}</Label>
						</div>
						<div className="flex items-center">
							<RadioGroupItem value="vencer" id="r4" />
							<Label htmlFor="r4">{t("labels.vencer")}</Label>
						</div>
						<div className="flex items-center">
							<RadioGroupItem value="pagadas" id="r5" />
							<Label htmlFor="r5">{t("labels.pagadas")}</Label>
						</div>
					</RadioGroup>
				</div>
			</div>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Pagination className="mt-2">
				<PaginationContent>
					<PaginationItem>
						<PaginationPrevious
							onClick={() => {
								if (table.getCanPreviousPage()) table.previousPage()
							}}
							disabled={!table.getCanPreviousPage()}
							displayName={t("pagination.previous")}
						/>
					</PaginationItem>
					{table.getState().pagination.pageIndex > 1 && (
						<>
							<PaginationItem>
								<PaginationLink onClick={() => table.setPageIndex(0)}>1</PaginationLink>
							</PaginationItem>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
						</>
					)}
					{table.getState().pagination.pageIndex > 0 && (
						<PaginationItem>
							<PaginationLink
								onClick={() => table.setPageIndex(table.getState().pagination.pageIndex - 1)}
							>
								{table.getState().pagination.pageIndex}
							</PaginationLink>
						</PaginationItem>
					)}
					<PaginationItem>
						<PaginationLink isActive>{table.getState().pagination.pageIndex + 1}</PaginationLink>
					</PaginationItem>
					{table.getPageCount() >= table.getState().pagination.pageIndex + 2 && (
						<PaginationItem>
							<PaginationLink
								onClick={() => table.setPageIndex(table.getState().pagination.pageIndex + 1)}
							>
								{table.getState().pagination.pageIndex + 2}
							</PaginationLink>
						</PaginationItem>
					)}
					{table.getPageCount() > table.getState().pagination.pageIndex + 2 && (
						<>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
							<PaginationItem>
								<PaginationLink onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
									{table.getPageCount()}
								</PaginationLink>
							</PaginationItem>
						</>
					)}
					<PaginationItem>
						<PaginationNext
							onClick={() => {
								if (table.getCanNextPage()) table.nextPage()
							}}
							disabled={!table.getCanNextPage()}
							displayName={t("pagination.next")}
						/>
					</PaginationItem>
				</PaginationContent>
			</Pagination>
			<PayPopUp
				show={showAbonarPopUp}
				data={item}
				onClose={() => setShowAbonarPopUp(false)}
				onPaymentSuccess={handlePaymentSuccess}
			/>

			<PaymentHistoryPopUp
				show={showHistoryPopUp}
				data={item}
				onClose={() => setShowHistoryPopUp(false)}
			/>
		</div>
	)
}
